.illustration {
  max-width: 350px;
  width: 80%;
}

.illustration-connect {
  max-width: 300px;
  width: 80%;
}

.demo-video {
  max-width: 100%;
  height: auto;
  border-radius: 32px;
}

.modal-lg {
  max-width: 700px;
}

.bg-blue {
  background-color: #3755be !important;
}
svg.bg-blue {
  fill: #3755be;
}

.logo img {
  width: auto;
  margin-right: 8px;
}
