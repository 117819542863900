@import "https://fonts.googleapis.com/css?family=Roboto:400,500,700";
body {
  background-color: #1b1f3b !important;
}

@keyframes hideLoader {
  0% {
    width: 100%;
    height: 100%;
  }

  100% {
    width: 0;
    height: 0;
  }
}

body div.loader {
  width: 100vw;
  height: 100vh;
  z-index: 1071;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  transition: opacity .5s;
  display: flex;
  overflow: hidden;
}

body.loaded > div.loader {
  animation: hideLoader .5s linear .5s forwards;
}

.loading-animation {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  animation: typing 1s linear infinite alternate;
  position: relative;
  left: -12px;
}

@keyframes typing {
  0% {
    background-color: #fffc;
    box-shadow: 12px 0 #64646433, 24px 0 #64646433;
  }

  25% {
    background-color: #fff6;
    box-shadow: 12px 0 #646464, 24px 0 #64646433;
  }

  75% {
    background-color: #fff6;
    box-shadow: 12px 0 #64646433, 24px 0 #646464;
  }
}

.illustration {
  max-width: 350px;
  width: 80%;
}

.illustration-connect {
  max-width: 300px;
  width: 80%;
}

.demo-video {
  max-width: 100%;
  height: auto;
  border-radius: 32px;
}

.modal-lg {
  max-width: 700px;
}

.bg-blue {
  background-color: #3755be !important;
}

svg.bg-blue {
  fill: #3755be;
}

.logo img {
  width: auto;
  margin-right: 8px;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #3755be;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #212529;
  --primary-2: #ff8e88;
  --primary-3: #1b1f3b;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #495057;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: Inter UI, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  cursor: help;
  text-decoration-skip-ink: none;
  border-bottom: 0;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #3755be;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #263a83;
  text-decoration: none;
}

a:not([href]), a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  color: #adb5bd;
  text-align: left;
  caption-side: bottom;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

th {
  text-align: inherit;
}

label {
  margin-bottom: .75rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #212529;
  margin-bottom: 1rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 400;
}

.display-1 {
  font-size: 6rem;
  font-weight: 600;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 600;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 600;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 1.2;
}

hr {
  border: 0;
  border-top: 1px solid #0000001a;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

small, .small {
  font-size: .75rem;
  font-weight: 400;
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  color: #6c757d;
  font-size: .75rem;
  display: block;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .3125rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .75rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: 90%;
}

code {
  color: #e83e8c;
  word-wrap: break-word;
  font-size: 87.5%;
}

a > code {
  color: inherit;
}

kbd {
  color: #fff;
  background-color: #212529;
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: 87.5%;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 600;
}

pre {
  color: #212529;
  font-size: 87.5%;
  display: block;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
}

.col {
  min-width: 0;
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.row-cols-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.row-cols-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.row-cols-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.row-cols-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.row-cols-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.row-cols-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (min-width: 576px) {
  .col-sm {
    min-width: 0;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-sm-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-sm-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-sm-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-sm-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-sm-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-sm-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    min-width: 0;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-md-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-md-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-md-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-md-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-md-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-md-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    min-width: 0;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-lg-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-lg-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-lg-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-lg-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-lg-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-lg-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    min-width: 0;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-xl-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-xl-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-xl-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-xl-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-xl-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-xl-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.table {
  width: 100%;
  color: #495057;
  background-color: #0000;
  margin-bottom: 1.5rem;
}

.table th, .table td {
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  padding: 1rem;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th, .table-sm td {
  padding: .5rem;
}

.table-bordered, .table-bordered th, .table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th, .table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th, .table-borderless td, .table-borderless thead th, .table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #3755be0d;
}

.table-hover tbody tr:hover {
  color: #495057;
  background-color: #3755be0d;
}

.table-primary, .table-primary > th, .table-primary > td {
  background-color: #c7cfed;
}

.table-primary th, .table-primary td, .table-primary thead th, .table-primary tbody + tbody {
  border-color: #97a7dd;
}

.table-hover .table-primary:hover, .table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #b4bee7;
}

.table-secondary, .table-secondary > th, .table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th, .table-secondary td, .table-secondary thead th, .table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover, .table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success, .table-success > th, .table-success > td {
  background-color: #c3e6cb;
}

.table-success th, .table-success td, .table-success thead th, .table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover, .table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info, .table-info > th, .table-info > td {
  background-color: #bee5eb;
}

.table-info th, .table-info td, .table-info thead th, .table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover, .table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning, .table-warning > th, .table-warning > td {
  background-color: #ffeeba;
}

.table-warning th, .table-warning td, .table-warning thead th, .table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover, .table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger, .table-danger > th, .table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th, .table-danger td, .table-danger thead th, .table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover, .table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light, .table-light > th, .table-light > td {
  background-color: #fdfdfe;
}

.table-light th, .table-light td, .table-light thead th, .table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover, .table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark, .table-dark > th, .table-dark > td {
  background-color: #c1c2c3;
}

.table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody {
  border-color: #8c8e90;
}

.table-hover .table-dark:hover, .table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #b4b5b6;
}

.table-primary-2, .table-primary-2 > th, .table-primary-2 > td {
  background-color: #ffdfde;
}

.table-primary-2 th, .table-primary-2 td, .table-primary-2 thead th, .table-primary-2 tbody + tbody {
  border-color: #ffc4c1;
}

.table-hover .table-primary-2:hover, .table-hover .table-primary-2:hover > td, .table-hover .table-primary-2:hover > th {
  background-color: #ffc6c5;
}

.table-primary-3, .table-primary-3 > th, .table-primary-3 > td {
  background-color: #bfc0c8;
}

.table-primary-3 th, .table-primary-3 td, .table-primary-3 thead th, .table-primary-3 tbody + tbody {
  border-color: #888b99;
}

.table-hover .table-primary-3:hover, .table-hover .table-primary-3:hover > td, .table-hover .table-primary-3:hover > th {
  background-color: #b1b3bc;
}

.table-active, .table-active > th, .table-active > td {
  background-color: #3755be0d;
}

.table-hover .table-active:hover, .table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: #314caa0d;
}

.table .thead-dark th {
  color: #fff;
  background-color: #1b1f3b;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #1b1f3b;
}

.table-dark th, .table-dark td, .table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #ffffff0d;
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: #ffffff13;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow-x: auto;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  width: 100%;
  height: calc(1.5em + .875rem + 2px);
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: .3125rem;
  padding: .4375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: #0000;
  border: 0;
}

.form-control:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ced4da;
  outline: 0;
  box-shadow: 0 .125rem .625rem #0000000d;
}

.form-control::-webkit-input-placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control::placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}

input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file, .form-control-range {
  width: 100%;
  display: block;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.4375rem + 1px);
  padding-bottom: calc(.4375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.875rem + 1px);
  padding-bottom: calc(.875rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(.21875rem + 1px);
  padding-bottom: calc(.21875rem + 1px);
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  width: 100%;
  color: #495057;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .4375rem 0;
  font-size: 1rem;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  height: calc(1.5em + .4375rem + 2px);
  border-radius: .3125rem;
  padding: .21875rem 1rem;
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc(1.5em + 1.75rem + 2px);
  border-radius: .3125rem;
  padding: .875rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control[size], select.form-control[multiple], textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  margin-top: .25rem;
  display: block;
}

.form-row {
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
}

.form-row > .col, .form-row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.form-check {
  padding-left: 1.25rem;
  display: block;
  position: relative;
}

.form-check-input {
  margin-top: .3rem;
  margin-left: -1.25rem;
  position: absolute;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #adb5bd;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  align-items: center;
  margin-right: .75rem;
  padding-left: 0;
  display: inline-flex;
}

.form-check-inline .form-check-input {
  margin-top: 0;
  margin-left: 0;
  margin-right: .3125rem;
  position: static;
}

.valid-feedback {
  width: 100%;
  color: #28a745;
  margin-top: .25rem;
  font-size: .75rem;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #28a745;
  border-radius: .3125rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .75rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .21875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .4375rem) calc(.75em + .4375rem);
  border-color: #28a745;
  padding-right: calc(1.5em + .875rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .21875rem) top calc(.375em + .21875rem);
  padding-right: calc(1.5em + .875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") right 1.75rem center / calc(.75em + .4375rem) calc(.75em + .4375rem) no-repeat;
  border-color: #28a745;
  padding-right: calc(.75em + 2.3125rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label:before, .custom-control-input.is-valid ~ .custom-control-label:before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label:before, .custom-control-input.is-valid:checked ~ .custom-control-label:before {
  background-color: #34ce57;
  border-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label:before, .custom-control-input.is-valid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label:before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .75rem;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545;
  border-radius: .3125rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .75rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .21875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .4375rem) calc(.75em + .4375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .875rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .21875rem) top calc(.375em + .21875rem);
  padding-right: calc(1.5em + .875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") right 1.75rem center / calc(.75em + .4375rem) calc(.75em + .4375rem) no-repeat;
  border-color: #dc3545;
  padding-right: calc(.75em + 2.3125rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label:before, .custom-control-input.is-invalid ~ .custom-control-label:before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before, .custom-control-input.is-invalid:checked ~ .custom-control-label:before {
  background-color: #e4606d;
  border-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before, .custom-control-input.is-invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label:before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.form-inline {
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-group {
    flex-flow: wrap;
    flex: none;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group, .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    width: auto;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .form-inline .form-check-input {
    flex-shrink: 0;
    margin-top: 0;
    margin-left: 0;
    margin-right: .25rem;
    position: relative;
  }

  .form-inline .custom-control {
    justify-content: center;
    align-items: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  color: #495057;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .3125rem;
  padding: .4375rem 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #495057;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #3755be40;
}

.btn.disabled, .btn:disabled {
  opacity: .65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #3755be;
  border-color: #3755be;
}

.btn-primary:hover {
  color: #fff;
  background-color: #2e48a0;
  border-color: #2c4396;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #2e48a0;
  border-color: #2c4396;
  box-shadow: 0 0 0 .2rem #556fc880;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #3755be;
  border-color: #3755be;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2c4396;
  border-color: #293f8d;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #556fc880;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 .2rem #828a9180;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #828a9180;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 .2rem #48b46180;
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #48b46180;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 .2rem #e1536180;
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #e1536180;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-dark:hover {
  color: #fff;
  background-color: #101214;
  border-color: #0a0c0d;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #101214;
  border-color: #0a0c0d;
  box-shadow: 0 0 0 .2rem #42464980;
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #0a0c0d;
  border-color: #050506;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #42464980;
}

.btn-primary-2 {
  color: #212529;
  background-color: #ff8e88;
  border-color: #ff8e88;
}

.btn-primary-2:hover {
  color: #fff;
  background-color: #ff6a62;
  border-color: #ff5e55;
}

.btn-primary-2:focus, .btn-primary-2.focus {
  color: #fff;
  background-color: #ff6a62;
  border-color: #ff5e55;
  box-shadow: 0 0 0 .2rem #de7e7a80;
}

.btn-primary-2.disabled, .btn-primary-2:disabled {
  color: #212529;
  background-color: #ff8e88;
  border-color: #ff8e88;
}

.btn-primary-2:not(:disabled):not(.disabled):active, .btn-primary-2:not(:disabled):not(.disabled).active, .show > .btn-primary-2.dropdown-toggle {
  color: #fff;
  background-color: #ff5e55;
  border-color: #ff5148;
}

.btn-primary-2:not(:disabled):not(.disabled):active:focus, .btn-primary-2:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #de7e7a80;
}

.btn-primary-3 {
  color: #fff;
  background-color: #1b1f3b;
  border-color: #1b1f3b;
}

.btn-primary-3:hover {
  color: #fff;
  background-color: #0f1121;
  border-color: #0b0d18;
}

.btn-primary-3:focus, .btn-primary-3.focus {
  color: #fff;
  background-color: #0f1121;
  border-color: #0b0d18;
  box-shadow: 0 0 0 .2rem #3d415880;
}

.btn-primary-3.disabled, .btn-primary-3:disabled {
  color: #fff;
  background-color: #1b1f3b;
  border-color: #1b1f3b;
}

.btn-primary-3:not(:disabled):not(.disabled):active, .btn-primary-3:not(:disabled):not(.disabled).active, .show > .btn-primary-3.dropdown-toggle {
  color: #fff;
  background-color: #0b0d18;
  border-color: #07080f;
}

.btn-primary-3:not(:disabled):not(.disabled):active:focus, .btn-primary-3:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-3.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #3d415880;
}

.btn-outline-primary {
  color: #3755be;
  border-color: #3755be;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #3755be;
  border-color: #3755be;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 .2rem #3755be80;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #3755be;
  background-color: #0000;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #3755be;
  border-color: #3755be;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #3755be80;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: #0000;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: #0000;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: #0000;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: #0000;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: #0000;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: #0000;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 .2rem #21252980;
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #212529;
  background-color: #0000;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #21252980;
}

.btn-outline-primary-2 {
  color: #ff8e88;
  border-color: #ff8e88;
}

.btn-outline-primary-2:hover {
  color: #212529;
  background-color: #ff8e88;
  border-color: #ff8e88;
}

.btn-outline-primary-2:focus, .btn-outline-primary-2.focus {
  box-shadow: 0 0 0 .2rem #ff8e8880;
}

.btn-outline-primary-2.disabled, .btn-outline-primary-2:disabled {
  color: #ff8e88;
  background-color: #0000;
}

.btn-outline-primary-2:not(:disabled):not(.disabled):active, .btn-outline-primary-2:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-2.dropdown-toggle {
  color: #212529;
  background-color: #ff8e88;
  border-color: #ff8e88;
}

.btn-outline-primary-2:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-2:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #ff8e8880;
}

.btn-outline-primary-3 {
  color: #1b1f3b;
  border-color: #1b1f3b;
}

.btn-outline-primary-3:hover {
  color: #fff;
  background-color: #1b1f3b;
  border-color: #1b1f3b;
}

.btn-outline-primary-3:focus, .btn-outline-primary-3.focus {
  box-shadow: 0 0 0 .2rem #1b1f3b80;
}

.btn-outline-primary-3.disabled, .btn-outline-primary-3:disabled {
  color: #1b1f3b;
  background-color: #0000;
}

.btn-outline-primary-3:not(:disabled):not(.disabled):active, .btn-outline-primary-3:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-3.dropdown-toggle {
  color: #fff;
  background-color: #1b1f3b;
  border-color: #1b1f3b;
}

.btn-outline-primary-3:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-3:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-3.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #1b1f3b80;
}

.btn-link {
  color: #3755be;
  font-weight: 400;
  text-decoration: none;
}

.btn-link:hover {
  color: #263a83;
  text-decoration: none;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  border-radius: .3125rem;
  padding: .875rem 2.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn-sm, .btn-group-sm > .btn {
  border-radius: .3125rem;
  padding: .21875rem 1rem;
  font-size: .875rem;
  line-height: 1.5;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: .5rem;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  position: relative;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup, .dropright, .dropdown, .dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  min-width: 10rem;
  color: #495057;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: .3125rem;
  margin: .125rem 0 0;
  padding: .75rem 0;
  font-size: 1rem;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-right {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: .125rem;
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: .125rem;
  top: 0;
  left: 100%;
  right: auto;
}

.dropright .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropright .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-toggle:after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: .125rem;
  top: 0;
  left: auto;
  right: 100%;
}

.dropleft .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropleft .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  bottom: auto;
  right: auto;
}

.dropdown-divider {
  height: 0;
  border-top: 1px solid #dee2e6;
  margin: .75rem 0;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  clear: both;
  color: #49505780;
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  padding: .25rem 1.5rem;
  font-weight: 400;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #3d4349;
  background-color: #0000;
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
  color: #3755be;
  background-color: #0000;
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: #212529;
  white-space: nowrap;
  margin-bottom: 0;
  padding: .75rem 1.5rem;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  color: #49505780;
  padding: .25rem 1.5rem;
  display: block;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn:hover, .btn-group-vertical > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .9375rem;
  padding-right: .9375rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropright .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: 1.6875rem;
  padding-right: 1.6875rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .btn input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-control-plaintext, .input-group > .custom-select, .input-group > .custom-file {
  width: 1%;
  min-width: 0;
  flex: auto;
  margin-bottom: 0;
  position: relative;
}

.input-group > .form-control + .form-control, .input-group > .form-control + .custom-select, .input-group > .form-control + .custom-file, .input-group > .form-control-plaintext + .form-control, .input-group > .form-control-plaintext + .custom-select, .input-group > .form-control-plaintext + .custom-file, .input-group > .custom-select + .form-control, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .custom-file, .input-group > .custom-file + .form-control, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus, .input-group > .custom-select:focus, .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  align-items: center;
  display: flex;
}

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend, .input-group-append {
  display: flex;
}

.input-group-prepend .btn, .input-group-append .btn {
  z-index: 2;
  position: relative;
}

.input-group-prepend .btn:focus, .input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .input-group-text, .input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .3125rem;
  align-items: center;
  margin-bottom: 0;
  padding: .4375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-text input[type="radio"], .input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea), .input-group-lg > .custom-select {
  height: calc(1.5em + 1.75rem + 2px);
}

.input-group-lg > .form-control, .input-group-lg > .custom-select, .input-group-lg > .input-group-prepend > .input-group-text, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn {
  border-radius: .3125rem;
  padding: .875rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.input-group-sm > .form-control:not(textarea), .input-group-sm > .custom-select {
  height: calc(1.5em + .4375rem + 2px);
}

.input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
  border-radius: .3125rem;
  padding: .21875rem 1rem;
  font-size: .875rem;
  line-height: 1.5;
}

.input-group-lg > .custom-select, .input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text, .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  min-height: 1.5rem;
  padding-left: 2.5rem;
  display: block;
  position: relative;
}

.custom-control-inline {
  margin-right: 1rem;
  display: inline-flex;
}

.custom-control-input {
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  left: 0;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  background-color: #3755be;
  border-color: #3755be;
}

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #3755be40;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label:before {
  border-color: #ced4da;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label:before {
  color: #fff;
  background-color: #bcc7ec;
  border-color: #bcc7ec;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label:before, .custom-control-input:disabled ~ .custom-control-label:before {
  background-color: #e9ecef;
}

.custom-control-label {
  vertical-align: top;
  margin-bottom: 0;
  position: relative;
}

.custom-control-label:before {
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
  display: block;
  position: absolute;
  top: .25rem;
  left: -2.5rem;
}

.custom-control-label:after {
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
  display: block;
  position: absolute;
  top: .25rem;
  left: -2.5rem;
}

.custom-checkbox .custom-control-label:before {
  border-radius: .3125rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  background-color: #3755be;
  border-color: #3755be;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:before, .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label:before {
  background-color: #3755be80;
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #3755be80;
}

.custom-switch {
  padding-left: 4.5rem;
}

.custom-switch .custom-control-label:before {
  width: 3rem;
  pointer-events: all;
  border-radius: 200px;
  left: -4.5rem;
}

.custom-switch .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  background-color: #adb5bd;
  border-radius: 200px;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  top: calc(.25rem + 2px);
  left: calc(2px - 4.5rem);
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label:after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  background-color: #fff;
  transform: translateX(2rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #3755be80;
}

.custom-select {
  width: 100%;
  height: calc(1.5em + .875rem + 2px);
  color: #495057;
  vertical-align: middle;
  appearance: none;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat;
  border: 1px solid #dee2e6;
  border-radius: .3125rem;
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: inline-block;
}

.custom-select:focus {
  border-color: #ced4da;
  outline: 0;
  box-shadow: 0 0 0 .2rem #ced4da80;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  background-image: none;
  padding-right: .75rem;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + .4375rem + 2px);
  padding-top: .21875rem;
  padding-bottom: .21875rem;
  padding-left: 1rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(1.5em + 1.75rem + 2px);
  padding-top: .875rem;
  padding-bottom: .875rem;
  padding-left: .875rem;
  font-size: 125%;
}

.custom-file {
  width: 100%;
  height: calc(1.5em + .875rem + 2px);
  margin-bottom: 0;
  display: inline-block;
  position: relative;
}

.custom-file-input {
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .875rem + 2px);
  opacity: 0;
  margin: 0;
  position: relative;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #ced4da;
  box-shadow: 0 0 0 .2rem #3755be40;
}

.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label:after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]:after {
  content: attr(data-browse);
}

.custom-file-label {
  z-index: 1;
  height: calc(1.5em + .875rem + 2px);
  color: #495057;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .3125rem;
  padding: .4375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.custom-file-label:after {
  z-index: 3;
  height: calc(1.5em + .875rem);
  color: #495057;
  content: "Browse";
  border-left: inherit;
  background-color: #fff;
  border-radius: 0 .3125rem .3125rem 0;
  padding: .4375rem .75rem;
  line-height: 1.5;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 1rem 3rem #0000002d;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 1rem 3rem #0000002d;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 1rem 3rem #0000002d;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 1rem;
  margin-top: -.3125rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #3755be;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .375rem;
  color: #0000;
  cursor: pointer;
  background-color: #e9ecef;
  border-color: #0000;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #3755be;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: .375rem;
  color: #0000;
  cursor: pointer;
  background-color: #e9ecef;
  border-color: #0000;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 1rem;
  margin-top: 0;
  margin-left: .2rem;
  margin-right: .2rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #3755be;
}

.custom-range::-ms-track {
  width: 100%;
  height: .375rem;
  color: #0000;
  cursor: pointer;
  background-color: #0000;
  border-width: .5rem;
  border-color: #0000;
}

.custom-range::-ms-fill-lower {
  background-color: #e9ecef;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  background-color: #e9ecef;
  border-radius: 1rem;
  margin-right: 15px;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label:before, .custom-file-label, .custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label:before, .custom-file-label, .custom-select {
    transition: none;
  }
}

.nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: .5rem 1rem;
  display: block;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: inherit;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid #0000;
  border-top-left-radius: .3125rem;
  border-top-right-radius: .3125rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: inherit;
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  border-radius: .3125rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #3755be;
}

.nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  display: flex;
  position: relative;
}

.navbar .container, .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1.5rem;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  display: inline-block;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  float: none;
  position: static;
}

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: inline-block;
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: 0;
  padding: 0;
  font-size: 1.25rem;
  line-height: 1;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: center / 100% 100% no-repeat;
  display: inline-block;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row;
  justify-content: flex-start;
}

.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: .75rem;
  padding-right: .75rem;
}

.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #495057;
}

.navbar-light .navbar-nav .nav-link {
  color: #49505780;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #495057e6;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #4950574d;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
  color: #495057;
}

.navbar-light .navbar-toggler {
  color: #49505780;
  border-color: #4950571a;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2873, 80, 87, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #49505780;
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #495057;
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff80;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffffbf;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #ffffff80;
  border-color: #ffffff1a;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #ffffff80;
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #00000020;
  border-radius: .625rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(.625rem - 1px);
  border-top-right-radius: calc(.625rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-left-radius: calc(.625rem - 1px);
  border-bottom-right-radius: calc(.625rem - 1px);
}

.card-body {
  min-height: 1px;
  flex: auto;
  padding: 2rem;
}

.card-title {
  margin-bottom: 2rem;
}

.card-subtitle {
  margin-top: -1rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 2rem;
}

.card-header {
  background-color: #00000008;
  border-bottom: 1px solid #00000020;
  margin-bottom: 0;
  padding: 2rem;
}

.card-header:first-child {
  border-radius: calc(.625rem - 1px) calc(.625rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  background-color: #00000008;
  border-top: 1px solid #00000020;
  padding: 2rem;
}

.card-footer:last-child {
  border-radius: 0 0 calc(.625rem - 1px) calc(.625rem - 1px);
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -2rem;
  margin-left: -1rem;
  margin-right: -1rem;
}

.card-header-pills {
  margin-left: -1rem;
  margin-right: -1rem;
}

.card-img-overlay {
  padding: 1.25rem;
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
  flex-shrink: 0;
}

.card-img, .card-img-top {
  border-top-left-radius: calc(.625rem - 1px);
  border-top-right-radius: calc(.625rem - 1px);
}

.card-img, .card-img-bottom {
  border-bottom-left-radius: calc(.625rem - 1px);
  border-bottom-right-radius: calc(.625rem - 1px);
}

.card-deck .card {
  margin-bottom: 12px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: wrap;
    margin-left: -12px;
    margin-right: -12px;
    display: flex;
  }

  .card-deck .card {
    flex: 1 0;
    margin-bottom: 0;
    margin-left: 12px;
    margin-right: 12px;
  }
}

.card-group > .card {
  margin-bottom: 12px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 2rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
    column-gap: 1.25rem;
  }

  .card-columns .card {
    width: 100%;
    display: inline-block;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  background-color: #3755be1a;
  border-radius: 200rem;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding: .5rem 1.25rem;
  list-style: none;
  display: flex;
}

.breadcrumb-item {
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .25rem;
}

.breadcrumb-item + .breadcrumb-item:before {
  color: #6c757d;
  content: "/";
  padding-right: .25rem;
  display: inline-block;
}

.breadcrumb-item + .breadcrumb-item:hover:before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #adb5bd;
}

.pagination {
  border-radius: .3125rem;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  color: #3755be;
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin-left: -1px;
  padding: .5rem .75rem;
  line-height: 1.25;
  display: block;
  position: relative;
}

.page-link:hover {
  z-index: 2;
  color: #263a83;
  background-color: #e9ecef;
  border-color: #dee2e6;
  text-decoration: none;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 .2rem #3755be40;
}

.page-item:first-child .page-link {
  border-top-left-radius: .3125rem;
  border-bottom-left-radius: .3125rem;
  margin-left: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: .3125rem;
  border-bottom-right-radius: .3125rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #3755be;
  border-color: #3755be;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .625rem;
  border-bottom-left-radius: .625rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .625rem;
  border-bottom-right-radius: .625rem;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.badge {
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .3125rem;
  padding: .5em .5rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 1;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  border-radius: 10rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.badge-primary {
  color: #fff;
  background-color: #3755be;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #2c4396;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #3755be80;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #28a74580;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #dc354580;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.badge-dark {
  color: #fff;
  background-color: #212529;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #0a0c0d;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #21252980;
}

.badge-primary-2 {
  color: #212529;
  background-color: #ff8e88;
}

a.badge-primary-2:hover, a.badge-primary-2:focus {
  color: #212529;
  background-color: #ff5e55;
}

a.badge-primary-2:focus, a.badge-primary-2.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #ff8e8880;
}

.badge-primary-3 {
  color: #fff;
  background-color: #1b1f3b;
}

a.badge-primary-3:hover, a.badge-primary-3:focus {
  color: #fff;
  background-color: #0b0d18;
}

a.badge-primary-3:focus, a.badge-primary-3.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #1b1f3b80;
}

.jumbotron {
  background-color: #e9ecef;
  border-radius: .625rem;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.alert {
  border: 1px solid #0000;
  border-radius: .3125rem;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  color: inherit;
  padding: .75rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  color: #334eaf;
  background-color: #e7ebf7;
  border-color: #b7c2e8;
}

.alert-primary hr {
  border-top-color: #a4b2e2;
}

.alert-primary .alert-link {
  color: #273c88;
}

.alert-secondary {
  color: #636c73;
  background-color: #edeeef;
  border-color: #cacdd0;
}

.alert-secondary hr {
  border-top-color: #bcc0c4;
}

.alert-secondary .alert-link {
  color: #4b5258;
}

.alert-success {
  color: #259a3f;
  background-color: #e5f4e9;
  border-color: #b2dfbc;
}

.alert-success hr {
  border-top-color: #a0d8ac;
}

.alert-success .alert-link {
  color: #1b712e;
}

.alert-info {
  color: #1595a9;
  background-color: #e3f4f6;
  border-color: #abdee5;
}

.alert-info hr {
  border-top-color: #98d6df;
}

.alert-info .alert-link {
  color: #0f6d7c;
}

.alert-warning {
  color: #ebb206;
  background-color: #fff8e1;
  border-color: #ffe9a6;
}

.alert-warning hr {
  border-top-color: #ffe38d;
}

.alert-warning .alert-link {
  color: #b98c05;
}

.alert-danger {
  color: #ca313f;
  background-color: #fbe7e9;
  border-color: #f2b6bc;
}

.alert-danger hr {
  border-top-color: #eea0a8;
}

.alert-danger .alert-link {
  color: #a12732;
}

.alert-light {
  color: #e4e5e6;
  background-color: #fefefe;
  border-color: #fcfdfd;
}

.alert-light hr {
  border-top-color: #edf3f3;
}

.alert-light .alert-link {
  color: #cacccd;
}

.alert-dark {
  color: #1e2226;
  background-color: #e4e5e5;
  border-color: #afb1b2;
}

.alert-dark hr {
  border-top-color: #a2a4a5;
}

.alert-dark .alert-link {
  color: #08090a;
}

.alert-primary-2 {
  color: #eb837d;
  background-color: #fff1f1;
  border-color: #ffd6d4;
}

.alert-primary-2 hr {
  border-top-color: #ffbebb;
}

.alert-primary-2 .alert-link {
  color: #e45951;
}

.alert-primary-3 {
  color: #191d36;
  background-color: #e4e4e7;
  border-color: #adaeb8;
}

.alert-primary-3 hr {
  border-top-color: #9fa1ac;
}

.alert-primary-3 .alert-link {
  color: #090a13;
}

@keyframes progress-bar-stripes {
  from {
    background-position: .375rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: .375rem;
  background-color: #e9ecef;
  border-radius: 10rem;
  font-size: .75rem;
  line-height: 0;
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #3755be;
  flex-direction: column;
  justify-content: center;
  transition: width .6s;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: .375rem .375rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  align-items: flex-start;
  display: flex;
}

.media-body {
  flex: 1;
}

.list-group {
  border-radius: .3125rem;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  background-color: #3755be0d;
  text-decoration: none;
}

.list-group-item-action:active {
  color: #495057;
  background-color: #e9ecef;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #dee2e6;
  padding: .75rem 1.25rem;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #3755be;
  border-color: #3755be;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  border-top-width: 1px;
  margin-top: -1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: .3125rem;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: .3125rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  border-left-width: 1px;
  margin-left: -1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .3125rem;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: .3125rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .3125rem;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: .3125rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .3125rem;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: .3125rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .3125rem;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: .3125rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #1d2c63;
  background-color: #c7cfed;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #1d2c63;
  background-color: #b4bee7;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #1d2c63;
  border-color: #1d2c63;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #111315;
  background-color: #c1c2c3;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #111315;
  background-color: #b4b5b6;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #111315;
  border-color: #111315;
}

.list-group-item-primary-2 {
  color: #854a47;
  background-color: #ffdfde;
}

.list-group-item-primary-2.list-group-item-action:hover, .list-group-item-primary-2.list-group-item-action:focus {
  color: #854a47;
  background-color: #ffc6c5;
}

.list-group-item-primary-2.list-group-item-action.active {
  color: #fff;
  background-color: #854a47;
  border-color: #854a47;
}

.list-group-item-primary-3 {
  color: #0e101f;
  background-color: #bfc0c8;
}

.list-group-item-primary-3.list-group-item-action:hover, .list-group-item-primary-3.list-group-item-action:focus {
  color: #0e101f;
  background-color: #b1b3bc;
}

.list-group-item-primary-3.list-group-item-action.active {
  color: #fff;
  background-color: #0e101f;
  border-color: #0e101f;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .5;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  background-color: #0000;
  border: 0;
  padding: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border: 1px solid #0000001a;
  border-radius: .25rem;
  font-size: .875rem;
  overflow: hidden;
  box-shadow: 0 .25rem .75rem #0000001a;
}

.toast:not(:last-child) {
  margin-bottom: .75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  opacity: 1;
  display: block;
}

.toast.hide {
  display: none;
}

.toast-header {
  color: #6c757d;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border-bottom: 1px solid #0000000d;
  align-items: center;
  padding: .25rem .75rem;
  display: flex;
}

.toast-body {
  padding: .75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  z-index: 1050;
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modal-dialog {
  width: auto;
  pointer-events: none;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  max-height: calc(100% - 1rem);
  display: flex;
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - 1rem);
  align-items: center;
  display: flex;
}

.modal-dialog-centered:before {
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
  display: block;
}

.modal-dialog-centered.modal-dialog-scrollable {
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none;
}

.modal-content {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .625rem;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: calc(.625rem - 1px);
  border-top-right-radius: calc(.625rem - 1px);
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #e9ecef;
  border-bottom-left-radius: calc(.625rem - 1px);
  border-bottom-right-radius: calc(.625rem - 1px);
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.modal-footer > * {
  margin: .25rem;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  z-index: 1070;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
  margin: 0;
  font-family: Inter UI, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .arrow {
  width: .5rem;
  height: .4rem;
  display: block;
  position: absolute;
}

.tooltip .arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: .4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow:before, .bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-width: .4rem .25rem 0;
  border-top-color: #212529;
  top: 0;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 .4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  width: .4rem;
  height: .5rem;
  left: 0;
}

.bs-tooltip-right .arrow:before, .bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-width: .25rem .4rem .25rem 0;
  border-right-color: #212529;
  right: 0;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: .4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow:before, .bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-width: 0 .25rem .4rem;
  border-bottom-color: #212529;
  bottom: 0;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 .4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  width: .4rem;
  height: .5rem;
  right: 0;
}

.bs-tooltip-left .arrow:before, .bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-width: .25rem 0 .25rem .4rem;
  border-left-color: #212529;
  left: 0;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  text-align: center;
  background-color: #212529;
  border-radius: .3125rem;
  padding: .25rem .5rem;
}

.popover {
  z-index: 1060;
  max-width: 276px;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: .3125rem;
  font-family: Inter UI, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.popover .arrow {
  width: 0;
  height: 0;
  margin: 0 .3125rem;
  display: block;
  position: absolute;
}

.popover .arrow:before, .popover .arrow:after {
  content: "";
  border-style: solid;
  border-color: #0000;
  display: block;
  position: absolute;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: -1px;
}

.bs-popover-top > .arrow:before, .bs-popover-auto[x-placement^="top"] > .arrow:before {
  border-width: 0;
  border-top-color: #dee2e6;
  bottom: 0;
}

.bs-popover-top > .arrow:after, .bs-popover-auto[x-placement^="top"] > .arrow:after {
  border-width: 0;
  border-top-color: #0000;
  bottom: 1px;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  width: 0;
  height: 0;
  margin: .3125rem 0;
  left: -1px;
}

.bs-popover-right > .arrow:before, .bs-popover-auto[x-placement^="right"] > .arrow:before {
  border-width: 0;
  border-right-color: #dee2e6;
  left: 0;
}

.bs-popover-right > .arrow:after, .bs-popover-auto[x-placement^="right"] > .arrow:after {
  border-width: 0;
  border-right-color: #0000;
  left: 1px;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: -1px;
}

.bs-popover-bottom > .arrow:before, .bs-popover-auto[x-placement^="bottom"] > .arrow:before {
  border-width: 0;
  border-bottom-color: #dee2e6;
  top: 0;
}

.bs-popover-bottom > .arrow:after, .bs-popover-auto[x-placement^="bottom"] > .arrow:after {
  border-width: 0;
  border-bottom-color: #0000;
  top: 1px;
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[x-placement^="bottom"] .popover-header:before {
  width: 0;
  content: "";
  border-bottom: 1px solid #fff;
  margin-left: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  width: 0;
  height: 0;
  margin: .3125rem 0;
  right: -1px;
}

.bs-popover-left > .arrow:before, .bs-popover-auto[x-placement^="left"] > .arrow:before {
  border-width: 0;
  border-left-color: #dee2e6;
  right: 0;
}

.bs-popover-left > .arrow:after, .bs-popover-auto[x-placement^="left"] > .arrow:after {
  border-width: 0;
  border-left-color: #0000;
  right: 1px;
}

.popover-header {
  color: #212529;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
  border-top-left-radius: calc(.3125rem - 1px);
  border-top-right-radius: calc(.3125rem - 1px);
  margin-bottom: 0;
  padding: 1rem;
  font-size: 1rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #495057;
  padding: 1rem;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left), .active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right), .active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  justify-content: center;
  align-items: center;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 15;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators li {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid;
  border-right-color: #0000;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
  display: inline-block;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  opacity: 0;
  background-color: currentColor;
  border-radius: 50%;
  animation: spinner-grow .75s linear infinite;
  display: inline-block;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #3755be !important;
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
  background-color: #2c4396 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #212529 !important;
}

a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus {
  background-color: #0a0c0d !important;
}

.bg-primary-2 {
  background-color: #ff8e88 !important;
}

a.bg-primary-2:hover, a.bg-primary-2:focus, button.bg-primary-2:hover, button.bg-primary-2:focus {
  background-color: #ff5e55 !important;
}

.bg-primary-3 {
  background-color: #1b1f3b !important;
}

a.bg-primary-3:hover, a.bg-primary-3:focus, button.bg-primary-3:hover, button.bg-primary-3:focus {
  background-color: #0b0d18 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #3755be !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-primary-2 {
  border-color: #ff8e88 !important;
}

.border-primary-3 {
  border-color: #1b1f3b !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: .2rem !important;
}

.rounded {
  border-radius: .3125rem !important;
}

.rounded-top {
  border-top-left-radius: .3125rem !important;
  border-top-right-radius: .3125rem !important;
}

.rounded-right {
  border-top-right-radius: .3125rem !important;
  border-bottom-right-radius: .3125rem !important;
}

.rounded-bottom {
  border-bottom-left-radius: .3125rem !important;
  border-bottom-right-radius: .3125rem !important;
}

.rounded-left {
  border-top-left-radius: .3125rem !important;
  border-bottom-left-radius: .3125rem !important;
}

.rounded-lg {
  border-radius: .625rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive:before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  position: static;
  overflow: visible;
}

.shadow-sm {
  box-shadow: 0 .125rem .625rem #0000000d !important;
}

.shadow {
  box-shadow: 0 .625rem 1.5rem #00000014 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .375rem !important;
}

.mt-1, .my-1 {
  margin-top: .375rem !important;
}

.mr-1, .mx-1 {
  margin-right: .375rem !important;
}

.mb-1, .my-1 {
  margin-bottom: .375rem !important;
}

.ml-1, .mx-1 {
  margin-left: .375rem !important;
}

.m-2 {
  margin: .75rem !important;
}

.mt-2, .my-2 {
  margin-top: .75rem !important;
}

.mr-2, .mx-2 {
  margin-right: .75rem !important;
}

.mb-2, .my-2 {
  margin-bottom: .75rem !important;
}

.ml-2, .mx-2 {
  margin-left: .75rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.mt-3, .my-3 {
  margin-top: 1.5rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1.5rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1.5rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1.5rem !important;
}

.m-4 {
  margin: 2.25rem !important;
}

.mt-4, .my-4 {
  margin-top: 2.25rem !important;
}

.mr-4, .mx-4 {
  margin-right: 2.25rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 2.25rem !important;
}

.ml-4, .mx-4 {
  margin-left: 2.25rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5, .my-5 {
  margin-top: 3rem !important;
}

.mr-5, .mx-5 {
  margin-right: 3rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.ml-5, .mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.mt-6, .my-6 {
  margin-top: 4.5rem !important;
}

.mr-6, .mx-6 {
  margin-right: 4.5rem !important;
}

.mb-6, .my-6 {
  margin-bottom: 4.5rem !important;
}

.ml-6, .mx-6 {
  margin-left: 4.5rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.mt-7, .my-7 {
  margin-top: 6rem !important;
}

.mr-7, .mx-7 {
  margin-right: 6rem !important;
}

.mb-7, .my-7 {
  margin-bottom: 6rem !important;
}

.ml-7, .mx-7 {
  margin-left: 6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .375rem !important;
}

.pt-1, .py-1 {
  padding-top: .375rem !important;
}

.pr-1, .px-1 {
  padding-right: .375rem !important;
}

.pb-1, .py-1 {
  padding-bottom: .375rem !important;
}

.pl-1, .px-1 {
  padding-left: .375rem !important;
}

.p-2 {
  padding: .75rem !important;
}

.pt-2, .py-2 {
  padding-top: .75rem !important;
}

.pr-2, .px-2 {
  padding-right: .75rem !important;
}

.pb-2, .py-2 {
  padding-bottom: .75rem !important;
}

.pl-2, .px-2 {
  padding-left: .75rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.pt-3, .py-3 {
  padding-top: 1.5rem !important;
}

.pr-3, .px-3 {
  padding-right: 1.5rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1.5rem !important;
}

.pl-3, .px-3 {
  padding-left: 1.5rem !important;
}

.p-4 {
  padding: 2.25rem !important;
}

.pt-4, .py-4 {
  padding-top: 2.25rem !important;
}

.pr-4, .px-4 {
  padding-right: 2.25rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 2.25rem !important;
}

.pl-4, .px-4 {
  padding-left: 2.25rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5, .py-5 {
  padding-top: 3rem !important;
}

.pr-5, .px-5 {
  padding-right: 3rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 3rem !important;
}

.pl-5, .px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.pt-6, .py-6 {
  padding-top: 4.5rem !important;
}

.pr-6, .px-6 {
  padding-right: 4.5rem !important;
}

.pb-6, .py-6 {
  padding-bottom: 4.5rem !important;
}

.pl-6, .px-6 {
  padding-left: 4.5rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.pt-7, .py-7 {
  padding-top: 6rem !important;
}

.pr-7, .px-7 {
  padding-right: 6rem !important;
}

.pb-7, .py-7 {
  padding-bottom: 6rem !important;
}

.pl-7, .px-7 {
  padding-left: 6rem !important;
}

.m-n1 {
  margin: -.375rem !important;
}

.mt-n1, .my-n1 {
  margin-top: -.375rem !important;
}

.mr-n1, .mx-n1 {
  margin-right: -.375rem !important;
}

.mb-n1, .my-n1 {
  margin-bottom: -.375rem !important;
}

.ml-n1, .mx-n1 {
  margin-left: -.375rem !important;
}

.m-n2 {
  margin: -.75rem !important;
}

.mt-n2, .my-n2 {
  margin-top: -.75rem !important;
}

.mr-n2, .mx-n2 {
  margin-right: -.75rem !important;
}

.mb-n2, .my-n2 {
  margin-bottom: -.75rem !important;
}

.ml-n2, .mx-n2 {
  margin-left: -.75rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.mt-n3, .my-n3 {
  margin-top: -1.5rem !important;
}

.mr-n3, .mx-n3 {
  margin-right: -1.5rem !important;
}

.mb-n3, .my-n3 {
  margin-bottom: -1.5rem !important;
}

.ml-n3, .mx-n3 {
  margin-left: -1.5rem !important;
}

.m-n4 {
  margin: -2.25rem !important;
}

.mt-n4, .my-n4 {
  margin-top: -2.25rem !important;
}

.mr-n4, .mx-n4 {
  margin-right: -2.25rem !important;
}

.mb-n4, .my-n4 {
  margin-bottom: -2.25rem !important;
}

.ml-n4, .mx-n4 {
  margin-left: -2.25rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5, .my-n5 {
  margin-top: -3rem !important;
}

.mr-n5, .mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5, .my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5, .mx-n5 {
  margin-left: -3rem !important;
}

.m-n6 {
  margin: -4.5rem !important;
}

.mt-n6, .my-n6 {
  margin-top: -4.5rem !important;
}

.mr-n6, .mx-n6 {
  margin-right: -4.5rem !important;
}

.mb-n6, .my-n6 {
  margin-bottom: -4.5rem !important;
}

.ml-n6, .mx-n6 {
  margin-left: -4.5rem !important;
}

.m-n7 {
  margin: -6rem !important;
}

.mt-n7, .my-n7 {
  margin-top: -6rem !important;
}

.mr-n7, .mx-n7 {
  margin-right: -6rem !important;
}

.mb-n7, .my-n7 {
  margin-bottom: -6rem !important;
}

.ml-n7, .mx-n7 {
  margin-left: -6rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .375rem !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: .375rem !important;
  }

  .mr-sm-1, .mx-sm-1 {
    margin-right: .375rem !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: .375rem !important;
  }

  .ml-sm-1, .mx-sm-1 {
    margin-left: .375rem !important;
  }

  .m-sm-2 {
    margin: .75rem !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: .75rem !important;
  }

  .mr-sm-2, .mx-sm-2 {
    margin-right: .75rem !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: .75rem !important;
  }

  .ml-sm-2, .mx-sm-2 {
    margin-left: .75rem !important;
  }

  .m-sm-3 {
    margin: 1.5rem !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-3, .mx-sm-3 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-3, .mx-sm-3 {
    margin-left: 1.5rem !important;
  }

  .m-sm-4 {
    margin: 2.25rem !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 2.25rem !important;
  }

  .mr-sm-4, .mx-sm-4 {
    margin-right: 2.25rem !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 2.25rem !important;
  }

  .ml-sm-4, .mx-sm-4 {
    margin-left: 2.25rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .m-sm-6 {
    margin: 4.5rem !important;
  }

  .mt-sm-6, .my-sm-6 {
    margin-top: 4.5rem !important;
  }

  .mr-sm-6, .mx-sm-6 {
    margin-right: 4.5rem !important;
  }

  .mb-sm-6, .my-sm-6 {
    margin-bottom: 4.5rem !important;
  }

  .ml-sm-6, .mx-sm-6 {
    margin-left: 4.5rem !important;
  }

  .m-sm-7 {
    margin: 6rem !important;
  }

  .mt-sm-7, .my-sm-7 {
    margin-top: 6rem !important;
  }

  .mr-sm-7, .mx-sm-7 {
    margin-right: 6rem !important;
  }

  .mb-sm-7, .my-sm-7 {
    margin-bottom: 6rem !important;
  }

  .ml-sm-7, .mx-sm-7 {
    margin-left: 6rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .375rem !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-top: .375rem !important;
  }

  .pr-sm-1, .px-sm-1 {
    padding-right: .375rem !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-bottom: .375rem !important;
  }

  .pl-sm-1, .px-sm-1 {
    padding-left: .375rem !important;
  }

  .p-sm-2 {
    padding: .75rem !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-top: .75rem !important;
  }

  .pr-sm-2, .px-sm-2 {
    padding-right: .75rem !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-bottom: .75rem !important;
  }

  .pl-sm-2, .px-sm-2 {
    padding-left: .75rem !important;
  }

  .p-sm-3 {
    padding: 1.5rem !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 1.5rem !important;
  }

  .p-sm-4 {
    padding: 2.25rem !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-top: 2.25rem !important;
  }

  .pr-sm-4, .px-sm-4 {
    padding-right: 2.25rem !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-bottom: 2.25rem !important;
  }

  .pl-sm-4, .px-sm-4 {
    padding-left: 2.25rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important;
  }

  .p-sm-6 {
    padding: 4.5rem !important;
  }

  .pt-sm-6, .py-sm-6 {
    padding-top: 4.5rem !important;
  }

  .pr-sm-6, .px-sm-6 {
    padding-right: 4.5rem !important;
  }

  .pb-sm-6, .py-sm-6 {
    padding-bottom: 4.5rem !important;
  }

  .pl-sm-6, .px-sm-6 {
    padding-left: 4.5rem !important;
  }

  .p-sm-7 {
    padding: 6rem !important;
  }

  .pt-sm-7, .py-sm-7 {
    padding-top: 6rem !important;
  }

  .pr-sm-7, .px-sm-7 {
    padding-right: 6rem !important;
  }

  .pb-sm-7, .py-sm-7 {
    padding-bottom: 6rem !important;
  }

  .pl-sm-7, .px-sm-7 {
    padding-left: 6rem !important;
  }

  .m-sm-n1 {
    margin: -.375rem !important;
  }

  .mt-sm-n1, .my-sm-n1 {
    margin-top: -.375rem !important;
  }

  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -.375rem !important;
  }

  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -.375rem !important;
  }

  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -.375rem !important;
  }

  .m-sm-n2 {
    margin: -.75rem !important;
  }

  .mt-sm-n2, .my-sm-n2 {
    margin-top: -.75rem !important;
  }

  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -.75rem !important;
  }

  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -.75rem !important;
  }

  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -.75rem !important;
  }

  .m-sm-n3 {
    margin: -1.5rem !important;
  }

  .mt-sm-n3, .my-sm-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n4 {
    margin: -2.25rem !important;
  }

  .mt-sm-n4, .my-sm-n4 {
    margin-top: -2.25rem !important;
  }

  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -2.25rem !important;
  }

  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -2.25rem !important;
  }

  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -2.25rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5, .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-n6 {
    margin: -4.5rem !important;
  }

  .mt-sm-n6, .my-sm-n6 {
    margin-top: -4.5rem !important;
  }

  .mr-sm-n6, .mx-sm-n6 {
    margin-right: -4.5rem !important;
  }

  .mb-sm-n6, .my-sm-n6 {
    margin-bottom: -4.5rem !important;
  }

  .ml-sm-n6, .mx-sm-n6 {
    margin-left: -4.5rem !important;
  }

  .m-sm-n7 {
    margin: -6rem !important;
  }

  .mt-sm-n7, .my-sm-n7 {
    margin-top: -6rem !important;
  }

  .mr-sm-n7, .mx-sm-n7 {
    margin-right: -6rem !important;
  }

  .mb-sm-n7, .my-sm-n7 {
    margin-bottom: -6rem !important;
  }

  .ml-sm-n7, .mx-sm-n7 {
    margin-left: -6rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .375rem !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: .375rem !important;
  }

  .mr-md-1, .mx-md-1 {
    margin-right: .375rem !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: .375rem !important;
  }

  .ml-md-1, .mx-md-1 {
    margin-left: .375rem !important;
  }

  .m-md-2 {
    margin: .75rem !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: .75rem !important;
  }

  .mr-md-2, .mx-md-2 {
    margin-right: .75rem !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: .75rem !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: .75rem !important;
  }

  .m-md-3 {
    margin: 1.5rem !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 1.5rem !important;
  }

  .mr-md-3, .mx-md-3 {
    margin-right: 1.5rem !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 1.5rem !important;
  }

  .m-md-4 {
    margin: 2.25rem !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 2.25rem !important;
  }

  .mr-md-4, .mx-md-4 {
    margin-right: 2.25rem !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 2.25rem !important;
  }

  .ml-md-4, .mx-md-4 {
    margin-left: 2.25rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important;
  }

  .m-md-6 {
    margin: 4.5rem !important;
  }

  .mt-md-6, .my-md-6 {
    margin-top: 4.5rem !important;
  }

  .mr-md-6, .mx-md-6 {
    margin-right: 4.5rem !important;
  }

  .mb-md-6, .my-md-6 {
    margin-bottom: 4.5rem !important;
  }

  .ml-md-6, .mx-md-6 {
    margin-left: 4.5rem !important;
  }

  .m-md-7 {
    margin: 6rem !important;
  }

  .mt-md-7, .my-md-7 {
    margin-top: 6rem !important;
  }

  .mr-md-7, .mx-md-7 {
    margin-right: 6rem !important;
  }

  .mb-md-7, .my-md-7 {
    margin-bottom: 6rem !important;
  }

  .ml-md-7, .mx-md-7 {
    margin-left: 6rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .375rem !important;
  }

  .pt-md-1, .py-md-1 {
    padding-top: .375rem !important;
  }

  .pr-md-1, .px-md-1 {
    padding-right: .375rem !important;
  }

  .pb-md-1, .py-md-1 {
    padding-bottom: .375rem !important;
  }

  .pl-md-1, .px-md-1 {
    padding-left: .375rem !important;
  }

  .p-md-2 {
    padding: .75rem !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: .75rem !important;
  }

  .pr-md-2, .px-md-2 {
    padding-right: .75rem !important;
  }

  .pb-md-2, .py-md-2 {
    padding-bottom: .75rem !important;
  }

  .pl-md-2, .px-md-2 {
    padding-left: .75rem !important;
  }

  .p-md-3 {
    padding: 1.5rem !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 1.5rem !important;
  }

  .pr-md-3, .px-md-3 {
    padding-right: 1.5rem !important;
  }

  .pb-md-3, .py-md-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-3, .px-md-3 {
    padding-left: 1.5rem !important;
  }

  .p-md-4 {
    padding: 2.25rem !important;
  }

  .pt-md-4, .py-md-4 {
    padding-top: 2.25rem !important;
  }

  .pr-md-4, .px-md-4 {
    padding-right: 2.25rem !important;
  }

  .pb-md-4, .py-md-4 {
    padding-bottom: 2.25rem !important;
  }

  .pl-md-4, .px-md-4 {
    padding-left: 2.25rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important;
  }

  .p-md-6 {
    padding: 4.5rem !important;
  }

  .pt-md-6, .py-md-6 {
    padding-top: 4.5rem !important;
  }

  .pr-md-6, .px-md-6 {
    padding-right: 4.5rem !important;
  }

  .pb-md-6, .py-md-6 {
    padding-bottom: 4.5rem !important;
  }

  .pl-md-6, .px-md-6 {
    padding-left: 4.5rem !important;
  }

  .p-md-7 {
    padding: 6rem !important;
  }

  .pt-md-7, .py-md-7 {
    padding-top: 6rem !important;
  }

  .pr-md-7, .px-md-7 {
    padding-right: 6rem !important;
  }

  .pb-md-7, .py-md-7 {
    padding-bottom: 6rem !important;
  }

  .pl-md-7, .px-md-7 {
    padding-left: 6rem !important;
  }

  .m-md-n1 {
    margin: -.375rem !important;
  }

  .mt-md-n1, .my-md-n1 {
    margin-top: -.375rem !important;
  }

  .mr-md-n1, .mx-md-n1 {
    margin-right: -.375rem !important;
  }

  .mb-md-n1, .my-md-n1 {
    margin-bottom: -.375rem !important;
  }

  .ml-md-n1, .mx-md-n1 {
    margin-left: -.375rem !important;
  }

  .m-md-n2 {
    margin: -.75rem !important;
  }

  .mt-md-n2, .my-md-n2 {
    margin-top: -.75rem !important;
  }

  .mr-md-n2, .mx-md-n2 {
    margin-right: -.75rem !important;
  }

  .mb-md-n2, .my-md-n2 {
    margin-bottom: -.75rem !important;
  }

  .ml-md-n2, .mx-md-n2 {
    margin-left: -.75rem !important;
  }

  .m-md-n3 {
    margin: -1.5rem !important;
  }

  .mt-md-n3, .my-md-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n3, .mx-md-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n3, .my-md-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n3, .mx-md-n3 {
    margin-left: -1.5rem !important;
  }

  .m-md-n4 {
    margin: -2.25rem !important;
  }

  .mt-md-n4, .my-md-n4 {
    margin-top: -2.25rem !important;
  }

  .mr-md-n4, .mx-md-n4 {
    margin-right: -2.25rem !important;
  }

  .mb-md-n4, .my-md-n4 {
    margin-bottom: -2.25rem !important;
  }

  .ml-md-n4, .mx-md-n4 {
    margin-left: -2.25rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5, .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5, .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5, .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5, .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-n6 {
    margin: -4.5rem !important;
  }

  .mt-md-n6, .my-md-n6 {
    margin-top: -4.5rem !important;
  }

  .mr-md-n6, .mx-md-n6 {
    margin-right: -4.5rem !important;
  }

  .mb-md-n6, .my-md-n6 {
    margin-bottom: -4.5rem !important;
  }

  .ml-md-n6, .mx-md-n6 {
    margin-left: -4.5rem !important;
  }

  .m-md-n7 {
    margin: -6rem !important;
  }

  .mt-md-n7, .my-md-n7 {
    margin-top: -6rem !important;
  }

  .mr-md-n7, .mx-md-n7 {
    margin-right: -6rem !important;
  }

  .mb-md-n7, .my-md-n7 {
    margin-bottom: -6rem !important;
  }

  .ml-md-n7, .mx-md-n7 {
    margin-left: -6rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .375rem !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: .375rem !important;
  }

  .mr-lg-1, .mx-lg-1 {
    margin-right: .375rem !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: .375rem !important;
  }

  .ml-lg-1, .mx-lg-1 {
    margin-left: .375rem !important;
  }

  .m-lg-2 {
    margin: .75rem !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: .75rem !important;
  }

  .mr-lg-2, .mx-lg-2 {
    margin-right: .75rem !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: .75rem !important;
  }

  .ml-lg-2, .mx-lg-2 {
    margin-left: .75rem !important;
  }

  .m-lg-3 {
    margin: 1.5rem !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-3, .mx-lg-3 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-3, .mx-lg-3 {
    margin-left: 1.5rem !important;
  }

  .m-lg-4 {
    margin: 2.25rem !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 2.25rem !important;
  }

  .mr-lg-4, .mx-lg-4 {
    margin-right: 2.25rem !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 2.25rem !important;
  }

  .ml-lg-4, .mx-lg-4 {
    margin-left: 2.25rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .m-lg-6 {
    margin: 4.5rem !important;
  }

  .mt-lg-6, .my-lg-6 {
    margin-top: 4.5rem !important;
  }

  .mr-lg-6, .mx-lg-6 {
    margin-right: 4.5rem !important;
  }

  .mb-lg-6, .my-lg-6 {
    margin-bottom: 4.5rem !important;
  }

  .ml-lg-6, .mx-lg-6 {
    margin-left: 4.5rem !important;
  }

  .m-lg-7 {
    margin: 6rem !important;
  }

  .mt-lg-7, .my-lg-7 {
    margin-top: 6rem !important;
  }

  .mr-lg-7, .mx-lg-7 {
    margin-right: 6rem !important;
  }

  .mb-lg-7, .my-lg-7 {
    margin-bottom: 6rem !important;
  }

  .ml-lg-7, .mx-lg-7 {
    margin-left: 6rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .375rem !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-top: .375rem !important;
  }

  .pr-lg-1, .px-lg-1 {
    padding-right: .375rem !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-bottom: .375rem !important;
  }

  .pl-lg-1, .px-lg-1 {
    padding-left: .375rem !important;
  }

  .p-lg-2 {
    padding: .75rem !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-top: .75rem !important;
  }

  .pr-lg-2, .px-lg-2 {
    padding-right: .75rem !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-bottom: .75rem !important;
  }

  .pl-lg-2, .px-lg-2 {
    padding-left: .75rem !important;
  }

  .p-lg-3 {
    padding: 1.5rem !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-3, .px-lg-3 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-3, .px-lg-3 {
    padding-left: 1.5rem !important;
  }

  .p-lg-4 {
    padding: 2.25rem !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-top: 2.25rem !important;
  }

  .pr-lg-4, .px-lg-4 {
    padding-right: 2.25rem !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-bottom: 2.25rem !important;
  }

  .pl-lg-4, .px-lg-4 {
    padding-left: 2.25rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important;
  }

  .p-lg-6 {
    padding: 4.5rem !important;
  }

  .pt-lg-6, .py-lg-6 {
    padding-top: 4.5rem !important;
  }

  .pr-lg-6, .px-lg-6 {
    padding-right: 4.5rem !important;
  }

  .pb-lg-6, .py-lg-6 {
    padding-bottom: 4.5rem !important;
  }

  .pl-lg-6, .px-lg-6 {
    padding-left: 4.5rem !important;
  }

  .p-lg-7 {
    padding: 6rem !important;
  }

  .pt-lg-7, .py-lg-7 {
    padding-top: 6rem !important;
  }

  .pr-lg-7, .px-lg-7 {
    padding-right: 6rem !important;
  }

  .pb-lg-7, .py-lg-7 {
    padding-bottom: 6rem !important;
  }

  .pl-lg-7, .px-lg-7 {
    padding-left: 6rem !important;
  }

  .m-lg-n1 {
    margin: -.375rem !important;
  }

  .mt-lg-n1, .my-lg-n1 {
    margin-top: -.375rem !important;
  }

  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -.375rem !important;
  }

  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -.375rem !important;
  }

  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -.375rem !important;
  }

  .m-lg-n2 {
    margin: -.75rem !important;
  }

  .mt-lg-n2, .my-lg-n2 {
    margin-top: -.75rem !important;
  }

  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -.75rem !important;
  }

  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -.75rem !important;
  }

  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -.75rem !important;
  }

  .m-lg-n3 {
    margin: -1.5rem !important;
  }

  .mt-lg-n3, .my-lg-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n4 {
    margin: -2.25rem !important;
  }

  .mt-lg-n4, .my-lg-n4 {
    margin-top: -2.25rem !important;
  }

  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -2.25rem !important;
  }

  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -2.25rem !important;
  }

  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -2.25rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5, .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-n6 {
    margin: -4.5rem !important;
  }

  .mt-lg-n6, .my-lg-n6 {
    margin-top: -4.5rem !important;
  }

  .mr-lg-n6, .mx-lg-n6 {
    margin-right: -4.5rem !important;
  }

  .mb-lg-n6, .my-lg-n6 {
    margin-bottom: -4.5rem !important;
  }

  .ml-lg-n6, .mx-lg-n6 {
    margin-left: -4.5rem !important;
  }

  .m-lg-n7 {
    margin: -6rem !important;
  }

  .mt-lg-n7, .my-lg-n7 {
    margin-top: -6rem !important;
  }

  .mr-lg-n7, .mx-lg-n7 {
    margin-right: -6rem !important;
  }

  .mb-lg-n7, .my-lg-n7 {
    margin-bottom: -6rem !important;
  }

  .ml-lg-n7, .mx-lg-n7 {
    margin-left: -6rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .375rem !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: .375rem !important;
  }

  .mr-xl-1, .mx-xl-1 {
    margin-right: .375rem !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: .375rem !important;
  }

  .ml-xl-1, .mx-xl-1 {
    margin-left: .375rem !important;
  }

  .m-xl-2 {
    margin: .75rem !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: .75rem !important;
  }

  .mr-xl-2, .mx-xl-2 {
    margin-right: .75rem !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: .75rem !important;
  }

  .ml-xl-2, .mx-xl-2 {
    margin-left: .75rem !important;
  }

  .m-xl-3 {
    margin: 1.5rem !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-3, .mx-xl-3 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-3, .mx-xl-3 {
    margin-left: 1.5rem !important;
  }

  .m-xl-4 {
    margin: 2.25rem !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 2.25rem !important;
  }

  .mr-xl-4, .mx-xl-4 {
    margin-right: 2.25rem !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 2.25rem !important;
  }

  .ml-xl-4, .mx-xl-4 {
    margin-left: 2.25rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .m-xl-6 {
    margin: 4.5rem !important;
  }

  .mt-xl-6, .my-xl-6 {
    margin-top: 4.5rem !important;
  }

  .mr-xl-6, .mx-xl-6 {
    margin-right: 4.5rem !important;
  }

  .mb-xl-6, .my-xl-6 {
    margin-bottom: 4.5rem !important;
  }

  .ml-xl-6, .mx-xl-6 {
    margin-left: 4.5rem !important;
  }

  .m-xl-7 {
    margin: 6rem !important;
  }

  .mt-xl-7, .my-xl-7 {
    margin-top: 6rem !important;
  }

  .mr-xl-7, .mx-xl-7 {
    margin-right: 6rem !important;
  }

  .mb-xl-7, .my-xl-7 {
    margin-bottom: 6rem !important;
  }

  .ml-xl-7, .mx-xl-7 {
    margin-left: 6rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .375rem !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-top: .375rem !important;
  }

  .pr-xl-1, .px-xl-1 {
    padding-right: .375rem !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-bottom: .375rem !important;
  }

  .pl-xl-1, .px-xl-1 {
    padding-left: .375rem !important;
  }

  .p-xl-2 {
    padding: .75rem !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-top: .75rem !important;
  }

  .pr-xl-2, .px-xl-2 {
    padding-right: .75rem !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-bottom: .75rem !important;
  }

  .pl-xl-2, .px-xl-2 {
    padding-left: .75rem !important;
  }

  .p-xl-3 {
    padding: 1.5rem !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-3, .px-xl-3 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-3, .px-xl-3 {
    padding-left: 1.5rem !important;
  }

  .p-xl-4 {
    padding: 2.25rem !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-top: 2.25rem !important;
  }

  .pr-xl-4, .px-xl-4 {
    padding-right: 2.25rem !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-bottom: 2.25rem !important;
  }

  .pl-xl-4, .px-xl-4 {
    padding-left: 2.25rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important;
  }

  .p-xl-6 {
    padding: 4.5rem !important;
  }

  .pt-xl-6, .py-xl-6 {
    padding-top: 4.5rem !important;
  }

  .pr-xl-6, .px-xl-6 {
    padding-right: 4.5rem !important;
  }

  .pb-xl-6, .py-xl-6 {
    padding-bottom: 4.5rem !important;
  }

  .pl-xl-6, .px-xl-6 {
    padding-left: 4.5rem !important;
  }

  .p-xl-7 {
    padding: 6rem !important;
  }

  .pt-xl-7, .py-xl-7 {
    padding-top: 6rem !important;
  }

  .pr-xl-7, .px-xl-7 {
    padding-right: 6rem !important;
  }

  .pb-xl-7, .py-xl-7 {
    padding-bottom: 6rem !important;
  }

  .pl-xl-7, .px-xl-7 {
    padding-left: 6rem !important;
  }

  .m-xl-n1 {
    margin: -.375rem !important;
  }

  .mt-xl-n1, .my-xl-n1 {
    margin-top: -.375rem !important;
  }

  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -.375rem !important;
  }

  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -.375rem !important;
  }

  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -.375rem !important;
  }

  .m-xl-n2 {
    margin: -.75rem !important;
  }

  .mt-xl-n2, .my-xl-n2 {
    margin-top: -.75rem !important;
  }

  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -.75rem !important;
  }

  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -.75rem !important;
  }

  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -.75rem !important;
  }

  .m-xl-n3 {
    margin: -1.5rem !important;
  }

  .mt-xl-n3, .my-xl-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n4 {
    margin: -2.25rem !important;
  }

  .mt-xl-n4, .my-xl-n4 {
    margin-top: -2.25rem !important;
  }

  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -2.25rem !important;
  }

  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -2.25rem !important;
  }

  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -2.25rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5, .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-n6 {
    margin: -4.5rem !important;
  }

  .mt-xl-n6, .my-xl-n6 {
    margin-top: -4.5rem !important;
  }

  .mr-xl-n6, .mx-xl-n6 {
    margin-right: -4.5rem !important;
  }

  .mb-xl-n6, .my-xl-n6 {
    margin-bottom: -4.5rem !important;
  }

  .ml-xl-n6, .mx-xl-n6 {
    margin-left: -4.5rem !important;
  }

  .m-xl-n7 {
    margin: -6rem !important;
  }

  .mt-xl-n7, .my-xl-n7 {
    margin-top: -6rem !important;
  }

  .mr-xl-n7, .mx-xl-n7 {
    margin-right: -6rem !important;
  }

  .mb-xl-n7, .my-xl-n7 {
    margin-bottom: -6rem !important;
  }

  .ml-xl-n7, .mx-xl-n7 {
    margin-left: -6rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link:after {
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: #0000;
  position: absolute;
  inset: 0;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #3755be !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #263a83 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #212529 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #000 !important;
}

.text-primary-2 {
  color: #ff8e88 !important;
}

a.text-primary-2:hover, a.text-primary-2:focus {
  color: #ff453c !important;
}

.text-primary-3 {
  color: #1b1f3b !important;
}

a.text-primary-3:hover, a.text-primary-3:focus {
  color: #030307 !important;
}

.text-body {
  color: #495057 !important;
}

.text-muted {
  color: #adb5bd !important;
}

.text-black-50 {
  color: #00000080 !important;
}

.text-white-50 {
  color: #ffffff80 !important;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *, :before, :after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #adb5bd;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body, .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered th, .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

section {
  position: relative;
}

.bg-image {
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  left: 0;
}

.bg-image:not([class*="absolute"]) {
  position: absolute;
}

.bg-image + .card-body {
  z-index: 1;
  position: relative;
}

img.bg-image {
  object-fit: cover;
}

.btn {
  letter-spacing: -.02em;
}

.btn.btn-sm, .btn-group-sm > .btn {
  letter-spacing: 0;
}

.btn > span {
  vertical-align: middle;
  display: inline-block;
}

.btn > .icon:first-child {
  margin-right: .5rem;
}

.btn > .icon:last-child {
  margin-left: .5rem;
}

.btn > .icon.d-block {
  height: 2rem;
  margin: 0 auto .75rem;
}

.btn.btn-sm > .icon, .btn-group-sm > .btn > .icon {
  height: 1rem;
}

.btn:focus {
  box-shadow: none !important;
}

[class*="-flex"] > .btn {
  flex-shrink: 0;
}

.btn.btn-primary *, .btn-outline-primary:hover *, .btn.btn-secondary *, .btn-outline-secondary:hover *, .btn.btn-success *, .btn-outline-success:hover *, .btn.btn-info *, .btn-outline-info:hover * {
  fill: #fff;
}

.btn.btn-warning *, .btn-outline-warning:hover * {
  fill: #212529;
}

.btn.btn-danger *, .btn-outline-danger:hover * {
  fill: #fff;
}

.btn.btn-light *, .btn-outline-light:hover * {
  fill: #212529;
}

.btn.btn-dark *, .btn-outline-dark:hover * {
  fill: #fff;
}

.btn.btn-primary-2 *, .btn-outline-primary-2:hover * {
  fill: #212529;
}

.btn.btn-primary-3 *, .btn-outline-primary-3:hover * {
  fill: #fff;
}

.btn-light {
  background: #f8f9fa;
  border-color: #dee2e6;
}

.btn-white {
  color: #3755be;
  background: #fff;
}

.btn-white > .icon path {
  fill: #3755be;
}

.btn-white:hover {
  color: #3755be;
  background: #ffffffe6;
}

.btn-outline-white {
  border-color: #dee2e6;
}

.btn-round {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.btn-round > .icon:only-child {
  margin: 0;
}

.btn-round > .icon.icon-sm {
  height: 1rem;
}

.btn-round.btn-sm, .btn-group-sm > .btn-round.btn {
  width: 2rem;
  height: 2rem;
}

.btn-round.btn-lg, .btn-group-lg > .btn-round.btn {
  width: 3.75rem;
  height: 3.75rem;
}

.btn-round.btn-xlg {
  width: 4.5rem;
  height: 4.5rem;
}

.btn-round.btn-lg > .icon, .btn-group-lg > .btn-round.btn > .icon, .btn-round.btn-xlg > .icon {
  height: 2rem;
}

.btn-loading:not(.btn-loading-animate) > span {
  vertical-align: baseline;
}

.btn-loading .icon {
  display: none;
}

.btn-loading.btn-loading-animate .icon {
  display: inline-block;
}

.btn-loading.btn-loading-animate .icon path {
  transform-origin: 12px;
  animation: spin .5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.btn.back-to-top {
  z-index: 1020;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
}

.card {
  margin-bottom: 1.5rem;
}

.card.card-sm.card-body {
  padding: 1.5rem;
}

.card[class*="bg-primary"]:not([class*="hover-bg-primary"]) {
  border-color: #0000;
}

.card[class*="hover-"] {
  transition: background-color .2s, box-shadow .2s, transform .2s;
}

.card > .bg-image {
  border-radius: .625rem;
}

@media (min-width: 576px) {
  .card.card-lg.card-body {
    padding: 3rem;
  }
}

@media (max-width: 767.98px) {
  .card {
    margin-bottom: .75rem;
  }
}

.card + .card {
  margin-top: 1.5rem;
}

.card.card-sm + .card-sm {
  margin-top: .75rem;
}

a.card {
  color: #495057;
  font-weight: 400;
  transition: all .1s;
}

a.card[class*="hover-shadow"]:hover {
  transform: translate3d(-4px, -4px, 0)rotateZ(-1deg);
}

@media (min-width: 768px) {
  .card-icon-2 {
    min-height: 22rem;
  }
}

.card-icon-3 {
  overflow: hidden;
}

.card-icon-3 h3 {
  margin-bottom: 1rem;
}

.card-icon-3 .badge {
  max-height: 1.5rem;
  position: absolute;
  top: 3rem;
  right: 3rem;
}

@media (min-width: 768px) {
  .card-icon-3 {
    min-height: 28rem;
  }

  .card-icon-3 .icon-round {
    position: relative;
    bottom: 1rem;
    right: 1rem;
    transform: scale(2.5);
  }
}

.card-customer-1 h4 {
  margin-bottom: .75rem;
}

.card-article-wide {
  overflow: hidden;
}

.card-article-wide .card-img-top {
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .card-article-wide {
    min-height: 22.5rem;
  }

  .card-article-wide .card-img-top {
    border-radius: .625rem 0 0 .625rem;
  }
}

.dropdown-toggle {
  cursor: pointer;
  outline: none;
}

.dropdown-toggle:after {
  display: none !important;
}

.dropdown-toggle.btn > .icon:last-child {
  margin: 0;
  transform: rotateZ(-90deg);
}

.dropright .dropdown-toggle.btn > .icon:last-child {
  transform: rotateZ(180deg);
}

.dropup .dropdown-toggle.btn > .icon:last-child {
  transform: rotateZ(90deg);
}

.dropleft .dropdown-toggle:before {
  display: none;
}

.dropleft .dropdown-toggle.btn > .icon:first-child {
  transform: rotateZ(0deg);
}

.dropdown-item {
  font-weight: 600;
  transition: color .1s;
}

.dropdown-header {
  font-size: inherit;
  padding-bottom: 0;
}

.dropdown-menu {
  box-shadow: 0 .125rem .625rem #0000000d;
}

.dropdown-menu h6 {
  margin-bottom: 1rem;
}

.dropdown-menu.text-light .dropdown-item {
  color: #ffffff80;
}

.dropdown-menu.text-light .dropdown-item:hover, .dropdown-menu.text-light .dropdown-item:active {
  color: #fff;
}

[data-dropdown-content] .dropdown-item {
  padding-left: 0;
  padding-right: 0;
}

.dropdown-divider {
  margin: 0;
}

[data-toggle="dropdown-grid"] + .dropdown-menu {
  width: 100vw;
  max-width: none;
  box-shadow: none;
  background: none;
  border: none;
  border-radius: 0;
  margin-top: 0;
  padding: 0;
}

[data-toggle="dropdown-grid"] + .dropdown-menu .dropdown-menu {
  margin-top: 0;
  top: 0;
}

[data-toggle="dropdown-grid"] + .dropdown-menu .card {
  margin-bottom: 0;
}

[data-toggle="dropdown-grid"] + .dropdown-menu .card.card-sm {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.dropdown-menu.row {
  pointer-events: none;
}

.dropdown-menu.row.show {
  display: flex;
}

@media (min-width: 992px) {
  .dropdown-menu.row [class*="col"] {
    padding-left: 0;
    padding-right: 0;
  }
}

[data-dropdown-content] {
  pointer-events: all;
}

.dropdown-menu .dropdown {
  position: relative;
}

.dropdown-menu .dropdown .dropdown-toggle {
  padding-right: 3rem;
}

.dropdown-menu .dropdown .dropdown-toggle .icon {
  transition: opacity .2s, transform .2s;
  position: absolute;
  right: 0;
  transform: rotateZ(180deg);
}

.dropdown-menu .dropdown .dropdown-toggle:focus .icon {
  opacity: 1;
  transform: rotateZ(180deg)translateX(-4px);
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .dropdown-menu, .navbar-expand-lg [data-dropdown-content] {
    left: 0 !important;
  }

  .navbar-expand-lg [data-toggle="dropdown-grid"] + .dropdown-menu {
    width: auto;
    position: relative;
    top: auto;
  }

  .navbar-expand-lg .dropdown-menu .dropdown .dropdown-toggle .icon {
    transform: rotateZ(270deg);
  }
}

body[data-dropdown-grid-hover="true"] [data-toggle="dropdown-grid"]:hover:before, body[data-dropdown-grid-hover="true"] [data-dropdown-content]:not(.container):hover:before, [data-toggle="dropdown-grid"][data-hover="true"]:hover:before {
  content: "";
  width: 100%;
  height: 3rem;
  position: absolute;
  top: 100%;
  left: 0;
}

body[data-dropdown-grid-hover="true"] [data-toggle="dropdown-grid"] + .dropdown-menu [data-toggle="dropdown-grid"]:hover:before, body[data-dropdown-grid-hover="true"] [data-dropdown-content]:not(.container) + .dropdown-menu [data-toggle="dropdown-grid"]:hover:before, [data-toggle="dropdown-grid"][data-hover="true"] + .dropdown-menu [data-toggle="dropdown-grid"]:hover:before {
  width: 140%;
  height: 110%;
  top: 0;
  left: -20%;
}

[data-dropdown-content]:not(.container):hover:before {
  width: calc(100% + 3rem);
  height: calc(100% + 3rem);
  padding: 3rem;
  top: -1.5rem;
  left: -1.5rem;
}

@media (min-width: 992px) {
  .dropdown-grid-menu {
    min-width: 10rem;
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: .3125rem;
    padding: 1.125rem;
    box-shadow: 0 .625rem 1.5rem #00000014;
  }
}

@media (max-width: 1199.98px) {
  .dropdown-grid-menu {
    border-left: 1px solid #dee2e6;
    padding-left: .75rem;
  }

  .navbar-dark .dropdown-grid-menu {
    border-color: #ffffff40;
  }
}

footer {
  padding: 6rem 0;
}

.row[class*="flex-"][class*="-column"] > [class*="col"][class*="my-"]:first-child {
  margin-top: 0 !important;
}

.row[class*="flex-"][class*="-column"] > [class*="col"][class*="my-"]:last-child {
  margin-bottom: 0 !important;
}

@media (max-width: 767px) {
  .row:not(.no-gutters) > [class*="col-6"]:nth-child(2n+1) {
    padding-right: .375rem;
  }

  .row:not(.no-gutters) > [class*="col-6"]:nth-child(2n) {
    padding-left: .375rem;
  }
}

@media (min-width: 576px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.row-tight {
  margin-left: -.375rem;
  margin-right: -.375rem;
}

.row-tight > [class*="col"] {
  padding: 0 .375rem;
}

img[class*="flex-"][class*="-grow-1"] {
  object-fit: cover;
}

img {
  max-width: 100%;
}

.navbar {
  width: 100%;
  border-bottom: 1px solid #1b1f3b14;
}

.navbar[data-overlay] {
  position: absolute;
}

.navbar[data-sticky="top"] {
  transition: all .2s;
}

.navbar[data-sticky="top"].scrolled.navbar-dark {
  background: #1b1f3b;
  border-bottom-color: #0000;
}

.navbar[data-sticky="top"].scrolled.navbar-light {
  background: #fff !important;
}

.navbar[data-overlay], .navbar[data-sticky="top"] {
  z-index: 999;
}

.navbar[data-overlay].scrolled, .navbar[data-sticky="top"].scrolled {
  z-index: 1030;
}

.navbar .dropdown-menu {
  z-index: 1060;
}

.navbar .nav-link {
  transition: color .2s;
}

.navbar-container {
  position: relative;
}

.navbar-light .navbar-toggler svg path {
  fill: #212529;
}

.navbar-dark {
  border-bottom: 1px solid #ffffff14;
}

.navbar-dark .navbar-toggler svg path, .navbar-dark.navbar-toggled-show .dropdown-toggle > svg.bg-dark * {
  fill: #fff;
}

.navbar-toggler {
  border: none;
  position: relative;
}

.navbar-toggler[aria-expanded="false"] .navbar-toggler-close {
  opacity: 0;
  transform: scale(0)rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-open {
  opacity: 0;
  transform: scale(0)rotate(-45deg);
}

.navbar-toggler-open, .navbar-toggler-close {
  opacity: 1;
  transition: opacity .2s, transform .2s;
  transform: scale(1);
}

.navbar-toggler-close {
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 992px) {
  .navbar {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar .dropdown-menu {
    margin-top: 1rem;
  }
}

nav[data-overlay].navbar-toggled-show {
  background: #fff;
}

nav[data-overlay].navbar-toggled-show.navbar-dark {
  background: #1b1f3b;
}

nav[data-overlay].navbar-toggled-show.navbar-dark .dropdown-item {
  color: #ffffff80;
}

nav[data-overlay].navbar-toggled-show.navbar-dark .dropdown-item:hover {
  color: #fff;
}

@media (max-width: 991.98px) {
  .navbar-collapse {
    max-height: 100vh;
    overflow-y: scroll;
  }
}

@media (max-width: 991.98px) {
  .nav-item.dropdown .col-auto {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.nav-link.disabled {
  opacity: .25;
}

section .nav-link, footer .nav-link {
  transition: opacity .2s linear;
}

section .nav-link:not(.active):not(.disabled):not(:hover), footer .nav-link:not(.active):not(.disabled):not(:hover) {
  opacity: .5;
}

section .nav-link .icon, footer .nav-link .icon {
  transform: translate3d(0, 0, 0);
}

section .nav-link[aria-expanded="true"], footer .nav-link[aria-expanded="true"] {
  opacity: 1 !important;
}

section:not(.text-light) .nav-link, footer:not(.text-light) .nav-link {
  color: #212529;
}

.nav-link.btn.active {
  color: #fff;
  background: #3755be;
}

.nav-link.btn-light.active, .nav-link.btn-light:active {
  background: #f8f9fa !important;
  border-color: #dee2e6 !important;
}

.nav-link.btn-light:not(.active) {
  background: none;
  border-color: #0000;
}

.nav-link.btn-light .icon * {
  fill: #3755be;
}

.nav-link .icon-round {
  margin-bottom: .75rem;
  margin-left: auto;
  margin-right: auto;
}

.nav-link:not(.active):not(:active) .icon-round {
  background-color: #0000 !important;
}

.nav.flex-column {
  flex-flow: column;
}

.nav.flex-column .nav-link {
  padding-top: 0;
  padding-left: 0;
}

.nav-link[data-toggle="collapse"] > .icon {
  position: relative;
  right: .25rem;
  transform: rotateZ(-90deg);
}

img[data-inject-svg] {
  opacity: 0;
}

svg.icon * {
  fill: #3755be;
}

svg.icon[class*="bg-"] {
  background: none !important;
}

svg.bg-white * {
  fill: #fff;
}

svg.bg-primary * {
  fill: #3755be;
}

svg.bg-primary-alt * {
  fill: #f3f5fb;
}

svg.bg-secondary * {
  fill: #6c757d;
}

svg.bg-secondary-alt * {
  fill: #f6f7f7;
}

svg.bg-success * {
  fill: #28a745;
}

svg.bg-success-alt * {
  fill: #f2faf4;
}

svg.bg-info * {
  fill: #17a2b8;
}

svg.bg-info-alt * {
  fill: #f1f9fb;
}

svg.bg-warning * {
  fill: #ffc107;
}

svg.bg-warning-alt * {
  fill: #fffbf0;
}

svg.bg-danger * {
  fill: #dc3545;
}

svg.bg-danger-alt * {
  fill: #fdf3f4;
}

svg.bg-light * {
  fill: #f8f9fa;
}

svg.bg-light-alt * {
  fill: #fff;
}

svg.bg-dark * {
  fill: #212529;
}

svg.bg-dark-alt * {
  fill: #f2f2f2;
}

svg.bg-primary-2 * {
  fill: #ff8e88;
}

svg.bg-primary-2-alt * {
  fill: #fff8f8;
}

svg.bg-primary-3 * {
  fill: #1b1f3b;
}

svg.bg-primary-3-alt * {
  fill: #f1f2f3;
}

.text-light svg.icon:not([class*="bg-"]) * {
  fill: #fff;
}

.text-primary svg.icon:not([class*="bg-"]) * {
  fill: #3755be;
}

@font-face {
  font-family: Inter UI;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+0-5FF;
  src: url("Inter-UI-upright.var.bab7c391.woff2") format("woff2-variations"), url("Inter-UI-Regular.ace1c64c.woff2") format("woff2"), url("Inter-UI-Regular.716fa801.woff") format("woff");
}

@font-face {
  font-family: Inter UI;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+0-5FF;
  src: url("Inter-UI.var.8ffcdd4f.woff2") format("woff2-variations"), url("Inter-UI-Italic.971f9989.woff2") format("woff2"), url("Inter-UI-Italic.f471eaa4.woff") format("woff");
}

@font-face {
  font-family: Inter UI;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  unicode-range: U+0-5FF;
  src: url("Inter-UI-upright.var.bab7c391.woff2") format("woff2-variations"), url("Inter-UI-Medium.c8597681.woff2") format("woff2"), url("Inter-UI-Medium.105399a0.woff") format("woff");
}

@font-face {
  font-family: Inter UI;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  unicode-range: U+0-5FF;
  src: url("Inter-UI.var.8ffcdd4f.woff2") format("woff2-variations"), url("Inter-UI-MediumItalic.eff01856.woff2") format("woff2"), url("Inter-UI-MediumItalic.62b9a08c.woff") format("woff");
}

@font-face {
  font-family: Inter UI;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  unicode-range: U+0-5FF;
  src: url("Inter-UI-upright.var.bab7c391.woff2") format("woff2-variations"), url("Inter-UI-Bold.6c95cea8.woff2") format("woff2"), url("Inter-UI-Bold.28819779.woff") format("woff");
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4, .display-1, .display-2, .display-3, .display-4 {
  letter-spacing: -.03em;
}

h5, .h5 {
  line-height: 1.5;
}

@media (max-width: 1199.98px) {
  html {
    font-size: .9375rem;
  }
}

@media (max-width: 767.98px) {
  html {
    font-size: .875rem;
  }
}

@media (max-width: 991.98px) {
  .display-2 {
    font-size: 3.5rem;
  }
}

.display-1, .display-2, .display-3, .display-4 {
  color: #212529;
  margin-bottom: 1.5rem;
  line-height: 1;
}

.display-4 {
  line-height: 1.1;
}

@media (max-width: 1199.98px) {
  .display-3 {
    font-size: 3.5rem;
    line-height: 4rem;
  }
}

@media (max-width: 767.98px) {
  .display-4 {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

h5 {
  margin-bottom: .75rem;
}

h6 {
  line-height: 1.375rem;
}

a {
  color: #3755be;
  font-weight: 600;
}

a.lead, small, .text-small {
  font-weight: 600;
}

.text-small {
  font-size: .875rem;
  line-height: 1.25rem;
}

small {
  line-height: 1.1875rem;
  display: inline-block;
}

small.text-uppercase {
  letter-spacing: .08em;
}

strong {
  font-weight: 600;
}

.text-light a:not(.btn), .text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5, .text-light h6, .text-light .h1, .text-light .h2, .text-light .h3, .text-light .h4, .text-light .h5, .text-light .h6, .text-light .display-1, .text-light .display-2, .text-light .display-3, .text-light .display-4 {
  color: #fff;
}

.text-light .text-muted {
  color: #ffffffb3 !important;
}

.highlight {
  background: #ff8e8833;
  display: inline;
}

.alert {
  word-break: break-word;
}

.o-hidden {
  overflow: hidden;
}

.o-visible {
  overflow: visible;
}

@media (min-width: 992px) {
  .o-lg-visible {
    overflow: visible;
  }
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: .1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-30 {
  opacity: .3;
}

.opacity-40 {
  opacity: .4;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.opacity-80 {
  opacity: .8;
}

.opacity-90 {
  opacity: .9;
}

section {
  padding: 6rem 0;
}

.h-0 {
  height: 0;
}

.min-vh-10 {
  min-height: 10vh;
}

.min-vh-20 {
  min-height: 20vh;
}

.min-vh-30 {
  min-height: 30vh;
}

.min-vh-40 {
  min-height: 40vh;
}

.min-vh-50 {
  min-height: 50vh;
}

.min-vh-60 {
  min-height: 60vh;
}

.min-vh-70 {
  min-height: 70vh;
}

.min-vh-80 {
  min-height: 80vh;
}

.min-vh-90 {
  min-height: 90vh;
}

.min-vh-100 {
  min-height: 100vh;
}

@media (min-width: 768px) {
  .min-vh-md-10 {
    min-height: 10vh;
  }

  .min-vh-md-20 {
    min-height: 20vh;
  }

  .min-vh-md-30 {
    min-height: 30vh;
  }

  .min-vh-md-40 {
    min-height: 40vh;
  }

  .min-vh-md-50 {
    min-height: 50vh;
  }

  .min-vh-md-60 {
    min-height: 60vh;
  }

  .min-vh-md-70 {
    min-height: 70vh;
  }

  .min-vh-md-80 {
    min-height: 80vh;
  }

  .min-vh-md-90 {
    min-height: 90vh;
  }

  .min-vh-md-100 {
    min-height: 100vh;
  }
}

@media (min-width: 992px) {
  .min-vh-lg-10 {
    min-height: 10vh;
  }

  .min-vh-lg-20 {
    min-height: 20vh;
  }

  .min-vh-lg-30 {
    min-height: 30vh;
  }

  .min-vh-lg-40 {
    min-height: 40vh;
  }

  .min-vh-lg-50 {
    min-height: 50vh;
  }

  .min-vh-lg-60 {
    min-height: 60vh;
  }

  .min-vh-lg-70 {
    min-height: 70vh;
  }

  .min-vh-lg-80 {
    min-height: 80vh;
  }

  .min-vh-lg-90 {
    min-height: 90vh;
  }

  .min-vh-lg-100 {
    min-height: 100vh;
  }
}

.min-vw-10 {
  min-width: 10vw;
}

.min-vw-20 {
  min-width: 20vw;
}

.min-vw-30 {
  min-width: 30vw;
}

.min-vw-40 {
  min-width: 40vw;
}

.min-vw-50 {
  min-width: 50vw;
}

.min-vw-60 {
  min-width: 60vw;
}

.min-vw-70 {
  min-width: 70vw;
}

.min-vw-80 {
  min-width: 80vw;
}

.min-vw-90 {
  min-width: 90vw;
}

.min-vw-100 {
  min-width: 100vw;
}

.text-white p a {
  color: #fff;
}

.text-strikethrough {
  text-decoration: line-through;
}

.overlay .container {
  z-index: 1;
  position: relative;
}

.overlay:after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(#0000 0%, #000000a6 100%);
  position: absolute;
  bottom: 0;
  left: 0;
}

.overlay .divider {
  z-index: 1;
}

.overlay.card:after {
  border-radius: 0 0 .625rem .625rem;
}

.overlay > .position-absolute {
  z-index: 2;
}

.shadow-3d {
  box-shadow: .5rem .5rem #e9ecef80;
}

.bg-primary-alt {
  background: #f3f5fb;
}

.bg-primary-2-alt {
  background: #fff8f8;
}

.bg-primary-3-alt {
  background: #f1f2f3;
}

img.rounded, div.rounded {
  border-radius: .625rem !important;
}

.rounded-sm, img.rounded-sm {
  border-radius: .3125rem !important;
}

.bg-primary-3.border-bottom {
  border-color: #ffffff14 !important;
}

.position-relative {
  z-index: 1;
}

.position-absolute[style] {
  z-index: 2;
}

.sticky-top {
  top: 1.5rem;
}

.top-0 {
  top: 0;
  bottom: auto;
}

.layer-1 {
  z-index: 1;
}

.layer-2 {
  z-index: 2;
}

.layer-3 {
  z-index: 3;
}

.layer-4 {
  z-index: 4;
}

[class*="layer-"] {
  position: relative;
}

.blend-mode-multiply {
  mix-blend-mode: multiply;
}

.flip-x {
  transform: scaleX(-1);
}

.flip-y {
  transform: scaleY(-1);
}

.rotate-left {
  transform: rotateZ(-1deg);
}

.rotate-left.mobile-screenshot {
  transform: rotateZ(-5deg);
}

.rotate-right {
  transform: rotateZ(1deg);
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing="linear"], body[data-aos-easing="linear"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .25, .75, .75);
}

[data-aos][data-aos][data-aos-easing="ease"], body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing="ease-in"], body[data-aos-easing="ease-in"] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing="ease-out"], body[data-aos-easing="ease-out"] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-out"], body[data-aos-easing="ease-in-out"] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-back"], body[data-aos-easing="ease-in-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

[data-aos][data-aos][data-aos-easing="ease-out-back"], body[data-aos-easing="ease-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-back"], body[data-aos-easing="ease-in-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

[data-aos][data-aos][data-aos-easing="ease-in-sine"], body[data-aos-easing="ease-in-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.47, 0, .745, .715);
}

[data-aos][data-aos][data-aos-easing="ease-out-sine"], body[data-aos-easing="ease-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-sine"], body[data-aos-easing="ease-in-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
}

[data-aos][data-aos][data-aos-easing="ease-in-quad"], body[data-aos-easing="ease-in-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quad"], body[data-aos-easing="ease-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quad"], body[data-aos-easing="ease-in-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-cubic"], body[data-aos-easing="ease-in-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-cubic"], body[data-aos-easing="ease-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-cubic"], body[data-aos-easing="ease-in-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-quart"], body[data-aos-easing="ease-in-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quart"], body[data-aos-easing="ease-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quart"], body[data-aos-easing="ease-in-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos="fade-up"] {
  transform: translate3d(0, 100px, 0);
}

[data-aos="fade-down"] {
  transform: translate3d(0, -100px, 0);
}

[data-aos="fade-right"] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos="fade-left"] {
  transform: translate3d(100px, 0, 0);
}

[data-aos="fade-up-right"] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos="fade-up-left"] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos="fade-down-right"] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos="fade-down-left"] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^="zoom"][data-aos^="zoom"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
  opacity: 1;
  transform: translateZ(0)scale(1);
}

[data-aos="zoom-in"] {
  transform: scale(.6);
}

[data-aos="zoom-in-up"] {
  transform: translate3d(0, 100px, 0)scale(.6);
}

[data-aos="zoom-in-down"] {
  transform: translate3d(0, -100px, 0)scale(.6);
}

[data-aos="zoom-in-right"] {
  transform: translate3d(-100px, 0, 0)scale(.6);
}

[data-aos="zoom-in-left"] {
  transform: translate3d(100px, 0, 0)scale(.6);
}

[data-aos="zoom-out"] {
  transform: scale(1.2);
}

[data-aos="zoom-out-up"] {
  transform: translate3d(0, 100px, 0)scale(1.2);
}

[data-aos="zoom-out-down"] {
  transform: translate3d(0, -100px, 0)scale(1.2);
}

[data-aos="zoom-out-right"] {
  transform: translate3d(-100px, 0, 0)scale(1.2);
}

[data-aos="zoom-out-left"] {
  transform: translate3d(100px, 0, 0)scale(1.2);
}

[data-aos^="slide"][data-aos^="slide"] {
  transition-property: transform;
}

[data-aos^="slide"][data-aos^="slide"].aos-animate {
  transform: translateZ(0);
}

[data-aos="slide-up"] {
  transform: translate3d(0, 100%, 0);
}

[data-aos="slide-down"] {
  transform: translate3d(0, -100%, 0);
}

[data-aos="slide-right"] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos="slide-left"] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^="flip"][data-aos^="flip"] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos="flip-left"] {
  transform: perspective(2500px)rotateY(-100deg);
}

[data-aos="flip-left"].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos="flip-right"] {
  transform: perspective(2500px)rotateY(100deg);
}

[data-aos="flip-right"].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos="flip-up"] {
  transform: perspective(2500px)rotateX(-100deg);
}

[data-aos="flip-up"].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos="flip-down"] {
  transform: perspective(2500px)rotateX(100deg);
}

[data-aos="flip-down"].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

.accordion-panel-title {
  cursor: pointer;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.accordion-panel-title > .icon {
  transition: transform .1s;
}

.accordion-panel-title > * {
  transition: color .1s;
}

.accordion-panel-title .h6 + .icon {
  height: 1rem;
}

.accordion-panel-title[aria-expanded="true"] > .icon {
  transform: rotateZ(45deg);
}

.accordion-panel-title[aria-expanded="true"] > *, .accordion-panel-title:hover > * {
  color: #3755be;
}

.accordion-panel-title[aria-expanded="true"] > * path, .accordion-panel-title:hover > * path {
  fill: #3755be;
}

.article p.lead:first-child {
  margin-top: 4.5rem;
  margin-bottom: 3rem;
}

.article p + h4 {
  margin-top: 3rem;
}

.article p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.article blockquote {
  margin: 3rem 0;
}

.article p + h5 {
  margin-top: 2.25rem;
}

.article ul {
  padding-left: 1.5rem;
}

.article ul > li {
  margin-bottom: .75rem;
}

article ol > li:not(:last-child) {
  margin-bottom: 3rem;
}

figure {
  text-align: center;
  margin: 3rem 0;
}

figcaption {
  color: #adb5bd;
  margin-top: .75rem;
  font-size: .875rem;
  font-weight: 600;
}

blockquote {
  border-radius: .625rem;
  padding: 3rem;
  position: relative;
}

blockquote .btn {
  position: absolute;
  bottom: .75rem;
  right: .75rem;
}

.comments {
  padding-left: 0;
  list-style: none;
}

.comments .comment:not(:last-child) {
  margin-bottom: 1.5rem;
}

.comments .comment .comments {
  margin-top: 1.5rem;
}

.comments .comment .comment {
  border-left: 4px solid #dee2e6;
  padding-left: 2.25rem;
}

.list-articles {
  margin-bottom: 0;
}

.list-articles > li:not(:last-child) {
  margin-bottom: .75rem;
}

.col-lg-3 .list-articles img {
  min-height: 3.75rem;
  object-fit: cover;
}

.col-lg-3 .list-articles img.rounded {
  border-radius: .3125rem !important;
}

.col-lg-4 .list-articles img {
  min-height: 6.75rem;
  object-fit: cover;
}

.col-lg-4 .list-articles img.rounded {
  border-radius: .3125rem !important;
}

@media (min-width: 768px) {
  .article-portfolio > :not(figure) {
    max-width: 36rem;
    margin-left: auto;
    margin-right: auto;
  }

  .article-portfolio figure {
    margin: 6rem 0;
  }
}

.avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  flex-shrink: 0;
}

.avatar.avatar-sm {
  width: 2.25rem;
  height: 2.25rem;
}

.avatar.avatar-lg {
  width: 4.5rem;
  height: 4.5rem;
}

.avatar.avatar-xlg {
  width: 7.5rem;
  height: 7.5rem;
}

@media (min-width: 992px) {
  .avatar.avatar-lg {
    width: 4.5rem;
    height: 4.5rem;
  }
}

.avatars {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.avatars > li {
  position: relative;
}

.avatars > li:not(:last-child) {
  margin-right: -.75rem;
}

.avatars .avatar {
  border: 2px solid #fff;
}

.avatar-author {
  display: flex;
}

.avatar-author h6 {
  margin-bottom: 0;
}

.badge {
  text-transform: uppercase;
  letter-spacing: .02em;
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}

.badge .icon {
  height: .75rem;
  vertical-align: top;
}

.badge.position-absolute {
  bottom: auto;
}

.badge.badge-top {
  position: absolute;
  top: -.75rem;
  bottom: auto;
}

.breadcrumb {
  font-weight: 600;
  display: inline-flex;
}

.breadcrumb-item {
  height: 1.5rem;
  align-items: center;
  font-size: .875rem;
  display: flex;
}

.breadcrumb-item a {
  transition: opacity .1s linear;
}

.breadcrumb-item a:not(:hover) {
  opacity: .75;
}

.breadcrumb-item + .breadcrumb-item:before {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  opacity: .2;
  background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.4444 8.41358C14.7776 8.2281 15.1875 8.46907 15.1875 8.85048V15.1495C15.1875 15.5309 14.7776 15.7719 14.4444 15.5864L8.78505 12.4369C8.44258 12.2463 8.44258 11.7537 8.78505 11.5631L14.4444 8.41358Z' fill='%23495057'/></svg>");
  margin-right: .25rem;
  padding: 0;
  transform: rotateZ(180deg);
}

.breadcrumb-item .icon {
  height: 1rem;
  opacity: .2;
  margin-left: .25rem;
  transform: rotateZ(180deg);
}

.text-light .breadcrumb-item.active {
  color: #ffffff80;
}

.text-light .breadcrumb-item + .breadcrumb-item:before {
  background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.4444 8.41358C14.7776 8.2281 15.1875 8.46907 15.1875 8.85048V15.1495C15.1875 15.5309 14.7776 15.7719 14.4444 15.5864L8.78505 12.4369C8.44258 12.2463 8.44258 11.7537 8.78505 11.5631L14.4444 8.41358Z' fill='%23FFFFFF'/></svg>");
}

.text-light .breadcrumb {
  background: #ffffff1a;
}

@media (max-width: 767.98px) {
  .breadcrumb-item + .breadcrumb-item:before {
    margin-left: .5rem;
    position: relative;
    top: 3px;
  }
}

.decoration:not(.position-relative) {
  position: absolute;
}

.decoration svg {
  background: none !important;
}

.decoration.top {
  top: 0;
}

.decoration.left {
  left: 0;
}

.decoration.right {
  right: 0;
}

.decoration.middle-y {
  top: 50%;
}

.decoration.middle-x {
  left: 50%;
}

.decoration.bottom {
  bottom: 0;
}

.decoration.scale-2 svg {
  transform: scale(2);
}

.decoration.scale-3 svg {
  transform: scale(3);
}

.decoration.scale-4 svg {
  transform: scale(4);
}

.decoration.scale-5 svg {
  transform: scale(5);
}

.decoration-check {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.decoration-check .decoration {
  max-width: 250px;
  position: relative;
}

.decoration-check .icon {
  height: 4rem;
  position: absolute;
}

.mobile-screenshot {
  position: relative;
}

.mobile-screenshot > img:first-child {
  padding: 7%;
}

.mobile-screenshot > img:last-child {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.divider {
  position: relative;
}

.divider svg {
  height: 6vw;
  background: none !important;
}

.divider svg:not([class*="bg"]) path {
  fill: #fff;
}

.divider:not(:first-child) {
  top: 1px;
}

.divider:first-child {
  bottom: 1px;
}

.divider.divider-vertical {
  height: 100vh;
  width: 6rem;
  top: 0;
  left: 1px;
}

.divider.divider-vertical svg {
  height: 100vh;
  width: 6rem;
}

section.has-divider {
  padding: 0;
}

section.has-divider .container {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

body.compensate-for-scrollbar {
  overflow: hidden;
}

.fancybox-active {
  height: auto;
}

.fancybox-is-hidden {
  visibility: hidden;
  margin: 0;
  top: -9999px;
  left: -9999px;
  position: absolute !important;
}

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  width: 100%;
  z-index: 99992;
  outline: none;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateZ(0);
}

.fancybox-container * {
  box-sizing: border-box;
}

.fancybox-bg, .fancybox-inner, .fancybox-outer, .fancybox-stage {
  position: absolute;
  inset: 0;
}

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.fancybox-bg {
  opacity: 0;
  transition-duration: inherit;
  background: #1e1e1e;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(.47, 0, .74, .71);
}

.fancybox-is-open .fancybox-bg {
  opacity: .9;
  transition-timing-function: cubic-bezier(.22, .61, .36, 1);
}

.fancybox-caption, .fancybox-infobar, .fancybox-navigation .fancybox-button, .fancybox-toolbar {
  direction: ltr;
  opacity: 0;
  visibility: hidden;
  z-index: 99997;
  transition: opacity .25s, visibility 0s .25s;
  position: absolute;
}

.fancybox-show-caption .fancybox-caption, .fancybox-show-infobar .fancybox-infobar, .fancybox-show-nav .fancybox-navigation .fancybox-button, .fancybox-show-toolbar .fancybox-toolbar {
  opacity: 1;
  visibility: visible;
  transition: opacity .25s, visibility;
}

.fancybox-infobar {
  color: #ccc;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  pointer-events: none;
  -webkit-touch-callout: none;
  user-select: none;
  padding: 0 10px;
  font-size: 13px;
  line-height: 44px;
  top: 0;
  left: 0;
}

.fancybox-toolbar {
  top: 0;
  right: 0;
}

.fancybox-stage {
  direction: ltr;
  z-index: 99994;
  overflow: visible;
  transform: translateZ(0);
}

.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  white-space: normal;
  width: 100%;
  z-index: 99994;
  outline: none;
  padding: 44px;
  transition-property: transform, opacity;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}

.fancybox-slide:before {
  content: "";
  height: 100%;
  vertical-align: middle;
  width: 0;
  font-size: 0;
  display: inline-block;
}

.fancybox-is-sliding .fancybox-slide, .fancybox-slide--current, .fancybox-slide--next, .fancybox-slide--previous {
  display: block;
}

.fancybox-slide--image {
  padding: 44px 0;
  overflow: hidden;
}

.fancybox-slide--image:before {
  display: none;
}

.fancybox-slide--html {
  padding: 6px;
}

.fancybox-content {
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  text-align: left;
  vertical-align: middle;
  background: #fff;
  margin: 0;
  padding: 44px;
  display: inline-block;
  position: relative;
  overflow: auto;
}

.fancybox-slide--image .fancybox-content {
  -webkit-backface-visibility: hidden;
  max-width: none;
  transform-origin: 0 0;
  user-select: none;
  z-index: 99995;
  background: 0 0 / 100% 100% no-repeat;
  padding: 0;
  transition-property: transform, opacity;
  animation-timing-function: cubic-bezier(.5, 0, .14, 1);
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
}

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in;
}

.fancybox-can-pan .fancybox-content, .fancybox-can-swipe .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-container [data-selectable="true"] {
  cursor: text;
}

.fancybox-image, .fancybox-spaceball {
  height: 100%;
  max-height: none;
  max-width: none;
  user-select: none;
  width: 100%;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--iframe .fancybox-content, .fancybox-slide--map .fancybox-content, .fancybox-slide--pdf .fancybox-content, .fancybox-slide--video .fancybox-content {
  height: 100%;
  width: 100%;
  padding: 0;
  overflow: visible;
}

.fancybox-slide--video .fancybox-content {
  background: #000;
}

.fancybox-slide--map .fancybox-content {
  background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
}

.fancybox-iframe, .fancybox-video {
  height: 100%;
  width: 100%;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  overflow: hidden;
}

.fancybox-iframe {
  position: absolute;
  top: 0;
  left: 0;
}

.fancybox-error {
  cursor: default;
  max-width: 400px;
  width: 100%;
  background: #fff;
  padding: 40px;
}

.fancybox-error p {
  color: #444;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 20px;
}

.fancybox-button {
  box-shadow: none;
  cursor: pointer;
  height: 44px;
  vertical-align: top;
  visibility: inherit;
  width: 44px;
  background: #1e1e1e99;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 10px;
  transition: color .2s;
  display: inline-block;
  position: relative;
}

.fancybox-button, .fancybox-button:link, .fancybox-button:visited {
  color: #ccc;
}

.fancybox-button:hover {
  color: #fff;
}

.fancybox-button:focus {
  outline: none;
}

.fancybox-button.fancybox-focus {
  outline: 1px dotted;
}

.fancybox-button[disabled], .fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none;
}

.fancybox-button div {
  height: 100%;
}

.fancybox-button svg {
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
  overflow: visible;
}

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0;
}

.fancybox-button--fsenter svg:nth-child(2), .fancybox-button--fsexit svg:nth-child(1), .fancybox-button--pause svg:nth-child(1), .fancybox-button--play svg:nth-child(2) {
  display: none;
}

.fancybox-progress {
  height: 2px;
  transform-origin: 0;
  z-index: 99998;
  background: #ff5268;
  transition-property: transform;
  transition-timing-function: linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: scaleX(0);
}

.fancybox-close-small {
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  z-index: 401;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 8px;
  position: absolute;
  top: -44px;
  right: -12px;
}

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1;
}

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  top: 0;
  right: 0;
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden;
}

.fancybox-is-scaling .fancybox-close-small, .fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none;
}

.fancybox-navigation .fancybox-button {
  height: 100px;
  opacity: 0;
  width: 70px;
  background-clip: content-box;
  position: absolute;
  top: calc(50% - 50px);
}

.fancybox-navigation .fancybox-button div {
  padding: 7px;
}

.fancybox-navigation .fancybox-button--arrow_left {
  padding: 31px 26px 31px 6px;
  left: 0;
}

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
}

.fancybox-caption {
  color: #eee;
  text-align: center;
  z-index: 99996;
  padding: 25px 44px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  bottom: 0;
  left: 0;
  right: 0;
}

.fancybox-caption:before {
  content: "";
  pointer-events: none;
  z-index: -1;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAD6CAQAAADKSeXYAAAAYklEQVQoz42RwQ3AMAgDjfcfup8WoRykfBAK5mQHKSz5rbXJPis1hjiV3CIqgG0hLZPkVkA4p4x5oR1bVeDrdCLrW2Q0D5bcwY3TGMHbdw3mPRuOtaspYP1w//G1OIcW148H0DMCqI/3mMMAAAAASUVORK5CYII=");
  background-repeat: repeat-x;
  background-size: contain;
  display: block;
  position: absolute;
  inset: -44px 0 0;
}

.fancybox-caption a, .fancybox-caption a:link, .fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none;
}

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline;
}

.fancybox-loading {
  height: 50px;
  opacity: .7;
  width: 50px;
  z-index: 99999;
  background: none;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  margin: -25px 0 0 -25px;
  padding: 0;
  animation: a 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes a {
  to {
    transform: rotate(1turn);
  }
}

.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, .25, 1);
}

.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translateZ(0);
}

.fancybox-fx-fade.fancybox-slide--next, .fancybox-fx-fade.fancybox-slide--previous {
  opacity: 0;
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(.5, .5, .5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scaleX(1);
}

.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  transform: rotate(-1turn);
}

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  transform: rotate(1turn);
}

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  transform: rotate(0deg);
}

.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0)translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0)translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scaleX(1)translateZ(0);
}

.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0)scale(.1)skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0)scale(.1)skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  transform: translateZ(0)scale(1);
}

@media (max-height: 576px) {
  .fancybox-caption {
    padding: 12px;
  }

  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px;
  }

  .fancybox-slide--image {
    padding: 6px 0;
  }

  .fancybox-close-small {
    right: -6px;
  }

  .fancybox-slide--image .fancybox-close-small {
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    width: 36px;
    background: #4e4e4e;
    padding: 6px;
    top: 0;
    right: 0;
  }
}

.fancybox-share {
  max-width: 90%;
  text-align: center;
  background: #f4f4f4;
  border-radius: 3px;
  padding: 30px;
}

.fancybox-share h1 {
  color: #222;
  margin: 0 0 20px;
  font-size: 35px;
  font-weight: 700;
}

.fancybox-share p {
  margin: 0;
  padding: 0;
}

.fancybox-share__button {
  min-width: 130px;
  user-select: none;
  white-space: nowrap;
  border: 0;
  border-radius: 3px;
  margin: 0 5px 10px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  text-decoration: none;
  transition: all .2s;
  display: inline-block;
}

.fancybox-share__button:link, .fancybox-share__button:visited {
  color: #fff;
}

.fancybox-share__button:hover {
  text-decoration: none;
}

.fancybox-share__button--fb {
  background: #3b5998;
}

.fancybox-share__button--fb:hover {
  background: #344e86;
}

.fancybox-share__button--pt {
  background: #bd081d;
}

.fancybox-share__button--pt:hover {
  background: #aa0719;
}

.fancybox-share__button--tw {
  background: #1da1f2;
}

.fancybox-share__button--tw:hover {
  background: #0d95e8;
}

.fancybox-share__button svg {
  height: 25px;
  vertical-align: middle;
  width: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
}

.fancybox-share__button svg path {
  fill: #fff;
}

.fancybox-share__input {
  color: #5d5b5b;
  width: 100%;
  background: none;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  outline: none;
  margin: 10px 0 0;
  padding: 10px 15px;
  font-size: 14px;
}

.fancybox-thumbs {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-tap-highlight-color: transparent;
  width: 212px;
  z-index: 99995;
  background: #ddd;
  margin: 0;
  padding: 2px 2px 4px;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}

.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}

.fancybox-thumbs__list {
  height: 100%;
  white-space: nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;
  list-style: none;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px #0000004d;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}

.fancybox-thumbs__list a {
  backface-visibility: hidden;
  cursor: pointer;
  float: left;
  height: 75px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  -webkit-tap-highlight-color: transparent;
  width: 100px;
  background-color: #0000001a;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  outline: none;
  margin: 2px;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.fancybox-thumbs__list a:before {
  content: "";
  opacity: 0;
  z-index: 99991;
  border: 6px solid #ff5268;
  transition: all .2s cubic-bezier(.25, .46, .45, .94);
  position: absolute;
  inset: 0;
}

.fancybox-thumbs__list a:focus:before {
  opacity: .5;
}

.fancybox-thumbs__list a.fancybox-thumbs-active:before {
  opacity: 1;
}

@media (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px;
  }

  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }

  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px);
  }
}

.flatpickr-calendar {
  opacity: 0;
  text-align: center;
  visibility: hidden;
  direction: ltr;
  width: 307.875px;
  box-sizing: border-box;
  touch-action: manipulation;
  background: #fff;
  border: 0;
  border-radius: 5px;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  animation: none;
  display: none;
  position: absolute;
  box-shadow: 1px 0 #e6e6e6, -1px 0 #e6e6e6, 0 1px #e6e6e6, 0 -1px #e6e6e6, 0 3px 13px #00000014;
}

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  z-index: 99999;
  display: inline-block;
}

.flatpickr-calendar.animate.open {
  animation: fpFadeInDown .3s cubic-bezier(.23, 1, .32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
  pointer-events: none;
  content: "";
  height: 0;
  width: 0;
  border: solid #0000;
  display: block;
  position: absolute;
  left: 22px;
}

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.arrowRight:before, .flatpickr-calendar.rightMost:after, .flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before, .flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  color: #000000e6;
  fill: #000000e6;
  height: 34px;
  text-align: center;
  user-select: none;
  background: none;
  flex: 1;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  cursor: pointer;
  height: 34px;
  z-index: 3;
  color: #000000e6;
  fill: #000000e6;
  padding: 10px;
  text-decoration: none;
  position: absolute;
  top: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  fill: inherit;
  transition: fill .1s;
}

.numInputWrapper {
  height: auto;
  position: relative;
}

.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numInputWrapper span {
  width: 14px;
  height: 50%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #39393926;
  padding: 0 4px 0 2px;
  line-height: 50%;
  position: absolute;
  right: 0;
}

.numInputWrapper span:hover {
  background: #0000001a;
}

.numInputWrapper span:active {
  background: #0003;
}

.numInputWrapper span:after {
  content: "";
  display: block;
  position: absolute;
}

.numInputWrapper span.arrowUp {
  border-bottom: 0;
  top: 0;
}

.numInputWrapper span.arrowUp:after {
  border-bottom: 4px solid #39393999;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-top: 4px solid #39393999;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: #00000080;
}

.numInputWrapper:hover {
  background: #0000000d;
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  color: inherit;
  width: 75%;
  height: 34px;
  text-align: center;
  padding: 7.48px 0 0;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
  position: absolute;
  left: 12.5%;
  transform: translate3d(0, 0, 0);
}

.flatpickr-current-month span.cur-month {
  color: inherit;
  margin-left: .5ch;
  padding: 0;
  font-family: inherit;
  font-weight: 700;
  display: inline-block;
}

.flatpickr-current-month span.cur-month:hover {
  background: #0000000d;
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch�;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #000000e6;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #000000e6;
}

.flatpickr-current-month input.cur-year {
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  vertical-align: initial;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0 0 0 .5ch;
  display: inline-block;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  color: #00000080;
  pointer-events: none;
  background: none;
  font-size: 100%;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  height: auto;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  vertical-align: initial;
  width: auto;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  margin: -1px 0 0;
  padding: 0 0 0 .5ch;
  position: relative;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: #0000000d;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #0000;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  text-align: center;
  width: 100%;
  height: 28px;
  background: none;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  flex: 1;
  display: flex;
}

span.flatpickr-weekday {
  cursor: default;
  color: #0000008a;
  text-align: center;
  background: none;
  flex: 1;
  margin: 0;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  display: block;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0;
}

.flatpickr-days {
  width: 307.875px;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  box-sizing: border-box;
  opacity: 1;
  outline: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0;
  display: flex;
  transform: translate3d(0, 0, 0);
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 #e6e6e6;
}

.flatpickr-day {
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  width: 14.2857%;
  max-width: 39px;
  height: 39px;
  text-align: center;
  background: none;
  border: 1px solid #0000;
  border-radius: 150px;
  flex-basis: 14.2857%;
  justify-content: center;
  margin: 0;
  font-weight: 400;
  line-height: 39px;
  display: inline-block;
  position: relative;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  background: #e6e6e6;
  border-color: #e6e6e6;
  outline: 0;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  color: #fff;
  background: #959ea9;
  border-color: #959ea9;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  box-shadow: none;
  color: #fff;
  background: #569ff7;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: #3939394d;
  cursor: default;
  background: none;
  border-color: #0000;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: #3939391a;
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 #569ff7, 5px 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  width: 100%;
  max-width: none;
  color: #3939394d;
  cursor: default;
  background: none;
  border: none;
  display: block;
}

.flatpickr-innerContainer {
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
}

.flatpickr-rContainer {
  box-sizing: border-box;
  padding: 0;
  display: inline-block;
}

.flatpickr-time {
  text-align: center;
  height: 0;
  max-height: 40px;
  box-sizing: border-box;
  outline: 0;
  line-height: 40px;
  display: flex;
  overflow: hidden;
}

.flatpickr-time:after {
  content: "";
  clear: both;
  display: table;
}

.flatpickr-time .numInputWrapper {
  width: 40%;
  height: 40px;
  float: left;
  flex: 1;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  box-shadow: none;
  text-align: center;
  height: inherit;
  line-height: inherit;
  color: #393939;
  box-sizing: border-box;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  position: relative;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  border: 0;
  outline: 0;
}

.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  width: 2%;
  user-select: none;
  align-self: center;
  font-weight: bold;
}

.flatpickr-time .flatpickr-am-pm {
  width: 18%;
  cursor: pointer;
  text-align: center;
  outline: 0;
  font-weight: 400;
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.flatpickr-months {
  margin: .75rem 0;
}

.flatpickr-months .flatpickr-month, .flatpickr-months .flatpickr-next-month, .flatpickr-months .flatpickr-prev-month {
  height: auto;
  position: relative;
}

.flatpickr-months .flatpickr-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg, .flatpickr-months .flatpickr-prev-month:hover svg {
  fill: #3755be;
}

.flatpickr-months .flatpickr-month {
  color: #495057;
}

.flatpickr-current-month {
  padding: 13px 0 0;
  font-size: 115%;
}

.flatpickr-current-month span.cur-month {
  font-weight: 600;
}

.flatpickr-current-month span.cur-month:hover, .numInputWrapper:hover {
  background: #3755be26;
}

.flatpickr-day {
  color: #495057;
  border-radius: .3125rem;
  font-weight: 500;
}

.flatpickr-day.today {
  border-color: #3755be;
}

.flatpickr-day.today:hover {
  background: #3755be;
  border-color: #3755be;
}

.flatpickr-day:hover {
  background: #3755be1a;
  border-color: #0000;
}

span.flatpickr-weekday {
  color: #495057;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #3755be;
  border-color: #3755be;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 #3755be;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: .3125rem 0 0 .3125rem;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 .3125rem .3125rem 0;
}

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.flickity-slider {
  width: 100%;
  height: 100%;
  position: absolute;
}

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.flickity-button {
  color: #333;
  background: #ffffffbf;
  border: none;
  position: absolute;
}

.flickity-button:hover {
  cursor: pointer;
  background: #fff;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: .6;
}

.flickity-button:disabled {
  opacity: .3;
  cursor: auto;
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

.flickity-prev-next-button {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  left: 10px;
  right: auto;
}

.flickity-prev-next-button .flickity-button-icon {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 20%;
  left: 20%;
}

.flickity-page-dots {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 1;
  list-style: none;
  position: absolute;
  bottom: -25px;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  width: 10px;
  height: 10px;
  opacity: .25;
  cursor: pointer;
  background: #333;
  border-radius: 50%;
  margin: 0 8px;
  display: inline-block;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

[data-flickity]:not(.flickity-enabled) {
  opacity: 0;
}

.flickity-page-dots {
  bottom: -2.125rem;
}

.flickity-page-dots .dot {
  width: 6px;
  height: 6px;
  opacity: .2;
  background: #495057;
  border-radius: 10rem;
  margin: 0 5px;
  transition: transform .2s linear, opacity .2s linear;
}

.flickity-page-dots .dot.is-selected {
  transform: scale(1.33333);
}

.flickity-prev-next-button {
  width: 3rem;
  height: 3rem;
  background: #fff;
  border: 1px solid #dee2e6;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .2s linear, box-shadow .2s linear;
  display: flex;
}

.flickity-prev-next-button:after {
  content: "";
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.8517 18.1136C14.9506 18.2121 15 18.3402 15 18.4977C15 18.6356 14.9506 18.7538 14.8517 18.8523C14.7331 18.9508 14.6045 19 14.4661 19C14.3277 19 14.209 18.9508 14.1102 18.8523L8.14831 12.8841C8.04944 12.7659 8 12.6379 8 12.5C8 12.3621 8.04944 12.2341 8.14831 12.1159L14.1102 6.14773C14.209 6.04924 14.3277 6 14.4661 6C14.6045 6 14.7331 6.04924 14.8517 6.14773C14.9506 6.24621 15 6.37424 15 6.53182C15 6.6697 14.9506 6.78788 14.8517 6.88636L9.42373 12.5L14.8517 18.1136Z' fill='%233755BE'/></svg>");
  background-size: cover;
  display: block;
}

.flickity-prev-next-button.previous {
  left: -4.5rem;
}

.flickity-prev-next-button.next {
  right: -4.5rem;
}

.flickity-prev-next-button.next:after {
  transform: rotateZ(180deg);
}

.flickity-prev-next-button:hover {
  box-shadow: 0 .125rem .625rem #0000000d;
}

.flickity-button-icon {
  display: none;
}

@media (max-width: 1199.98px) {
  .flickity-prev-next-button {
    display: none;
  }
}

[data-flickity].controls-light .flickity-page-dots .dot {
  background: #fff;
}

.controls-inside:not(:hover) .flickity-prev-next-button {
  opacity: 0;
}

.controls-inside .flickity-page-dots {
  position: absolute;
  bottom: 1.5rem;
}

.controls-inside .flickity-prev-next-button.previous, .arrows-inside .flickity-prev-next-button.previous {
  left: 1.5rem;
}

.controls-inside .flickity-prev-next-button.next, .arrows-inside .flickity-prev-next-button.next {
  right: 1.5rem;
}

.carousel-cell {
  width: 100%;
}

.highlight-selected .carousel-cell {
  transition: opacity .2s, transform .2s;
}

.highlight-selected .carousel-cell:not(.is-selected) {
  opacity: .25;
  transform: scale(.9);
}

.slider-phone > img {
  pointer-events: none;
  display: none;
}

@media (min-width: 768px) {
  .slider-phone {
    position: relative;
  }

  .slider-phone > img {
    z-index: 1;
    display: block;
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  .slider-phone .carousel-cell img {
    transition: opacity .2s, transform .2s;
    transform: scale(.86)translate3d(0, -4.55%, 0);
  }

  .slider-phone .carousel-cell:not(.is-selected) img {
    opacity: .1;
    transform: scale(.8);
  }

  .slider-phone .flickity-viewport {
    overflow: visible;
  }
}

@media (min-width: 992px) {
  .slider-phone .carousel-cell img {
    border-radius: 1.5rem;
  }
}

.form-control {
  transition: background-color .2s, border-color .2s, opacity .2s;
}

.form-control:disabled {
  opacity: .5;
}

.form-control[readonly] {
  background: #fff;
}

.form-group label {
  color: #adb5bd;
  font-size: .875rem;
  font-weight: 600;
}

.form-group label.custom-control-label {
  color: #495057;
}

.form-group label.custom-control-label:before, .form-group label.custom-control-label:after {
  top: .125rem;
}

.form-group.rounded {
  border-radius: .3125rem !important;
}

.text-light .form-group > label {
  color: #ffffffbf;
}

@media (max-width: 767.98px) {
  .form-control {
    font-size: 16px;
  }
}

.input-group .icon * {
  fill: #212529 !important;
}

.input-group .input-group-text {
  font-weight: 600;
}

.form-group {
  position: relative;
}

.form-group .custom-select {
  cursor: pointer;
  background: none;
}

.form-group .custom-select + .icon {
  pointer-events: none;
  position: absolute;
  top: .5rem;
  right: .75rem;
  transform: rotateZ(-90deg);
}

.form-group .custom-select + .icon path {
  fill: #212529;
}

.form-group .custom-select.custom-select-lg + .icon {
  top: 1.2rem;
}

.custom-control label {
  cursor: pointer;
}

.custom-radio {
  min-height: 1.3125rem;
  line-height: 1.3125rem;
}

.custom-control-label {
  user-select: none;
}

.custom-control-label:before {
  border: 1px solid #dee2e6;
}

.custom-control-label.text-small:before, .custom-control-label.text-small:after {
  top: .125rem;
}

.custom-control-input:checked ~ .custom-control-label:before {
  border-color: #3755be;
}

.custom-control {
  padding-left: 1.875rem;
}

.custom-control .custom-control-label:before, .custom-control .custom-control-label:after {
  left: -1.875rem;
}

.custom-switch {
  padding-left: 3.75rem;
}

.custom-switch .custom-control-label:before {
  height: 1.5rem;
  top: 0;
  left: -3.75rem;
}

.custom-switch .custom-control-label:after {
  background-color: #fff;
  border: 1px solid #dee2e6;
  top: .25rem;
  left: -3.375rem;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  border-color: #fff;
  transform: translateX(1.25rem);
}

.custom-range-labels {
  justify-content: space-between;
  margin-top: .25rem;
  display: flex;
}

.custom-range-labels > span {
  min-width: 13px;
  text-align: center;
  color: #adb5bd;
  font-size: .75rem;
  font-weight: 600;
  display: block;
}

.custom-range-labels > span:before {
  content: "";
  height: .125rem;
  width: 2px;
  height: .4rem;
  opacity: .5;
  background: #adb5bd;
  margin: 0 auto .5rem;
  display: block;
}

.custom-range-labels > span:first-child:before, .custom-range-labels > span:last-child:before {
  height: .6rem;
}

[data-recaptcha]:not([data-size="invisible"]) {
  margin-bottom: .75rem;
}

.grecaptcha-badge {
  display: none;
}

.header-inner {
  padding: 0;
  display: block;
}

.header-inner .container {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media (min-width: 768px) {
  .header-desktop-app .row:last-of-type {
    z-index: 2;
    margin-bottom: -22.5rem;
    position: relative;
  }

  .header-desktop-app + section {
    padding-top: 22.5rem;
  }
}

[class*="hover-"] {
  transition: background-color .1s, color .1s, box-shadow .1s, transform .1s;
}

.hover-bg-primary:hover {
  color: #fff;
  background: #3755be;
  border-color: #3755be;
}

.hover-bg-primary:hover h1, .hover-bg-primary:hover h2, .hover-bg-primary:hover h3, .hover-bg-primary:hover h5, .hover-bg-primary:hover h6, .hover-bg-primary:hover h5, .hover-bg-primary:hover .h1, .hover-bg-primary:hover .h2, .hover-bg-primary:hover .h3, .hover-bg-primary:hover .h4, .hover-bg-primary:hover .h5, .hover-bg-primary:hover .h6 {
  color: #fff;
}

.hover-bg-primary:hover .icon-round {
  background: #ffffff1a !important;
}

.hover-bg-primary:hover .icon * {
  fill: #fff;
}

.hover-bg-primary-2:hover {
  color: #212529;
  background: #ff8e88;
  border-color: #ff8e88;
}

.hover-bg-primary-2:hover h1, .hover-bg-primary-2:hover h2, .hover-bg-primary-2:hover h3, .hover-bg-primary-2:hover h5, .hover-bg-primary-2:hover h6, .hover-bg-primary-2:hover h5, .hover-bg-primary-2:hover .h1, .hover-bg-primary-2:hover .h2, .hover-bg-primary-2:hover .h3, .hover-bg-primary-2:hover .h4, .hover-bg-primary-2:hover .h5, .hover-bg-primary-2:hover .h6 {
  color: #212529;
}

.hover-bg-primary-2:hover .icon-round {
  background: #2125291a !important;
}

.hover-bg-primary-2:hover .icon * {
  fill: #212529;
}

.hover-bg-primary-3:hover {
  color: #fff;
  background: #1b1f3b;
  border-color: #1b1f3b;
}

.hover-bg-primary-3:hover h1, .hover-bg-primary-3:hover h2, .hover-bg-primary-3:hover h3, .hover-bg-primary-3:hover h5, .hover-bg-primary-3:hover h6, .hover-bg-primary-3:hover h5, .hover-bg-primary-3:hover .h1, .hover-bg-primary-3:hover .h2, .hover-bg-primary-3:hover .h3, .hover-bg-primary-3:hover .h4, .hover-bg-primary-3:hover .h5, .hover-bg-primary-3:hover .h6 {
  color: #fff;
}

.hover-bg-primary-3:hover .icon-round {
  background: #ffffff1a !important;
}

.hover-bg-primary-3:hover .icon * {
  fill: #fff;
}

.hover-shadow-3d {
  box-shadow: 0 0 #0000;
}

.hover-shadow-3d:hover {
  transform: translate3d(-4px, -4px, 0)rotateZ(-1deg);
  box-shadow: .5rem .5rem #e9ecef80;
}

.hover-shadow-sm {
  box-shadow: 0 0 #0000;
}

.hover-shadow-sm:hover {
  box-shadow: 0 .125rem .625rem #0000000d;
}

.hover-arrow:after {
  content: "->";
  margin-left: .375rem;
  transition: transform .2s;
  display: inline-block;
}

.hover-arrow:hover:after {
  transform: translate3d(6px, 0, 0);
}

.icon {
  width: auto;
  height: 1.5rem;
  flex-shrink: 0;
}

.icon.icon-md {
  height: 2.25rem;
}

.icon.icon-lg {
  height: 3rem;
}

.icon.icon-sm {
  height: .75rem;
}

.icon-round {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon-round > .icon {
  height: 2.25rem;
}

.icon-round.bg-primary {
  background: #3755be1a !important;
}

.icon-round.bg-primary-2 {
  background: #ff8e881a !important;
}

.icon-round.bg-primary-3 {
  background: #1b1f3b1a !important;
}

.icon-round.bg-white {
  background: #ffffff1a !important;
}

.icon-round.icon-round-xs {
  width: 2.25rem;
  height: 2.25rem;
}

.icon-round.icon-round-xs > .icon {
  height: 1.5rem;
}

.icon-round.icon-round-sm {
  width: 3rem;
  height: 3rem;
}

.icon-round.icon-round-sm > .icon {
  height: 1.5rem;
}

.icon-round.icon-round-xs {
  width: 1.5rem;
  height: 1.5rem;
}

.icon-round.icon-round-xs > .icon {
  height: 1rem;
}

@media (min-width: 992px) {
  .icon-round.icon-round-lg {
    width: 6rem;
    height: 6rem;
  }

  .icon-round.icon-round-lg > .icon {
    height: 3rem;
  }
}

.image-collage {
  justify-content: center;
  display: flex;
  position: relative;
}

.image-collage > a {
  z-index: 1;
  transition: transform .1s linear;
  position: relative;
}

.image-collage > a:nth-child(1), .image-collage > a:nth-child(3) {
  z-index: 2;
  position: absolute;
}

.image-collage > a:nth-child(1) img, .image-collage > a:nth-child(3) img {
  max-width: 345px;
}

.image-collage > a:nth-child(1) {
  top: -3rem;
  right: 0;
  transform: rotateZ(10deg);
}

.image-collage > a:nth-child(1):hover {
  transform: rotateZ(12deg)translate3d(0, -10px, 0);
}

.image-collage > a:nth-child(3) {
  bottom: -3rem;
  left: 0;
  transform: rotateZ(-10deg);
}

.image-collage > a:nth-child(3):hover {
  transform: rotateZ(-12deg)translate3d(0, -10px, 0);
}

.image-collage > a:nth-child(2) img {
  max-width: 384px;
}

.image-collage > a:nth-child(2):hover {
  transform: rotateZ(-2deg)translate3d(0, -10px, 0);
}

@media (max-width: 991.98px) {
  .image-collage > a:nth-child(2) img {
    max-width: 100%;
  }
}

.irs {
  -webkit-touch-callout: none;
  user-select: none;
  font-family: Arial, sans-serif;
  font-size: 12px;
  display: block;
  position: relative;
}

.irs-line {
  display: block;
  position: relative;
  overflow: hidden;
  outline: none !important;
}

.irs-bar {
  width: 0;
  display: block;
  position: absolute;
  left: 0;
}

.irs-shadow {
  width: 0;
  display: none;
  position: absolute;
  left: 0;
}

.irs-handle {
  box-sizing: border-box;
  cursor: default;
  z-index: 1;
  display: block;
  position: absolute;
}

.irs-handle.type_last {
  z-index: 2;
}

.irs-min, .irs-max {
  cursor: default;
  display: block;
  position: absolute;
}

.irs-min {
  left: 0;
}

.irs-max {
  right: 0;
}

.irs-from, .irs-to, .irs-single {
  cursor: default;
  white-space: nowrap;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.irs-grid {
  width: 100%;
  height: 20px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  width: 1px;
  height: 8px;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
}

.irs-grid-pol.small {
  height: 4px;
}

.irs-grid-text {
  white-space: nowrap;
  text-align: center;
  color: #000;
  padding: 0 3px;
  font-size: 9px;
  line-height: 9px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.irs-disable-mask {
  width: 102%;
  height: 100%;
  cursor: default;
  z-index: 2;
  background: none;
  display: block;
  position: absolute;
  top: 0;
  left: -1%;
}

.lt-ie9 .irs-disable-mask {
  filter: alpha(opacity= 0);
  cursor: not-allowed;
  background: #000;
}

.irs-disabled {
  opacity: .4;
}

.irs-hidden-input {
  overflow: hidden;
  width: 0 !important;
  height: 0 !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: #0000 !important;
  outline: none !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  display: block !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.irs--flat {
  height: 40px;
}

.irs--flat.irs-with-grid {
  height: 60px;
}

.irs--flat .irs-line {
  height: 12px;
  background-color: #e1e4e9;
  border-radius: 4px;
  top: 25px;
}

.irs--flat .irs-bar {
  height: 12px;
  background-color: #ed5565;
  top: 25px;
}

.irs--flat .irs-bar--single {
  border-radius: 4px 0 0 4px;
}

.irs--flat .irs-shadow {
  height: 1px;
  background-color: #e1e4e9;
  bottom: 16px;
}

.irs--flat .irs-handle {
  width: 16px;
  height: 18px;
  background-color: #0000;
  top: 22px;
}

.irs--flat .irs-handle > i:first-child {
  width: 2px;
  height: 100%;
  background-color: #da4453;
  margin-left: -1px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.irs--flat .irs-handle.state_hover > i:first-child, .irs--flat .irs-handle:hover > i:first-child {
  background-color: #a43540;
}

.irs--flat .irs-min, .irs--flat .irs-max {
  color: #999;
  text-shadow: none;
  background-color: #e1e4e9;
  border-radius: 4px;
  padding: 1px 3px;
  font-size: 10px;
  line-height: 1.333;
  top: 0;
}

.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
  color: #fff;
  text-shadow: none;
  background-color: #ed5565;
  border-radius: 4px;
  padding: 1px 5px;
  font-size: 10px;
  line-height: 1.333;
}

.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
  content: "";
  width: 0;
  height: 0;
  border: 3px solid #0000;
  border-top-color: #ed5565;
  margin-left: -3px;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  overflow: hidden;
}

.irs--flat .irs-grid-pol {
  background-color: #e1e4e9;
}

.irs--flat .irs-grid-text {
  color: #999;
}

.irs--big {
  height: 55px;
}

.irs--big.irs-with-grid {
  height: 70px;
}

.irs--big .irs-line {
  height: 12px;
  background: linear-gradient(#ddd -50%, #fff 150%);
  border: 1px solid #ccc;
  border-radius: 12px;
  top: 33px;
}

.irs--big .irs-bar {
  height: 12px;
  background: linear-gradient(#fff 0%, #428bca 30%, #b9d4ec 100%);
  border: 1px solid #428bca;
  top: 33px;
  box-shadow: inset 0 0 1px 1px #ffffff80;
}

.irs--big .irs-bar--single {
  border-radius: 12px 0 0 12px;
}

.irs--big .irs-shadow {
  height: 1px;
  background-color: #428bca80;
  bottom: 16px;
}

.irs--big .irs-handle {
  width: 30px;
  height: 30px;
  background: linear-gradient(#fff 0%, #b4b9be 30%, #fff 100%);
  border: 1px solid #0000004d;
  border-radius: 30px;
  top: 25px;
  box-shadow: 1px 1px 2px #0003, inset 0 0 3px 1px #fff;
}

.irs--big .irs-handle.state_hover, .irs--big .irs-handle:hover {
  background: linear-gradient(#fff 0%, #919ba5 30%, #fff 100%);
  border-color: #00000073;
}

.irs--big .irs-min, .irs--big .irs-max {
  color: #fff;
  text-shadow: none;
  background-color: #9f9f9f;
  border-radius: 3px;
  padding: 1px 5px;
  top: 0;
}

.irs--big .irs-from, .irs--big .irs-to, .irs--big .irs-single {
  color: #fff;
  text-shadow: none;
  background: linear-gradient(#428bca 0%, #3071a9 100%);
  border-radius: 3px;
  padding: 1px 5px;
}

.irs--big .irs-grid-pol {
  background-color: #428bca;
}

.irs--big .irs-grid-text {
  color: #428bca;
}

.irs--modern, .irs--modern.irs-with-grid {
  height: 55px;
}

.irs--modern .irs-line {
  height: 5px;
  background: linear-gradient(#e0e4ea 0%, #d1d6e0 100%);
  border: 1px solid #a3adc1;
  border-bottom-width: 0;
  border-radius: 5px;
  top: 25px;
}

.irs--modern .irs-bar {
  height: 5px;
  background: linear-gradient(#20b426 0%, #18891d 100%);
  top: 25px;
}

.irs--modern .irs-bar--single {
  border-radius: 5px 0 0 5px;
}

.irs--modern .irs-shadow {
  height: 1px;
  background-color: #d1d6e080;
  bottom: 21px;
}

.irs--modern .irs-handle {
  width: 12px;
  height: 13px;
  border: 1px solid #a3adc1;
  border-top-width: 0;
  border-radius: 0 0 3px 3px;
  top: 37px;
  box-shadow: 1px 1px 1px #0000001a;
}

.irs--modern .irs-handle > i:nth-child(1) {
  width: 6px;
  height: 6px;
  background: #fff;
  border: 1px solid #a3adc1;
  display: block;
  position: absolute;
  top: -4px;
  left: 1px;
  transform: rotate(45deg);
}

.irs--modern .irs-handle > i:nth-child(2) {
  box-sizing: border-box;
  width: 10px;
  height: 12px;
  background: linear-gradient(#fff 0%, #e9e6e6 100%);
  border-radius: 0 0 3px 3px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.irs--modern .irs-handle > i:nth-child(3) {
  box-sizing: border-box;
  width: 4px;
  height: 5px;
  border-left: 1px solid #a3adc1;
  border-right: 1px solid #a3adc1;
  display: block;
  position: absolute;
  top: 3px;
  left: 3px;
}

.irs--modern .irs-handle.state_hover, .irs--modern .irs-handle:hover {
  background: linear-gradient(#fff 0%, #919ba5 30%, #fff 100%);
  border-color: #7685a2;
}

.irs--modern .irs-handle.state_hover > i:nth-child(1), .irs--modern .irs-handle:hover > i:nth-child(1) {
  border-color: #7685a2;
}

.irs--modern .irs-handle.state_hover > i:nth-child(3), .irs--modern .irs-handle:hover > i:nth-child(3) {
  border-color: #48536a;
}

.irs--modern .irs-min, .irs--modern .irs-max {
  text-shadow: none;
  color: #fff;
  background-color: #d1d6e0;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 10px;
  line-height: 1.333;
  top: 0;
}

.irs--modern .irs-from, .irs--modern .irs-to, .irs--modern .irs-single {
  text-shadow: none;
  color: #fff;
  background-color: #20b426;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 10px;
  line-height: 1.333;
}

.irs--modern .irs-from:before, .irs--modern .irs-to:before, .irs--modern .irs-single:before {
  content: "";
  width: 0;
  height: 0;
  border: 3px solid #0000;
  border-top-color: #20b426;
  margin-left: -3px;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  overflow: hidden;
}

.irs--modern .irs-grid {
  height: 25px;
}

.irs--modern .irs-grid-pol {
  background-color: #dedede;
}

.irs--modern .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--sharp {
  height: 50px;
  font-size: 12px;
  line-height: 1;
}

.irs--sharp.irs-with-grid {
  height: 57px;
}

.irs--sharp .irs-line {
  height: 2px;
  background-color: #000;
  border-radius: 2px;
  top: 30px;
}

.irs--sharp .irs-bar {
  height: 2px;
  background-color: #ee22fa;
  top: 30px;
}

.irs--sharp .irs-bar--single {
  border-radius: 2px 0 0 2px;
}

.irs--sharp .irs-shadow {
  height: 1px;
  background-color: #00000080;
  bottom: 21px;
}

.irs--sharp .irs-handle {
  width: 10px;
  height: 10px;
  background-color: #a804b2;
  top: 25px;
}

.irs--sharp .irs-handle > i:first-child {
  width: 0;
  height: 0;
  border: 5px solid #0000;
  border-top-color: #a804b2;
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
}

.irs--sharp .irs-handle.state_hover, .irs--sharp .irs-handle:hover {
  background-color: #000;
}

.irs--sharp .irs-handle.state_hover > i:first-child, .irs--sharp .irs-handle:hover > i:first-child {
  border-top-color: #000;
}

.irs--sharp .irs-min, .irs--sharp .irs-max {
  color: #fff;
  opacity: .4;
  background-color: #a804b2;
  border-radius: 2px;
  padding: 3px 4px;
  font-size: 14px;
  line-height: 1;
  top: 0;
}

.irs--sharp .irs-from, .irs--sharp .irs-to, .irs--sharp .irs-single {
  text-shadow: none;
  color: #fff;
  background-color: #a804b2;
  border-radius: 2px;
  padding: 3px 4px;
  font-size: 14px;
  line-height: 1;
}

.irs--sharp .irs-from:before, .irs--sharp .irs-to:before, .irs--sharp .irs-single:before {
  content: "";
  width: 0;
  height: 0;
  border: 3px solid #0000;
  border-top-color: #a804b2;
  margin-left: -3px;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  overflow: hidden;
}

.irs--sharp .irs-grid {
  height: 25px;
}

.irs--sharp .irs-grid-pol {
  background-color: #dedede;
}

.irs--sharp .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--round {
  height: 50px;
}

.irs--round.irs-with-grid {
  height: 65px;
}

.irs--round .irs-line {
  height: 4px;
  background-color: #dee4ec;
  border-radius: 4px;
  top: 36px;
}

.irs--round .irs-bar {
  height: 4px;
  background-color: #006cfa;
  top: 36px;
}

.irs--round .irs-bar--single {
  border-radius: 4px 0 0 4px;
}

.irs--round .irs-shadow {
  height: 4px;
  background-color: #dee4ec80;
  bottom: 21px;
}

.irs--round .irs-handle {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 4px solid #006cfa;
  border-radius: 24px;
  top: 26px;
  box-shadow: 0 1px 3px #0000ff4d;
}

.irs--round .irs-handle.state_hover, .irs--round .irs-handle:hover {
  background-color: #f0f6ff;
}

.irs--round .irs-min, .irs--round .irs-max {
  color: #333;
  background-color: #0000001a;
  border-radius: 4px;
  padding: 3px 5px;
  font-size: 14px;
  line-height: 1;
  top: 0;
}

.irs--round .irs-from, .irs--round .irs-to, .irs--round .irs-single {
  text-shadow: none;
  color: #fff;
  background-color: #006cfa;
  border-radius: 4px;
  padding: 3px 5px;
  font-size: 14px;
  line-height: 1;
}

.irs--round .irs-from:before, .irs--round .irs-to:before, .irs--round .irs-single:before {
  content: "";
  width: 0;
  height: 0;
  border: 3px solid #0000;
  border-top-color: #006cfa;
  margin-left: -3px;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  overflow: hidden;
}

.irs--round .irs-grid {
  height: 25px;
}

.irs--round .irs-grid-pol {
  background-color: #dedede;
}

.irs--round .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--square {
  height: 50px;
}

.irs--square.irs-with-grid {
  height: 60px;
}

.irs--square .irs-line {
  height: 4px;
  background-color: #dedede;
  top: 31px;
}

.irs--square .irs-bar {
  height: 4px;
  background-color: #000;
  top: 31px;
}

.irs--square .irs-shadow {
  height: 2px;
  background-color: #dedede;
  bottom: 21px;
}

.irs--square .irs-handle {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 3px solid #000;
  top: 25px;
  transform: rotate(45deg);
}

.irs--square .irs-handle.state_hover, .irs--square .irs-handle:hover {
  background-color: #f0f6ff;
}

.irs--square .irs-min, .irs--square .irs-max {
  color: #333;
  background-color: #0000001a;
  padding: 3px 5px;
  font-size: 14px;
  line-height: 1;
  top: 0;
}

.irs--square .irs-from, .irs--square .irs-to, .irs--square .irs-single {
  text-shadow: none;
  color: #fff;
  background-color: #000;
  padding: 3px 5px;
  font-size: 14px;
  line-height: 1;
}

.irs--square .irs-grid {
  height: 25px;
}

.irs--square .irs-grid-pol {
  background-color: #dedede;
}

.irs--square .irs-grid-text {
  color: silver;
  font-size: 11px;
}

.irs--theme {
  height: 40px;
}

.irs--theme.irs-with-grid {
  height: 60px;
}

.irs--theme .irs-line {
  height: 12px;
  top: 25px;
}

.irs--theme .irs-line-left {
  height: 12px;
  background-position: 0 -30px;
}

.irs--theme .irs-line-mid {
  height: 12px;
  background-position: 0 0;
}

.irs--theme .irs-line-right {
  height: 12px;
  background-position: 100% -30px;
}

.irs--theme .irs-bar {
  height: 6px;
  background-color: #3755be;
  border-radius: 200px;
  top: 27px;
}

.irs--theme .irs-bar-edge {
  height: 12px;
  width: 9px;
  background-position: 0 -90px;
  top: 25px;
}

.irs--theme .irs-shadow {
  height: 3px;
  opacity: .25;
  background: #000;
  top: 34px;
}

.irs--theme .lt-ie9 .irs-shadow {
  filter: alpha(opacity= 25);
}

.irs--theme .irs-slider {
  width: 16px;
  height: 18px;
  background-position: 0 -120px;
  top: 22px;
}

.irs--theme .irs-slider.state_hover, .irs--theme .irs-slider:hover {
  background-position: 0 -150px;
}

.irs--theme .irs-min, .irs--theme .irs-max {
  opacity: 0;
}

.irs--theme .irs-from, .irs--theme .irs-to, .irs--theme .irs-single {
  color: #fff;
  text-shadow: none;
  background: #212529;
  border-radius: 4px;
  padding: 1px 5px;
  font-size: 10px;
  line-height: 1.333;
}

.irs--theme .irs-from:after, .irs--theme .irs-to:after, .irs--theme .irs-single:after {
  content: "";
  width: 0;
  height: 0;
  border: 3px solid #0000;
  border-top-color: #212529;
  margin-left: -3px;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  overflow: hidden;
}

.irs--theme .irs-grid {
  bottom: -8px;
}

.irs--theme .irs-grid-pol {
  background: #adb5bd;
}

.irs--theme .irs-grid-text {
  color: #adb5bd;
  margin-top: .5rem;
  font-size: .75rem;
  font-weight: 600;
  bottom: -8px;
}

.irs--theme .irs-handle {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dee2e6;
  border-radius: 50%;
  transition: background-color .2s;
  top: 22px;
  box-shadow: 0 .125rem .625rem #0000000d;
}

.irs--theme .irs-handle.state_hover {
  background-color: #3755be;
}

.irs--theme .irs-handle > i:first-child {
  display: none;
}

.irs--theme .irs-handle.state_hover > i:first-child, .irs--theme .irs-handle:hover > i:first-child {
  background-color: #a43540;
}

.jarallax {
  z-index: 0;
  position: relative;
}

.jarallax > .jarallax-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  font-family: "object-fit: cover;";
  position: absolute;
  top: 0;
  left: 0;
}

.list-group-item.row {
  padding-left: 0;
  padding-right: 0;
  transition: color .2s;
  display: flex;
}

.list-group-item.row h5 {
  transition: color .2s;
}

.list-group-item.row:hover {
  color: #3755be;
  background: none;
}

.list-group-item.row:hover h5 {
  color: #3755be;
}

.list-group-item.list-group-item-action {
  transition: background-color .2s;
}

.list-group-flush:last-child .list-group-item.row:last-child {
  border-bottom: 1px solid #dee2e6;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 1px solid #dee2e6;
}

div[data-maps-api-key] div.map-marker, div[data-maps-api-key] div.map-style {
  display: none;
}

.gm-err-title, .gm-err-message {
  color: #e0e0e0;
  margin: 0;
}

.gm-err-title:after, .gm-err-message:after {
  content: "Enter a valid Google Maps API Key";
  color: #616161;
  display: block;
}

.gm-err-message:after {
  content: "Please consult the Google Maps API documentation on how to obtain an API key.";
}

.video-poster {
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.video-poster .btn {
  position: absolute;
}

code[class*="language-"], pre[class*="language-"] {
  color: #000;
  text-shadow: 0 1px #fff;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  hyphens: none;
  background: none;
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-size: 1em;
  line-height: 1.5;
}

pre[class*="language-"]::selection, pre[class*="language-"] ::selection, code[class*="language-"]::selection, code[class*="language-"] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*="language-"], pre[class*="language-"] {
    text-shadow: none;
  }
}

pre[class*="language-"] {
  margin: .5em 0;
  padding: 1em;
  overflow: auto;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: #f5f2f0;
}

:not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .3em;
  padding: .1em;
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
  color: #708090;
}

.token.punctuation {
  color: #999;
}

.token.namespace {
  opacity: .7;
}

.token.property, .token.tag, .token.boolean, .token.number, .token.constant, .token.symbol, .token.deleted {
  color: #905;
}

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
  color: #690;
}

.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string {
  color: #9a6e3a;
  background: #ffffff80;
}

.token.atrule, .token.attr-value, .token.keyword {
  color: #07a;
}

.token.function, .token.class-name {
  color: #dd4a68;
}

.token.regex, .token.important, .token.variable {
  color: #e90;
}

.token.important, .token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

pre[class*="language-"], code[class*="language-"] {
  color: #212529;
  background: #f1f3fa;
  border: none;
}

article pre[class*="language-"] {
  border-radius: .3125rem;
  margin: 1rem 0;
}

pre[class*="language-"] {
  padding: 1.5rem;
}

body {
  transition: opacity .4s;
}

body.fade-page {
  opacity: 0;
}

.popover {
  font-weight: 600;
  box-shadow: 0 1rem 3rem #0000002d;
}

.popover-header {
  border-bottom: none;
  padding-bottom: 0;
  font-size: .875rem;
}

.popover-body {
  padding-top: .5rem;
}

.popover-image {
  position: relative;
}

.popover-hotspot {
  cursor: pointer;
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 50%;
  animation: hotspotPulse 2s infinite alternate;
  position: absolute;
}

@keyframes hotspotPulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: .5;
    transform: scale(1.3);
  }
}

.pricing-table {
  border-bottom: 1px solid #dee2e6;
}

.pricing-table thead th {
  border-top: 0;
  border-bottom-width: 1px;
}

.pricing-table th, .pricing-table td {
  padding: 1.5rem;
}

.pricing-table td, .pricing-table th {
  vertical-align: middle;
}

.pricing-table th:not(:last-child), .pricing-table td:not(:last-child) {
  border-right: 1px solid #dee2e6;
}

.pricing-table tbody tr {
  transition: background-color .2s;
}

.pricing-table-competitors thead {
  text-align: center;
}

.pricing-table-competitors thead th {
  vertical-align: middle;
  border-top: 0;
}

.pricing-table-competitors tbody .icon-round {
  margin: 0 auto;
}

.pricing-table-competitors tbody th {
  max-width: 120px;
}

.pricing-ticket:before, .pricing-ticket:after {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  background: #fff;
  border-radius: 50%;
  flex-shrink: 0;
  display: block;
  position: absolute;
  left: -.75rem;
}

.pricing-ticket:after {
  left: auto;
  right: -.75rem;
}

.process-circle {
  width: 3rem;
  height: 3rem;
  color: #fff;
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 600;
  display: flex;
}

.process-circle:empty:after {
  content: "";
  width: .5rem;
  height: .5rem;
  border-radius: 50%;
}

.process-circle:empty.bg-primary {
  background: #3755be0d !important;
}

.process-circle:empty.bg-primary:after {
  background-color: #3755be;
}

.process-circle:empty.bg-primary-2 {
  background: #ff8e880d !important;
}

.process-circle:empty.bg-primary-2:after {
  background-color: #ff8e88;
}

.process-circle:empty.bg-primary-3 {
  background: #1b1f3b0d !important;
}

.process-circle:empty.bg-primary-3:after {
  background-color: #1b1f3b;
}

.process-vertical {
  width: 100%;
  flex-direction: column;
  padding: 0;
  list-style: none;
  display: flex;
}

.process-vertical li {
  align-items: center;
  display: flex;
}

.process-vertical li .process-circle {
  margin-right: 1.5rem;
}

.process-vertical li:not(:last-child) {
  margin-bottom: 1.5rem;
  position: relative;
}

@media (min-width: 768px) {
  .process-vertical li {
    width: 50%;
    margin-left: 50%;
  }

  .process-vertical li .process-circle {
    margin-left: -1.5rem;
  }

  .process-vertical li:nth-child(2n) {
    text-align: right;
    flex-direction: row-reverse;
    margin-left: 0;
    margin-right: 50%;
  }

  .process-vertical li:nth-child(2n) .process-circle {
    margin-left: 1.5rem;
    margin-right: -1.5rem;
  }

  .process-vertical li:not(:last-child) {
    margin-bottom: 0;
    padding-bottom: 4.5rem;
  }

  .process-vertical li:not(:last-child):after {
    content: "";
    width: 1px;
    height: 3rem;
    background: #dee2e6;
    display: block;
    position: absolute;
    top: 4.125rem;
  }

  .text-light .process-vertical li:not(:last-child):after {
    background: #ffffff40;
  }
}

.progress-bar {
  font-weight: 600;
}

.progress-bar:only-child {
  border-radius: 10rem;
}

.progress {
  height: auto;
  min-height: .375rem;
}

progress.reading-position {
  width: 100%;
  height: 3px;
  appearance: none;
  color: #ff8e88;
  background-color: #0000;
  border: none;
  transition: opacity .2s;
  position: absolute;
  bottom: 0;
  left: 0;
}

progress.reading-position.reading-position-hidden {
  opacity: 0;
}

progress.reading-position::-webkit-progress-bar {
  background-color: #0000;
}

progress.reading-position::-webkit-progress-value {
  background-color: #ff8e88;
}

progress.reading-position::-moz-progress-bar {
  background-color: #ff8e88;
}

.article-progress {
  width: 100%;
  z-index: 998;
  position: fixed;
}

.article-progress .article-progress-wrapper {
  background: #fff;
  border-bottom: 1px solid #dee2e6;
  transition: opacity .2s;
  box-shadow: 0 .625rem 1.5rem #00000014;
}

.article-progress .reading-position-hidden + .article-progress-wrapper, .article-progress .reading-position[value="0"] + .article-progress-wrapper {
  opacity: 0;
  pointer-events: none;
  height: 0;
}

.navbar-container:not([style]) + .article-progress {
  top: 0;
}

th {
  color: #212529;
  font-weight: 600;
}

.table-dark th {
  color: #fff;
}

.table thead th {
  border-bottom-width: 1px;
}

article table {
  border-top: 1px solid #dee2e6;
  margin-bottom: 1.5rem;
}

article table td {
  vertical-align: top;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

article table td:not(:last-child) {
  white-space: nowrap;
  padding-right: 1.5rem;
}

article table thead th {
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-right: 1.5rem;
}

article table tr {
  border-bottom: 1px solid #dee2e6;
}

.tooltip {
  font-weight: 600;
}

.user img {
  max-height: 3rem;
  border-radius: 50%;
}

.user [data-scribe="component:author"] {
  display: none;
}

.timePosted > a {
  color: #adb5bd;
}

.tweet {
  word-break: break-word;
}

.step-circles {
  border-bottom: none;
}

.step-circles .nav-link {
  width: 3rem;
  height: 3rem;
  background: #3755be1a;
  border: 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: background-color .2s;
  display: flex;
  position: relative;
  color: #3755be !important;
  opacity: 1 !important;
}

.step-circles .nav-link:hover {
  background: #3755be26;
}

.step-circles .nav-item {
  opacity: .5;
  margin: 0 1.5rem;
}

.step-circles .nav-item.active .nav-link {
  background: #3755be;
  color: #fff !important;
}

.step-circles .nav-item.active, .step-circles .nav-item.done {
  opacity: 1;
}

.step-circles .nav-item:not(:last-child) .nav-link:after {
  content: "";
  width: 3rem;
  height: 4px;
  pointer-events: none;
  background: #3755be1a;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.wizard > ul:not(.step-circles) .btn {
  border-radius: .3125rem .3125rem 0 0;
}

.wizard > ul:not(.step-circles) > li.active .btn {
  color: #fff;
  background: #3755be;
  opacity: 1 !important;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .card .card-img-overlay {
    top: 0;
  }

  .card > a {
    flex-shrink: 0;
  }

  .card.justify-content-end .card-img-overlay {
    top: auto;
    bottom: 0;
  }

  .card:not([class*="col-"]) {
    width: 100%;
  }

  .card > div {
    max-width: 100%;
  }

  .card.card-article-wide .card-body {
    width: 100%;
  }

  .flex-column > img.flex-grow-1 {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
  }

  .icon {
    flex-shrink: 0 !important;
  }

  .icon-logo {
    width: auto !important;
    max-width: 6rem !important;
  }

  svg.icon {
    width: 1.5rem;
  }

  svg.icon.icon-md {
    width: 2.25rem;
  }

  svg.icon.icon-lg {
    width: 3rem;
  }

  svg.icon.icon-sm {
    width: .75rem;
  }

  img {
    flex-shrink: 0;
  }

  img.flex-grow-1 {
    flex-grow: 0 !important;
  }

  .list-group, .row {
    width: 100%;
  }

  .text-image-2 {
    display: block;
  }

  .text-image-2 .bg-white {
    bottom: 0;
    right: 0;
  }

  .video-cover .video-play-icon {
    left: 50%;
    transform: translateX(-50%)translateY(-50%);
  }

  .video-play-icon:after {
    content: "Play";
    font-size: .5rem;
    bottom: 51px;
    right: 5px;
  }

  .progress-bar {
    height: 6px;
    display: block;
  }

  .video-poster .btn-round {
    left: 50%;
    transform: translateX(-50%);
  }

  .process-vertical li:not(:last-child):after {
    display: none;
  }

  .flickity-button-icon {
    display: block;
  }

  .min-vh-100 {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .docs-sidebar {
    height: calc(100vh - 4.5625rem);
    position: sticky;
    top: 4.5625rem;
    overflow-y: scroll;
  }

  #docs-index {
    display: block !important;
  }
}

.docs-dl {
  border-top: 1px solid #dee2e6;
  margin-left: 0;
  margin-right: 0;
}

.docs-dl p {
  margin-bottom: 0;
}

.docs-dl > dt, .docs-dl > dd {
  border-bottom: 1px solid #dee2e6;
  margin: 0;
  padding: .5rem;
}

.alert p {
  margin-bottom: 0;
}

.code-snippet {
  position: relative;
}

.code-snippet .btn-clipboard {
  opacity: 0;
  position: absolute;
  top: .75rem;
  right: .75rem;
}

.code-snippet:hover .btn-clipboard {
  opacity: 1;
}

.docs-graphic .btn {
  margin: .5rem;
}

.docs-graphic:not(:hover) .btn {
  opacity: 0;
}

.discord-buttons a.discord-button, .discord-buttons button.discord-button {
  color: #fff;
  min-height: 32px;
  min-width: 60px;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  margin: 4px 8px 4px 0;
  padding: 2px 16px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  transition: background-color .15s, color .15s;
  display: flex;
}

.discord-buttons a.discord-button:hover {
  color: #fff;
  text-decoration: none;
}

.discord-buttons .discord-button .discord-button-emoji {
  width: 19px;
  height: 19px;
  margin-right: 4px;
}

.discord-buttons .discord-button.discord-button-primary {
  background-color: #5865f2;
}

.discord-buttons .discord-button:not(.discord-button-disabled).discord-button-primary:hover {
  background-color: #4752c4;
}

.discord-buttons .discord-button.discord-button-link, .discord-buttons .discord-button.discord-button-secondary {
  background-color: #4f545c;
}

.discord-buttons .discord-button:not(.discord-button-disabled).discord-button-link:hover, .discord-buttons .discord-button:not(.discord-button-disabled).discord-button-secondary:hover {
  background-color: #474c53;
}

.discord-light-theme .discord-buttons .discord-button.discord-button-link, .discord-light-theme .discord-buttons .discord-button.discord-button-secondary {
  background-color: #747f8d;
}

.discord-light-theme .discord-buttons .discord-button:not(.discord-button-disabled).discord-button-link:hover, .discord-light-theme .discord-buttons .discord-button:not(.discord-button-disabled).discord-button-secondary:hover {
  background-color: #68727f;
}

.discord-buttons .discord-button.discord-button-success {
  background-color: #3ba55c;
}

.discord-buttons .discord-button:not(.discord-button-disabled).discord-button-success:hover {
  background-color: #359553;
}

.discord-buttons .discord-button.discord-button-danger {
  background-color: #ed4245;
}

.discord-buttons .discord-button:not(.discord-button-disabled).discord-button-danger:hover {
  background-color: #d53b3e;
}

.discord-buttons .discord-button.discord-button-disabled, .discord-buttons .discord-button:disabled {
  opacity: .5;
  cursor: not-allowed;
}

.discord-buttons .discord-button .outbound-link-icon {
  margin-left: 8px;
  display: inline-flex;
}

.discord-buttons {
  flex-wrap: wrap;
  display: flex;
}

.discord-embed {
  color: #dcddde;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 13px;
  line-height: 150%;
  display: flex;
}

.discord-light-theme .discord-embed {
  color: #2e3338;
}

.discord-embed .discord-embed-left-border {
  width: 4px;
  background-color: #202225;
  border-radius: 4px 0 0 4px;
  flex-shrink: 0;
}

.discord-light-theme .discord-embed .discord-embed-left-border {
  background-color: #e3e5e8;
}

.discord-embed .discord-embed-container {
  max-width: 520px;
  background-color: #2f3136;
  border: 1px solid #2e303699;
  border-radius: 0 4px 4px 0;
  flex-direction: column;
  padding: 8px 16px 16px;
  display: flex;
}

.discord-light-theme .discord-embed .discord-embed-container {
  background-color: #f9f9f94d;
  border-color: #cdcdcd4d;
}

.discord-embed .discord-embed-content {
  display: flex;
}

.discord-embed .discord-embed-content .discord-embed-author {
  color: #fff;
  align-items: center;
  margin-top: 8px;
  font-weight: 500;
  display: flex;
}

.discord-light-theme .discord-embed .discord-embed-content .discord-embed-author {
  color: #4f545c;
}

.discord-embed .discord-embed-content .discord-embed-author a {
  color: #fff;
  font-weight: 500;
}

.discord-light-theme .discord-embed .discord-embed-content .discord-embed-author a {
  color: #4f545c;
}

.discord-embed .discord-embed-content .discord-embed-author .discord-embed-author-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
}

.discord-embed .discord-embed-content .discord-embed-title {
  color: #fff;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 600;
}

.discord-embed .discord-embed-content .discord-embed-title a {
  color: #00b0f4;
  font-weight: 600;
}

.discord-embed .discord-embed-content .discord-embed-description {
  margin-top: 8px;
}

.discord-embed .discord-embed-content .discord-embed-image {
  max-width: 100%;
  border-radius: 4px;
  margin-top: 16px;
}

.discord-embed .discord-embed-content .discord-embed-thumbnail {
  max-width: 80px;
  max-height: 80px;
  object-fit: contain;
  object-position: top center;
  border-radius: 4px;
  margin-top: 8px;
  margin-left: 16px;
}

.discord-embed .discord-embed-footer {
  color: #72767d;
  align-items: center;
  margin-top: 8px;
  font-size: .85em;
  display: flex;
}

.discord-embed .discord-embed-footer .discord-embed-footer-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 8px;
}

.discord-embed .discord-embed-footer .discord-embed-footer-separator {
  color: #3b3c42;
  margin: 0 4px;
  font-weight: 700;
}

.discord-light-theme .discord-embed .discord-embed-footer .discord-embed-footer-separator {
  color: #e4e4e4;
}

.discord-embed .discord-embed-field {
  min-width: 100%;
  margin-top: 5px;
}

.discord-embed .discord-embed-field.discord-embed-field-inline {
  min-width: 100px;
  flex-grow: 1;
  flex-basis: auto;
}

.discord-embed .discord-embed-field .discord-embed-field-title {
  color: #72767d;
  margin-bottom: 2px;
  font-weight: 500;
}

.discord-light-theme .discord-embed .discord-embed-field .discord-embed-field-title {
  color: #747f8d;
}

.discord-embed .discord-embed-fields {
  flex-wrap: wrap;
  margin-top: 8px;
  display: flex;
}

.discord-message .discord-author-info {
  color: #fff;
  align-items: center;
  font-size: 15px;
  display: inline-flex;
}

.discord-compact-mode .discord-message .discord-author-info {
  flex-direction: row-reverse;
  display: inline-flex;
}

.discord-message .discord-author-info .discord-author-username {
  letter-spacing: .5px;
  font-size: 1.1em;
  font-weight: 500;
}

.discord-compact-mode .discord-message .discord-author-info .discord-author-username {
  margin-left: 4px;
  margin-right: 4px;
}

.discord-light-theme .discord-message .discord-author-info .discord-author-username {
  color: #23262a;
}

.discord-message .discord-author-info .discord-author-bot-tag {
  text-transform: uppercase;
  background-color: #5865f2;
  border-radius: 3px;
  margin-left: 5px;
  padding: 3px 4px;
  font-size: .65em;
  line-height: 100%;
}

.discord-compact-mode .discord-message .discord-author-info .discord-author-bot-tag {
  margin-left: 0;
  margin-right: 5px;
  padding-left: 3px;
  padding-right: 3px;
  font-size: .6em;
}

.discord-light-theme .discord-message .discord-author-info .discord-author-bot-tag {
  color: #fff;
}

.discord-interaction {
  color: #b9bbbe;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 4px;
  padding-left: 56px;
  font-size: .95em;
  line-height: 150%;
  display: flex;
  position: relative;
}

.discord-compact-mode .discord-interaction {
  margin-bottom: 0;
  padding-left: 64px;
}

.discord-light-theme .discord-interaction {
  color: #4f5660;
}

.discord-interaction:before {
  content: "";
  width: 33px;
  box-sizing: border-box;
  border-top: 2px solid #4f545c;
  border-left: 2px solid #4f545c;
  border-top-left-radius: 6px;
  margin: -1px 0 0;
  display: block;
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 18px;
}

.discord-compact-mode .discord-interaction:before {
  left: 26px;
}

.discord-interaction .discord-interaction-author-info {
  flex-direction: row-reverse;
  font-size: 1em;
}

.discord-interaction .discord-interaction-author-avatar {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 4px;
}

.discord-compact-mode .discord-interaction .discord-interaction-author-avatar {
  display: none;
}

.discord-interaction .discord-interaction-author-info .discord-author-username {
  opacity: .65;
  margin-right: 4px;
  font-size: 1em;
}

.discord-compact-mode .discord-interaction .discord-interaction-author-info .discord-author-username {
  margin-left: 0;
}

.discord-interaction .discord-interaction-author-info .discord-author-bot-tag {
  margin: 1px 4px 0 0;
  font-size: .75em;
}

.discord-compact-mode .discord-interaction .discord-interaction-author-info .discord-author-bot-tag {
  padding-left: 4px;
  padding-right: 4px;
  font-size: .7em;
}

.discord-interaction .discord-interaction-command .discord-interaction-command-name {
  color: #7289da;
  opacity: .65;
}

.discord-interaction .discord-interaction-reply {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.discord-interaction .discord-interaction-reply .discord-interaction-reply-edited {
  color: #72767d;
  margin-left: 3px;
  font-size: 10px;
}

.discord-interaction .discord-interaction-reply:hover {
  color: #fff;
}

.discord-light-theme .discord-interaction .discord-interaction-reply:hover {
  color: #060607;
}

.discord-markdown .discord-markdown-content > pre {
  max-width: 90%;
  white-space: pre-wrap;
  box-sizing: border-box;
  border-radius: 4px;
  margin: .4em 0 0;
}

.discord-markdown .discord-markdown-content > pre > code {
  color: #b9bbbe;
  background-color: #2f3135;
  border: 1px solid #202225;
  border-radius: 4px;
  padding: .5em;
  font-size: 1em;
  display: block;
}

.discord-light-theme .discord-markdown .discord-markdown-content > pre > code {
  color: #4f5660;
  background-color: #f2f3f9;
  border-color: #e3e5e8;
}

.discord-markdown .discord-markdown-content > code {
  white-space: pre-wrap;
  background-color: #2f3136;
  border-radius: 3px;
  padding: 3px;
  font-size: .95em;
}

.discord-light-theme .discord-markdown .discord-markdown-content > code {
  background-color: #f2f3f5;
}

.discord-markdown .discord-markdown-content .d-spoiler {
  color: #0000;
  background-color: #202225;
  border-radius: 3px;
  transition: all .1s ease-in-out;
}

.discord-light-theme .discord-markdown .discord-markdown-content .d-spoiler {
  background-color: #b9bbbe;
}

.discord-markdown .discord-markdown-content .d-spoiler:hover {
  color: inherit;
  background-color: #ffffff1a;
}

.discord-light-theme .discord-markdown .discord-markdown-content .d-spoiler:hover {
  background-color: #0000001a;
}

.discord-markdown .discord-markdown-content > blockquote {
  margin: 0;
  padding: 0 8px 0 16px;
  position: relative;
}

.discord-markdown .discord-markdown-content > blockquote:before {
  content: "";
  width: 4px;
  background-color: #4f545c;
  border-radius: 4px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.discord-light-theme .discord-markdown .discord-markdown-content > blockquote:before {
  background-color: #c7ccd1;
}

.discord-message .discord-mention {
  color: #dee0fc;
  background-color: #5865f24d;
  border-radius: 3px;
  padding: 0 2px;
  font-weight: 500;
  transition: background-color 50ms ease-out, color 50ms ease-out;
}

.discord-message .discord-mention:hover {
  background-color: #5865f2;
}

.discord-message {
  color: #dcddde;
  flex-direction: column;
  margin: 1em 0;
  padding: .25em 1em 0;
  font-size: .9em;
  display: flex;
}

.discord-message:hover {
  background-color: #32353b;
}

.discord-compact-mode .discord-message {
  margin: .15em 0;
  padding-left: .25em;
  padding-right: .25em;
}

.discord-light-theme .discord-message {
  color: #2e3338;
}

.discord-light-theme .discord-message:hover {
  background-color: #fafafa;
}

.discord-message.discord-ephemeral-highlight {
  background-color: #5865f20d;
}

.discord-message.discord-mention-highlight {
  background-color: #faa61a1a;
}

.discord-message.discord-ephemeral-highlight, .discord-message.discord-mention-highlight {
  position: relative;
}

.discord-message.discord-ephemeral-highlight:before, .discord-message.discord-mention-highlight:before {
  content: "";
  width: 2px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.discord-message.discord-ephemeral-highlight:before {
  background-color: #5865f2;
}

.discord-message.discord-mention-highlight:before {
  background-color: #faa61a;
}

.discord-message.discord-ephemeral-highlight:hover {
  background-color: #5865f21a;
}

.discord-message.discord-mention-highlight:hover {
  background-color: #faa61a14;
}

.discord-light-theme .discord-message.discord-mention-highlight:hover {
  background-color: #faa61a33;
}

.discord-message a {
  color: #0096cf;
  font-weight: 400;
  text-decoration: none;
}

.discord-message a:hover {
  text-decoration: underline;
}

.discord-light-theme .discord-message a {
  color: #00b0f4;
}

.discord-message .discord-author-avatar {
  min-width: 40px;
  margin-top: 1px;
  margin-right: 16px;
}

.discord-compact-mode .discord-message .discord-author-avatar {
  display: none;
}

.discord-message .discord-author-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.discord-message .discord-message-content {
  width: 100%;
  overflow-wrap: anywhere;
  font-weight: 400;
  line-height: 160%;
  display: flex;
}

.discord-message .discord-message-content .discord-message-timestamp {
  color: #72767d;
  margin-left: 3px;
  font-size: 12px;
}

.discord-compact-mode .discord-message:hover .discord-message-content .discord-message-timestamp, .discord-compact-mode.discord-light-theme .discord-message:hover .discord-message-content .discord-message-timestamp, .discord-light-theme .discord-message .discord-message-content .discord-message-timestamp {
  color: #99aab5;
}

.discord-compact-mode .discord-message .discord-message-content .discord-message-timestamp {
  min-width: 48px;
  text-align: right;
  margin-left: 0;
  margin-right: 3px;
  font-size: 11px;
  display: inline-block;
}

.discord-compact-mode.discord-light-theme .discord-message .discord-message-content .discord-message-timestamp {
  color: #d1d9de;
}

.discord-message .discord-message-body {
  width: 100%;
  position: relative;
}

.discord-compact-mode .discord-message-body {
  margin-left: .25em;
}

.discord-message .discord-message-body .discord-message-edited {
  color: #72767d;
  margin-left: 3px;
  font-size: 10px;
}

.discord-message .discord-message-ephemeral-notice {
  color: #72767d;
  margin-top: 4px;
  font-size: 12px;
}

.discord-messages {
  color: #fff;
  background-color: #36393e;
  border: 1px solid #ffffff0d;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 170%;
}

.discord-messages.discord-light-theme {
  color: #747f8d;
  background-color: #f7f7f7;
  border-color: #dedede;
}

.discord-reactions .discord-reaction {
  background-color: #2f3136;
  border: 1px solid #0000;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 2px;
  margin-right: 2px;
  padding: 0 .4em;
  transition: background-color .1s, border-color .1s;
  display: inline-flex;
}

.discord-light-theme .discord-reactions .discord-reaction {
  background-color: #f2f3f5;
}

.discord-reactions .discord-reaction:hover {
  background-color: #36393f;
  border-color: #fff3;
}

.discord-light-theme .discord-reactions .discord-reaction:hover {
  border-color: #0003;
}

.discord-reactions .discord-reaction.discord-reaction-active {
  background-color: #5865f226;
  border-color: #5865f2;
}

.discord-reactions .discord-reaction .discord-reaction-emoji {
  width: 16px;
  height: 16px;
}

.discord-reactions .discord-reaction .discord-reaction-count {
  margin-left: 6px;
}

.discord-reactions {
  flex-wrap: wrap;
  padding: 2px 0;
  display: flex;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #db162f;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #212529;
  --primary-2: #3423a6;
  --primary-3: #010e1e;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #495057;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: Inter UI, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  cursor: help;
  text-decoration-skip-ink: none;
  border-bottom: 0;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #db162f;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #950f20;
  text-decoration: none;
}

a:not([href]), a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  color: #adb5bd;
  text-align: left;
  caption-side: bottom;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

th {
  text-align: inherit;
}

label {
  margin-bottom: .75rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #212529;
  margin-bottom: 1rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 400;
}

.display-1 {
  font-size: 6rem;
  font-weight: 600;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 600;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 600;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 1.2;
}

hr {
  border: 0;
  border-top: 1px solid #0000001a;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

small, .small {
  font-size: .75rem;
  font-weight: 400;
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  color: #6c757d;
  font-size: .75rem;
  display: block;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .3125rem;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .75rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: 90%;
}

code {
  color: #e83e8c;
  word-wrap: break-word;
  font-size: 87.5%;
}

a > code {
  color: inherit;
}

kbd {
  color: #fff;
  background-color: #212529;
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: 87.5%;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 600;
}

pre {
  color: #212529;
  font-size: 87.5%;
  display: block;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
}

.col {
  min-width: 0;
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.row-cols-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.row-cols-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.row-cols-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.row-cols-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.row-cols-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.row-cols-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (min-width: 576px) {
  .col-sm {
    min-width: 0;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-sm-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-sm-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-sm-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-sm-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-sm-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-sm-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-sm-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-sm-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-sm-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-sm-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-sm-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-sm-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-sm-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-sm-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    min-width: 0;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-md-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-md-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-md-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-md-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-md-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-md-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-md-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-md-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-md-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-md-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-md-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-md-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-md-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-md-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-md-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-md-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    min-width: 0;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-lg-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-lg-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-lg-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-lg-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-lg-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-lg-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-lg-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-lg-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-lg-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-lg-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-lg-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-lg-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-lg-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-lg-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-lg-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    min-width: 0;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
  }

  .row-cols-xl-1 > * {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .row-cols-xl-2 > * {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .row-cols-xl-3 > * {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .row-cols-xl-4 > * {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .row-cols-xl-5 > * {
    max-width: 20%;
    flex: 0 0 20%;
  }

  .row-cols-xl-6 > * {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-auto {
    width: auto;
    max-width: 100%;
    flex: none;
  }

  .col-xl-1 {
    max-width: 8.33333%;
    flex: 0 0 8.33333%;
  }

  .col-xl-2 {
    max-width: 16.6667%;
    flex: 0 0 16.6667%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.3333%;
    flex: 0 0 33.3333%;
  }

  .col-xl-5 {
    max-width: 41.6667%;
    flex: 0 0 41.6667%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-7 {
    max-width: 58.3333%;
    flex: 0 0 58.3333%;
  }

  .col-xl-8 {
    max-width: 66.6667%;
    flex: 0 0 66.6667%;
  }

  .col-xl-9 {
    max-width: 75%;
    flex: 0 0 75%;
  }

  .col-xl-10 {
    max-width: 83.3333%;
    flex: 0 0 83.3333%;
  }

  .col-xl-11 {
    max-width: 91.6667%;
    flex: 0 0 91.6667%;
  }

  .col-xl-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.table {
  width: 100%;
  color: #495057;
  background-color: #0000;
  margin-bottom: 1.5rem;
}

.table th, .table td {
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  padding: 1rem;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th, .table-sm td {
  padding: .5rem;
}

.table-bordered, .table-bordered th, .table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th, .table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th, .table-borderless td, .table-borderless thead th, .table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #db162f0d;
}

.table-hover tbody tr:hover {
  color: #495057;
  background-color: #db162f0d;
}

.table-primary, .table-primary > th, .table-primary > td {
  background-color: #f5bec5;
}

.table-primary th, .table-primary td, .table-primary thead th, .table-primary tbody + tbody {
  border-color: #ec8693;
}

.table-hover .table-primary:hover, .table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #f2a8b1;
}

.table-secondary, .table-secondary > th, .table-secondary > td {
  background-color: #d6d8db;
}

.table-secondary th, .table-secondary td, .table-secondary thead th, .table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover, .table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success, .table-success > th, .table-success > td {
  background-color: #c3e6cb;
}

.table-success th, .table-success td, .table-success thead th, .table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover, .table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info, .table-info > th, .table-info > td {
  background-color: #bee5eb;
}

.table-info th, .table-info td, .table-info thead th, .table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover, .table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning, .table-warning > th, .table-warning > td {
  background-color: #ffeeba;
}

.table-warning th, .table-warning td, .table-warning thead th, .table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover, .table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger, .table-danger > th, .table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th, .table-danger td, .table-danger thead th, .table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover, .table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light, .table-light > th, .table-light > td {
  background-color: #fdfdfe;
}

.table-light th, .table-light td, .table-light thead th, .table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover, .table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark, .table-dark > th, .table-dark > td {
  background-color: #c1c2c3;
}

.table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody {
  border-color: #8c8e90;
}

.table-hover .table-dark:hover, .table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #b4b5b6;
}

.table-primary-2, .table-primary-2 > th, .table-primary-2 > td {
  background-color: #c6c1e6;
}

.table-primary-2 th, .table-primary-2 td, .table-primary-2 thead th, .table-primary-2 tbody + tbody {
  border-color: #958dd1;
}

.table-hover .table-primary-2:hover, .table-hover .table-primary-2:hover > td, .table-hover .table-primary-2:hover > th {
  background-color: #b5afdf;
}

.table-primary-3, .table-primary-3 > th, .table-primary-3 > td {
  background-color: #b8bcc0;
}

.table-primary-3 th, .table-primary-3 td, .table-primary-3 thead th, .table-primary-3 tbody + tbody {
  border-color: #7b828a;
}

.table-hover .table-primary-3:hover, .table-hover .table-primary-3:hover > td, .table-hover .table-primary-3:hover > th {
  background-color: #aaafb4;
}

.table-active, .table-active > th, .table-active > td {
  background-color: #db162f0d;
}

.table-hover .table-active:hover, .table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: #c4142a0d;
}

.table .thead-dark th {
  color: #fff;
  background-color: #010e1e;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #010e1e;
}

.table-dark th, .table-dark td, .table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: #ffffff0d;
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: #ffffff13;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    width: 100%;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow-x: auto;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  width: 100%;
  height: calc(1.5em + .875rem + 2px);
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: .3125rem;
  padding: .4375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: #0000;
  border: 0;
}

.form-control:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #495057;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ced4da;
  outline: 0;
  box-shadow: 0 .125rem .625rem #0000000d;
}

.form-control::-webkit-input-placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control::placeholder {
  color: #adb5bd;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}

input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file, .form-control-range {
  width: 100%;
  display: block;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.4375rem + 1px);
  padding-bottom: calc(.4375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.875rem + 1px);
  padding-bottom: calc(.875rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(.21875rem + 1px);
  padding-bottom: calc(.21875rem + 1px);
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  width: 100%;
  color: #495057;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .4375rem 0;
  font-size: 1rem;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  height: calc(1.5em + .4375rem + 2px);
  border-radius: .3125rem;
  padding: .21875rem 1rem;
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-lg {
  height: calc(1.5em + 1.75rem + 2px);
  border-radius: .3125rem;
  padding: .875rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control[size], select.form-control[multiple], textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  margin-top: .25rem;
  display: block;
}

.form-row {
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
}

.form-row > .col, .form-row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.form-check {
  padding-left: 1.25rem;
  display: block;
  position: relative;
}

.form-check-input {
  margin-top: .3rem;
  margin-left: -1.25rem;
  position: absolute;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #adb5bd;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  align-items: center;
  margin-right: .75rem;
  padding-left: 0;
  display: inline-flex;
}

.form-check-inline .form-check-input {
  margin-top: 0;
  margin-left: 0;
  margin-right: .3125rem;
  position: static;
}

.valid-feedback {
  width: 100%;
  color: #28a745;
  margin-top: .25rem;
  font-size: .75rem;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #28a745;
  border-radius: .3125rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .75rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .21875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .4375rem) calc(.75em + .4375rem);
  border-color: #28a745;
  padding-right: calc(1.5em + .875rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .21875rem) top calc(.375em + .21875rem);
  padding-right: calc(1.5em + .875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") right 1.75rem center / calc(.75em + .4375rem) calc(.75em + .4375rem) no-repeat;
  border-color: #28a745;
  padding-right: calc(.75em + 2.3125rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label:before, .custom-control-input.is-valid ~ .custom-control-label:before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label:before, .custom-control-input.is-valid:checked ~ .custom-control-label:before {
  background-color: #34ce57;
  border-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label:before, .custom-control-input.is-valid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #28a74540;
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label:before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.invalid-feedback {
  width: 100%;
  color: #dc3545;
  margin-top: .25rem;
  font-size: .75rem;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: #dc3545;
  border-radius: .3125rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .75rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .21875rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .4375rem) calc(.75em + .4375rem);
  border-color: #dc3545;
  padding-right: calc(1.5em + .875rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .21875rem) top calc(.375em + .21875rem);
  padding-right: calc(1.5em + .875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") right 1.75rem center / calc(.75em + .4375rem) calc(.75em + .4375rem) no-repeat;
  border-color: #dc3545;
  padding-right: calc(.75em + 2.3125rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label:before, .custom-control-input.is-invalid ~ .custom-control-label:before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before, .custom-control-input.is-invalid:checked ~ .custom-control-label:before {
  background-color: #e4606d;
  border-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before, .custom-control-input.is-invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #dc354540;
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label:before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.form-inline {
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-group {
    flex-flow: wrap;
    flex: none;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-control {
    width: auto;
    vertical-align: middle;
    display: inline-block;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group, .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    width: auto;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    display: flex;
  }

  .form-inline .form-check-input {
    flex-shrink: 0;
    margin-top: 0;
    margin-left: 0;
    margin-right: .25rem;
    position: relative;
  }

  .form-inline .custom-control {
    justify-content: center;
    align-items: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  color: #495057;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .3125rem;
  padding: .4375rem 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #495057;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #db162f40;
}

.btn.disabled, .btn:disabled {
  opacity: .65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #db162f;
  border-color: #db162f;
}

.btn-primary:hover {
  color: #fff;
  background-color: #b81328;
  border-color: #ad1125;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #b81328;
  border-color: #ad1125;
  box-shadow: 0 0 0 .2rem #e0394e80;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #db162f;
  border-color: #db162f;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #ad1125;
  border-color: #a11023;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #e0394e80;
}

.btn-primary.blue {
  color: #fff;
  background-color: #3755be;
  border-color: #3755be;
}

.btn-primary.blue:hover {
  color: #fff;
  background-color: #2e48a0;
  border-color: #2c4396;
}

.btn-primary.blue:focus, .btn-primary.blue.focus {
  color: #fff;
  background-color: #2e48a0;
  border-color: #2c4396;
  box-shadow: 0 0 0 .2rem #556fc880;
}

.btn-primary.blue.disabled, .btn-primary.blue:disabled {
  color: #fff;
  background-color: #3755be;
  border-color: #3755be;
}

.btn-primary.blue:not(:disabled):not(.disabled):active, .btn-primary.blue:not(:disabled):not(.disabled).active, .show > .btn-primary.blue.dropdown-toggle {
  color: #fff;
  background-color: #2c4396;
  border-color: #293f8d;
}

.btn-primary.blue:not(:disabled):not(.disabled):active:focus, .btn-primary.blue:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #556fc880;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 .2rem #828a9180;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #828a9180;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 .2rem #48b46180;
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #48b46180;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 .2rem #e1536180;
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #e1536180;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-dark:hover {
  color: #fff;
  background-color: #101214;
  border-color: #0a0c0d;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #101214;
  border-color: #0a0c0d;
  box-shadow: 0 0 0 .2rem #42464980;
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #0a0c0d;
  border-color: #050506;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #42464980;
}

.btn-primary-2 {
  color: #fff;
  background-color: #3423a6;
  border-color: #3423a6;
}

.btn-primary-2:hover {
  color: #fff;
  background-color: #2a1c86;
  border-color: #271a7c;
}

.btn-primary-2:focus, .btn-primary-2.focus {
  color: #fff;
  background-color: #2a1c86;
  border-color: #271a7c;
  box-shadow: 0 0 0 .2rem #5244b380;
}

.btn-primary-2.disabled, .btn-primary-2:disabled {
  color: #fff;
  background-color: #3423a6;
  border-color: #3423a6;
}

.btn-primary-2:not(:disabled):not(.disabled):active, .btn-primary-2:not(:disabled):not(.disabled).active, .show > .btn-primary-2.dropdown-toggle {
  color: #fff;
  background-color: #271a7c;
  border-color: #241871;
}

.btn-primary-2:not(:disabled):not(.disabled):active:focus, .btn-primary-2:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #5244b380;
}

.btn-primary-3 {
  color: #fff;
  background-color: #010e1e;
  border-color: #010e1e;
}

.btn-primary-3:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-primary-3:focus, .btn-primary-3.focus {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: 0 0 0 .2rem #27324080;
}

.btn-primary-3.disabled, .btn-primary-3:disabled {
  color: #fff;
  background-color: #010e1e;
  border-color: #010e1e;
}

.btn-primary-3:not(:disabled):not(.disabled):active, .btn-primary-3:not(:disabled):not(.disabled).active, .show > .btn-primary-3.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-primary-3:not(:disabled):not(.disabled):active:focus, .btn-primary-3:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-3.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #27324080;
}

.btn-outline-primary {
  color: #db162f;
  border-color: #db162f;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #db162f;
  border-color: #db162f;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 .2rem #db162f80;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #db162f;
  background-color: #0000;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #db162f;
  border-color: #db162f;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #db162f80;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: #0000;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: #0000;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: #0000;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: #0000;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: #0000;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: #0000;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 .2rem #21252980;
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #212529;
  background-color: #0000;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #21252980;
}

.btn-outline-primary-2 {
  color: #3423a6;
  border-color: #3423a6;
}

.btn-outline-primary-2:hover {
  color: #fff;
  background-color: #3423a6;
  border-color: #3423a6;
}

.btn-outline-primary-2:focus, .btn-outline-primary-2.focus {
  box-shadow: 0 0 0 .2rem #3423a680;
}

.btn-outline-primary-2.disabled, .btn-outline-primary-2:disabled {
  color: #3423a6;
  background-color: #0000;
}

.btn-outline-primary-2:not(:disabled):not(.disabled):active, .btn-outline-primary-2:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-2.dropdown-toggle {
  color: #fff;
  background-color: #3423a6;
  border-color: #3423a6;
}

.btn-outline-primary-2:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-2:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-2.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #3423a680;
}

.btn-outline-primary-3 {
  color: #010e1e;
  border-color: #010e1e;
}

.btn-outline-primary-3:hover {
  color: #fff;
  background-color: #010e1e;
  border-color: #010e1e;
}

.btn-outline-primary-3:focus, .btn-outline-primary-3.focus {
  box-shadow: 0 0 0 .2rem #010e1e80;
}

.btn-outline-primary-3.disabled, .btn-outline-primary-3:disabled {
  color: #010e1e;
  background-color: #0000;
}

.btn-outline-primary-3:not(:disabled):not(.disabled):active, .btn-outline-primary-3:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-3.dropdown-toggle {
  color: #fff;
  background-color: #010e1e;
  border-color: #010e1e;
}

.btn-outline-primary-3:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-3:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-3.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #010e1e80;
}

.btn-link {
  color: #db162f;
  font-weight: 400;
  text-decoration: none;
}

.btn-link:hover {
  color: #950f20;
  text-decoration: none;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  border-radius: .3125rem;
  padding: .875rem 2.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn-sm, .btn-group-sm > .btn {
  border-radius: .3125rem;
  padding: .21875rem 1rem;
  font-size: .875rem;
  line-height: 1.5;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: .5rem;
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  position: relative;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup, .dropright, .dropdown, .dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  min-width: 10rem;
  color: #495057;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: .3125rem;
  margin: .125rem 0 0;
  padding: .75rem 0;
  font-size: 1rem;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-right {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-right {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: .125rem;
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: .125rem;
  top: 0;
  left: 100%;
  right: auto;
}

.dropright .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropright .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-toggle:after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: .125rem;
  top: 0;
  left: auto;
  right: 100%;
}

.dropleft .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropleft .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  bottom: auto;
  right: auto;
}

.dropdown-divider {
  height: 0;
  border-top: 1px solid #dee2e6;
  margin: .75rem 0;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  clear: both;
  color: #49505780;
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  padding: .25rem 1.5rem;
  font-weight: 400;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #3d4349;
  background-color: #0000;
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
  color: #db162f;
  background-color: #0000;
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: #212529;
  white-space: nowrap;
  margin-bottom: 0;
  padding: .75rem 1.5rem;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  color: #49505780;
  padding: .25rem 1.5rem;
  display: block;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn:hover, .btn-group-vertical > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .9375rem;
  padding-right: .9375rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropright .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: 1.6875rem;
  padding-right: 1.6875rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .btn input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-control-plaintext, .input-group > .custom-select, .input-group > .custom-file {
  width: 1%;
  min-width: 0;
  flex: auto;
  margin-bottom: 0;
  position: relative;
}

.input-group > .form-control + .form-control, .input-group > .form-control + .custom-select, .input-group > .form-control + .custom-file, .input-group > .form-control-plaintext + .form-control, .input-group > .form-control-plaintext + .custom-select, .input-group > .form-control-plaintext + .custom-file, .input-group > .custom-select + .form-control, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .custom-file, .input-group > .custom-file + .form-control, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus, .input-group > .custom-select:focus, .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  align-items: center;
  display: flex;
}

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend, .input-group-append {
  display: flex;
}

.input-group-prepend .btn, .input-group-append .btn {
  z-index: 2;
  position: relative;
}

.input-group-prepend .btn:focus, .input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .input-group-text, .input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .3125rem;
  align-items: center;
  margin-bottom: 0;
  padding: .4375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-text input[type="radio"], .input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea), .input-group-lg > .custom-select {
  height: calc(1.5em + 1.75rem + 2px);
}

.input-group-lg > .form-control, .input-group-lg > .custom-select, .input-group-lg > .input-group-prepend > .input-group-text, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn {
  border-radius: .3125rem;
  padding: .875rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.input-group-sm > .form-control:not(textarea), .input-group-sm > .custom-select {
  height: calc(1.5em + .4375rem + 2px);
}

.input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
  border-radius: .3125rem;
  padding: .21875rem 1rem;
  font-size: .875rem;
  line-height: 1.5;
}

.input-group-lg > .custom-select, .input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text, .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  min-height: 1.5rem;
  padding-left: 2.5rem;
  display: block;
  position: relative;
}

.custom-control-inline {
  margin-right: 1rem;
  display: inline-flex;
}

.custom-control-input {
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  left: 0;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  background-color: #db162f;
  border-color: #db162f;
}

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #db162f40;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label:before {
  border-color: #ced4da;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label:before {
  color: #fff;
  background-color: #f7adb6;
  border-color: #f7adb6;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label:before, .custom-control-input:disabled ~ .custom-control-label:before {
  background-color: #e9ecef;
}

.custom-control-label {
  vertical-align: top;
  margin-bottom: 0;
  position: relative;
}

.custom-control-label:before {
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
  display: block;
  position: absolute;
  top: .25rem;
  left: -2.5rem;
}

.custom-control-label:after {
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
  display: block;
  position: absolute;
  top: .25rem;
  left: -2.5rem;
}

.custom-checkbox .custom-control-label:before {
  border-radius: .3125rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  background-color: #db162f;
  border-color: #db162f;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:before, .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label:before {
  background-color: #db162f80;
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #db162f80;
}

.custom-switch {
  padding-left: 4.5rem;
}

.custom-switch .custom-control-label:before {
  width: 3rem;
  pointer-events: all;
  border-radius: 200px;
  left: -4.5rem;
}

.custom-switch .custom-control-label:after {
  width: 1rem;
  height: 1rem;
  background-color: #adb5bd;
  border-radius: 200px;
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  top: calc(.25rem + 2px);
  left: calc(2px - 4.5rem);
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label:after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  background-color: #fff;
  transform: translateX(2rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #db162f80;
}

.custom-select {
  width: 100%;
  height: calc(1.5em + .875rem + 2px);
  color: #495057;
  vertical-align: middle;
  appearance: none;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat;
  border: 1px solid #dee2e6;
  border-radius: .3125rem;
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: inline-block;
}

.custom-select:focus {
  border-color: #ced4da;
  outline: 0;
  box-shadow: 0 0 0 .2rem #ced4da80;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  background-image: none;
  padding-right: .75rem;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + .4375rem + 2px);
  padding-top: .21875rem;
  padding-bottom: .21875rem;
  padding-left: 1rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(1.5em + 1.75rem + 2px);
  padding-top: .875rem;
  padding-bottom: .875rem;
  padding-left: .875rem;
  font-size: 125%;
}

.custom-file {
  width: 100%;
  height: calc(1.5em + .875rem + 2px);
  margin-bottom: 0;
  display: inline-block;
  position: relative;
}

.custom-file-input {
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .875rem + 2px);
  opacity: 0;
  margin: 0;
  position: relative;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #ced4da;
  box-shadow: 0 0 0 .2rem #db162f40;
}

.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label:after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]:after {
  content: attr(data-browse);
}

.custom-file-label {
  z-index: 1;
  height: calc(1.5em + .875rem + 2px);
  color: #495057;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .3125rem;
  padding: .4375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.custom-file-label:after {
  z-index: 3;
  height: calc(1.5em + .875rem);
  color: #495057;
  content: "Browse";
  border-left: inherit;
  background-color: #fff;
  border-radius: 0 .3125rem .3125rem 0;
  padding: .4375rem .75rem;
  line-height: 1.5;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  appearance: none;
  background-color: #0000;
  padding: 0;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 1rem 3rem #0000002d;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 1rem 3rem #0000002d;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 1rem 3rem #0000002d;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 1rem;
  margin-top: -.3125rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #db162f;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .375rem;
  color: #0000;
  cursor: pointer;
  background-color: #e9ecef;
  border-color: #0000;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #db162f;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: .375rem;
  color: #0000;
  cursor: pointer;
  background-color: #e9ecef;
  border-color: #0000;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 1rem;
  margin-top: 0;
  margin-left: .2rem;
  margin-right: .2rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #db162f;
}

.custom-range::-ms-track {
  width: 100%;
  height: .375rem;
  color: #0000;
  cursor: pointer;
  background-color: #0000;
  border-width: .5rem;
  border-color: #0000;
}

.custom-range::-ms-fill-lower {
  background-color: #e9ecef;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  background-color: #e9ecef;
  border-radius: 1rem;
  margin-right: 15px;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label:before, .custom-file-label, .custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label:before, .custom-file-label, .custom-select {
    transition: none;
  }
}

.nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: .5rem 1rem;
  display: block;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: inherit;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid #0000;
  border-top-left-radius: .3125rem;
  border-top-right-radius: .3125rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: inherit;
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  border-radius: .3125rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #db162f;
}

.nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  display: flex;
  position: relative;
}

.navbar .container, .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1.5rem;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  display: inline-block;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  float: none;
  position: static;
}

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: inline-block;
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: 0;
  padding: 0;
  font-size: 1.25rem;
  line-height: 1;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: center / 100% 100% no-repeat;
  display: inline-block;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row;
  justify-content: flex-start;
}

.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: .75rem;
  padding-right: .75rem;
}

.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #495057;
}

.navbar-light .navbar-nav .nav-link {
  color: #49505780;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #495057e6;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #4950574d;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
  color: #495057;
}

.navbar-light .navbar-toggler {
  color: #49505780;
  border-color: #4950571a;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2873, 80, 87, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #49505780;
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #495057;
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff80;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffffbf;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #ffffff80;
  border-color: #ffffff1a;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #ffffff80;
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #00000020;
  border-radius: .625rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(.625rem - 1px);
  border-top-right-radius: calc(.625rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-left-radius: calc(.625rem - 1px);
  border-bottom-right-radius: calc(.625rem - 1px);
}

.card-body {
  min-height: 1px;
  flex: auto;
  padding: 2rem;
}

.card-title {
  margin-bottom: 2rem;
}

.card-subtitle {
  margin-top: -1rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 2rem;
}

.card-header {
  background-color: #00000008;
  border-bottom: 1px solid #00000020;
  margin-bottom: 0;
  padding: 2rem;
}

.card-header:first-child {
  border-radius: calc(.625rem - 1px) calc(.625rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  background-color: #00000008;
  border-top: 1px solid #00000020;
  padding: 2rem;
}

.card-footer:last-child {
  border-radius: 0 0 calc(.625rem - 1px) calc(.625rem - 1px);
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -2rem;
  margin-left: -1rem;
  margin-right: -1rem;
}

.card-header-pills {
  margin-left: -1rem;
  margin-right: -1rem;
}

.card-img-overlay {
  padding: 1.25rem;
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
  flex-shrink: 0;
}

.card-img, .card-img-top {
  border-top-left-radius: calc(.625rem - 1px);
  border-top-right-radius: calc(.625rem - 1px);
}

.card-img, .card-img-bottom {
  border-bottom-left-radius: calc(.625rem - 1px);
  border-bottom-right-radius: calc(.625rem - 1px);
}

.card-deck .card {
  margin-bottom: 12px;
}

@media (min-width: 576px) {
  .card-deck {
    flex-flow: wrap;
    margin-left: -12px;
    margin-right: -12px;
    display: flex;
  }

  .card-deck .card {
    flex: 1 0;
    margin-bottom: 0;
    margin-left: 12px;
    margin-right: 12px;
  }
}

.card-group > .card {
  margin-bottom: 12px;
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 2rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
    column-gap: 1.25rem;
  }

  .card-columns .card {
    width: 100%;
    display: inline-block;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  background-color: #db162f1a;
  border-radius: 200rem;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding: .5rem 1.25rem;
  list-style: none;
  display: flex;
}

.breadcrumb-item {
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .25rem;
}

.breadcrumb-item + .breadcrumb-item:before {
  color: #6c757d;
  content: "/";
  padding-right: .25rem;
  display: inline-block;
}

.breadcrumb-item + .breadcrumb-item:hover:before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #adb5bd;
}

.pagination {
  border-radius: .3125rem;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  color: #db162f;
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin-left: -1px;
  padding: .5rem .75rem;
  line-height: 1.25;
  display: block;
  position: relative;
}

.page-link:hover {
  z-index: 2;
  color: #950f20;
  background-color: #e9ecef;
  border-color: #dee2e6;
  text-decoration: none;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 .2rem #db162f40;
}

.page-item:first-child .page-link {
  border-top-left-radius: .3125rem;
  border-bottom-left-radius: .3125rem;
  margin-left: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: .3125rem;
  border-bottom-right-radius: .3125rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #db162f;
  border-color: #db162f;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .625rem;
  border-bottom-left-radius: .625rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .625rem;
  border-bottom-right-radius: .625rem;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.badge {
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .3125rem;
  padding: .5em .5rem;
  font-size: .75rem;
  font-weight: 600;
  line-height: 1;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  border-radius: 10rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.badge-primary {
  color: #fff;
  background-color: #db162f;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #ad1125;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #db162f80;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #28a74580;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #dc354580;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.badge-dark {
  color: #fff;
  background-color: #212529;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #0a0c0d;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #21252980;
}

.badge-primary-2 {
  color: #fff;
  background-color: #3423a6;
}

a.badge-primary-2:hover, a.badge-primary-2:focus {
  color: #fff;
  background-color: #271a7c;
}

a.badge-primary-2:focus, a.badge-primary-2.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #3423a680;
}

.badge-primary-3 {
  color: #fff;
  background-color: #010e1e;
}

a.badge-primary-3:hover, a.badge-primary-3:focus {
  color: #fff;
  background-color: #000;
}

a.badge-primary-3:focus, a.badge-primary-3.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #010e1e80;
}

.jumbotron {
  background-color: #e9ecef;
  border-radius: .625rem;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.alert {
  border: 1px solid #0000;
  border-radius: .3125rem;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  color: inherit;
  padding: .75rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  color: #c9142b;
  background-color: #fbe3e6;
  border-color: #f2abb4;
}

.alert-primary hr {
  border-top-color: #ef95a0;
}

.alert-primary .alert-link {
  color: #9b0f21;
}

.alert-secondary {
  color: #636c73;
  background-color: #edeeef;
  border-color: #cacdd0;
}

.alert-secondary hr {
  border-top-color: #bcc0c4;
}

.alert-secondary .alert-link {
  color: #4b5258;
}

.alert-success {
  color: #259a3f;
  background-color: #e5f4e9;
  border-color: #b2dfbc;
}

.alert-success hr {
  border-top-color: #a0d8ac;
}

.alert-success .alert-link {
  color: #1b712e;
}

.alert-info {
  color: #1595a9;
  background-color: #e3f4f6;
  border-color: #abdee5;
}

.alert-info hr {
  border-top-color: #98d6df;
}

.alert-info .alert-link {
  color: #0f6d7c;
}

.alert-warning {
  color: #ebb206;
  background-color: #fff8e1;
  border-color: #ffe9a6;
}

.alert-warning hr {
  border-top-color: #ffe38d;
}

.alert-warning .alert-link {
  color: #b98c05;
}

.alert-danger {
  color: #ca313f;
  background-color: #fbe7e9;
  border-color: #f2b6bc;
}

.alert-danger hr {
  border-top-color: #eea0a8;
}

.alert-danger .alert-link {
  color: #a12732;
}

.alert-light {
  color: #e4e5e6;
  background-color: #fefefe;
  border-color: #fcfdfd;
}

.alert-light hr {
  border-top-color: #edf3f3;
}

.alert-light .alert-link {
  color: #cacccd;
}

.alert-dark {
  color: #1e2226;
  background-color: #e4e5e5;
  border-color: #afb1b2;
}

.alert-dark hr {
  border-top-color: #a2a4a5;
}

.alert-dark .alert-link {
  color: #08090a;
}

.alert-primary-2 {
  color: #302099;
  background-color: #e7e5f4;
  border-color: #b6b0df;
}

.alert-primary-2 hr {
  border-top-color: #a59ed8;
}

.alert-primary-2 .alert-link {
  color: #23176f;
}

.alert-primary-3 {
  color: #010d1c;
  background-color: #e1e2e4;
  border-color: #a4a8ae;
}

.alert-primary-3 hr {
  border-top-color: #979ba2;
}

.alert-primary-3 .alert-link {
  color: #000;
}

@keyframes progress-bar-stripes {
  from {
    background-position: .375rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: .375rem;
  background-color: #e9ecef;
  border-radius: 10rem;
  font-size: .75rem;
  line-height: 0;
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #db162f;
  flex-direction: column;
  justify-content: center;
  transition: width .6s;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: .375rem .375rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  align-items: flex-start;
  display: flex;
}

.media-body {
  flex: 1;
}

.list-group {
  border-radius: .3125rem;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  background-color: #db162f0d;
  text-decoration: none;
}

.list-group-item-action:active {
  color: #495057;
  background-color: #e9ecef;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #dee2e6;
  padding: .75rem 1.25rem;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #db162f;
  border-color: #db162f;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  border-top-width: 1px;
  margin-top: -1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: .3125rem;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: .3125rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  border-left-width: 1px;
  margin-left: -1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .3125rem;
  }

  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: .3125rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .3125rem;
  }

  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: .3125rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .3125rem;
  }

  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: .3125rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: .3125rem;
  }

  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: .3125rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    border-left-width: 1px;
    margin-left: -1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #720b18;
  background-color: #f5bec5;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #720b18;
  background-color: #f2a8b1;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #720b18;
  border-color: #720b18;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #111315;
  background-color: #c1c2c3;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #111315;
  background-color: #b4b5b6;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #111315;
  border-color: #111315;
}

.list-group-item-primary-2 {
  color: #1b1256;
  background-color: #c6c1e6;
}

.list-group-item-primary-2.list-group-item-action:hover, .list-group-item-primary-2.list-group-item-action:focus {
  color: #1b1256;
  background-color: #b5afdf;
}

.list-group-item-primary-2.list-group-item-action.active {
  color: #fff;
  background-color: #1b1256;
  border-color: #1b1256;
}

.list-group-item-primary-3 {
  color: #010710;
  background-color: #b8bcc0;
}

.list-group-item-primary-3.list-group-item-action:hover, .list-group-item-primary-3.list-group-item-action:focus {
  color: #010710;
  background-color: #aaafb4;
}

.list-group-item-primary-3.list-group-item-action.active {
  color: #fff;
  background-color: #010710;
  border-color: #010710;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .5;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  background-color: #0000;
  border: 0;
  padding: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border: 1px solid #0000001a;
  border-radius: .25rem;
  font-size: .875rem;
  overflow: hidden;
  box-shadow: 0 .25rem .75rem #0000001a;
}

.toast:not(:last-child) {
  margin-bottom: .75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  opacity: 1;
  display: block;
}

.toast.hide {
  display: none;
}

.toast-header {
  color: #6c757d;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border-bottom: 1px solid #0000000d;
  align-items: center;
  padding: .25rem .75rem;
  display: flex;
}

.toast-body {
  padding: .75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  z-index: 1050;
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modal-dialog {
  width: auto;
  pointer-events: none;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  max-height: calc(100% - 1rem);
  display: flex;
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - 1rem);
  align-items: center;
  display: flex;
}

.modal-dialog-centered:before {
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  content: "";
  display: block;
}

.modal-dialog-centered.modal-dialog-scrollable {
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable:before {
  content: none;
}

.modal-content {
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .625rem;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: calc(.625rem - 1px);
  border-top-right-radius: calc(.625rem - 1px);
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #e9ecef;
  border-bottom-left-radius: calc(.625rem - 1px);
  border-bottom-right-radius: calc(.625rem - 1px);
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.modal-footer > * {
  margin: .25rem;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }

  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  z-index: 1070;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
  margin: 0;
  font-family: Inter UI, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .arrow {
  width: .5rem;
  height: .4rem;
  display: block;
  position: absolute;
}

.tooltip .arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: .4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow:before, .bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-width: .4rem .25rem 0;
  border-top-color: #212529;
  top: 0;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 .4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  width: .4rem;
  height: .5rem;
  left: 0;
}

.bs-tooltip-right .arrow:before, .bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-width: .25rem .4rem .25rem 0;
  border-right-color: #212529;
  right: 0;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: .4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow:before, .bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-width: 0 .25rem .4rem;
  border-bottom-color: #212529;
  bottom: 0;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 .4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  width: .4rem;
  height: .5rem;
  right: 0;
}

.bs-tooltip-left .arrow:before, .bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-width: .25rem 0 .25rem .4rem;
  border-left-color: #212529;
  left: 0;
}

.tooltip-inner {
  max-width: 200px;
  color: #fff;
  text-align: center;
  background-color: #212529;
  border-radius: .3125rem;
  padding: .25rem .5rem;
}

.popover {
  z-index: 1060;
  max-width: 276px;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: .3125rem;
  font-family: Inter UI, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.popover .arrow {
  width: 0;
  height: 0;
  margin: 0 .3125rem;
  display: block;
  position: absolute;
}

.popover .arrow:before, .popover .arrow:after {
  content: "";
  border-style: solid;
  border-color: #0000;
  display: block;
  position: absolute;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: -1px;
}

.bs-popover-top > .arrow:before, .bs-popover-auto[x-placement^="top"] > .arrow:before {
  border-width: 0;
  border-top-color: #dee2e6;
  bottom: 0;
}

.bs-popover-top > .arrow:after, .bs-popover-auto[x-placement^="top"] > .arrow:after {
  border-width: 0;
  border-top-color: #0000;
  bottom: 1px;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  width: 0;
  height: 0;
  margin: .3125rem 0;
  left: -1px;
}

.bs-popover-right > .arrow:before, .bs-popover-auto[x-placement^="right"] > .arrow:before {
  border-width: 0;
  border-right-color: #dee2e6;
  left: 0;
}

.bs-popover-right > .arrow:after, .bs-popover-auto[x-placement^="right"] > .arrow:after {
  border-width: 0;
  border-right-color: #0000;
  left: 1px;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: -1px;
}

.bs-popover-bottom > .arrow:before, .bs-popover-auto[x-placement^="bottom"] > .arrow:before {
  border-width: 0;
  border-bottom-color: #dee2e6;
  top: 0;
}

.bs-popover-bottom > .arrow:after, .bs-popover-auto[x-placement^="bottom"] > .arrow:after {
  border-width: 0;
  border-bottom-color: #0000;
  top: 1px;
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[x-placement^="bottom"] .popover-header:before {
  width: 0;
  content: "";
  border-bottom: 1px solid #fff;
  margin-left: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  width: 0;
  height: 0;
  margin: .3125rem 0;
  right: -1px;
}

.bs-popover-left > .arrow:before, .bs-popover-auto[x-placement^="left"] > .arrow:before {
  border-width: 0;
  border-left-color: #dee2e6;
  right: 0;
}

.bs-popover-left > .arrow:after, .bs-popover-auto[x-placement^="left"] > .arrow:after {
  border-width: 0;
  border-left-color: #0000;
  right: 1px;
}

.popover-header {
  color: #212529;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
  border-top-left-radius: calc(.3125rem - 1px);
  border-top-right-radius: calc(.3125rem - 1px);
  margin-bottom: 0;
  padding: 1rem;
  font-size: 1rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #495057;
  padding: 1rem;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  width: 100%;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left), .active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right), .active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  justify-content: center;
  align-items: center;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 15;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators li {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid;
  border-right-color: #0000;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite;
  display: inline-block;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  opacity: 0;
  background-color: currentColor;
  border-radius: 50%;
  animation: spinner-grow .75s linear infinite;
  display: inline-block;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #db162f !important;
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
  background-color: #ad1125 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #212529 !important;
}

a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus {
  background-color: #0a0c0d !important;
}

.bg-primary-2 {
  background-color: #3423a6 !important;
}

a.bg-primary-2:hover, a.bg-primary-2:focus, button.bg-primary-2:hover, button.bg-primary-2:focus {
  background-color: #271a7c !important;
}

.bg-primary-3 {
  background-color: #010e1e !important;
}

a.bg-primary-3:hover, a.bg-primary-3:focus, button.bg-primary-3:hover, button.bg-primary-3:focus {
  background-color: #000 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #db162f !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-primary-2 {
  border-color: #3423a6 !important;
}

.border-primary-3 {
  border-color: #010e1e !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: .2rem !important;
}

.rounded {
  border-radius: .3125rem !important;
}

.rounded-top {
  border-top-left-radius: .3125rem !important;
  border-top-right-radius: .3125rem !important;
}

.rounded-right {
  border-top-right-radius: .3125rem !important;
  border-bottom-right-radius: .3125rem !important;
}

.rounded-bottom {
  border-bottom-left-radius: .3125rem !important;
  border-bottom-right-radius: .3125rem !important;
}

.rounded-left {
  border-top-left-radius: .3125rem !important;
  border-bottom-left-radius: .3125rem !important;
}

.rounded-lg {
  border-radius: .625rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive:before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-4by3:before {
  padding-top: 75%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  position: static;
  overflow: visible;
}

.shadow-sm {
  box-shadow: 0 .125rem .625rem #0000000d !important;
}

.shadow {
  box-shadow: 0 .625rem 1.5rem #00000014 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .375rem !important;
}

.mt-1, .my-1 {
  margin-top: .375rem !important;
}

.mr-1, .mx-1 {
  margin-right: .375rem !important;
}

.mb-1, .my-1 {
  margin-bottom: .375rem !important;
}

.ml-1, .mx-1 {
  margin-left: .375rem !important;
}

.m-2 {
  margin: .75rem !important;
}

.mt-2, .my-2 {
  margin-top: .75rem !important;
}

.mr-2, .mx-2 {
  margin-right: .75rem !important;
}

.mb-2, .my-2 {
  margin-bottom: .75rem !important;
}

.ml-2, .mx-2 {
  margin-left: .75rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.mt-3, .my-3 {
  margin-top: 1.5rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1.5rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1.5rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1.5rem !important;
}

.m-4 {
  margin: 2.25rem !important;
}

.mt-4, .my-4 {
  margin-top: 2.25rem !important;
}

.mr-4, .mx-4 {
  margin-right: 2.25rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 2.25rem !important;
}

.ml-4, .mx-4 {
  margin-left: 2.25rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5, .my-5 {
  margin-top: 3rem !important;
}

.mr-5, .mx-5 {
  margin-right: 3rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.ml-5, .mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 4.5rem !important;
}

.mt-6, .my-6 {
  margin-top: 4.5rem !important;
}

.mr-6, .mx-6 {
  margin-right: 4.5rem !important;
}

.mb-6, .my-6 {
  margin-bottom: 4.5rem !important;
}

.ml-6, .mx-6 {
  margin-left: 4.5rem !important;
}

.m-7 {
  margin: 6rem !important;
}

.mt-7, .my-7 {
  margin-top: 6rem !important;
}

.mr-7, .mx-7 {
  margin-right: 6rem !important;
}

.mb-7, .my-7 {
  margin-bottom: 6rem !important;
}

.ml-7, .mx-7 {
  margin-left: 6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .375rem !important;
}

.pt-1, .py-1 {
  padding-top: .375rem !important;
}

.pr-1, .px-1 {
  padding-right: .375rem !important;
}

.pb-1, .py-1 {
  padding-bottom: .375rem !important;
}

.pl-1, .px-1 {
  padding-left: .375rem !important;
}

.p-2 {
  padding: .75rem !important;
}

.pt-2, .py-2 {
  padding-top: .75rem !important;
}

.pr-2, .px-2 {
  padding-right: .75rem !important;
}

.pb-2, .py-2 {
  padding-bottom: .75rem !important;
}

.pl-2, .px-2 {
  padding-left: .75rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.pt-3, .py-3 {
  padding-top: 1.5rem !important;
}

.pr-3, .px-3 {
  padding-right: 1.5rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1.5rem !important;
}

.pl-3, .px-3 {
  padding-left: 1.5rem !important;
}

.p-4 {
  padding: 2.25rem !important;
}

.pt-4, .py-4 {
  padding-top: 2.25rem !important;
}

.pr-4, .px-4 {
  padding-right: 2.25rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 2.25rem !important;
}

.pl-4, .px-4 {
  padding-left: 2.25rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5, .py-5 {
  padding-top: 3rem !important;
}

.pr-5, .px-5 {
  padding-right: 3rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 3rem !important;
}

.pl-5, .px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 4.5rem !important;
}

.pt-6, .py-6 {
  padding-top: 4.5rem !important;
}

.pr-6, .px-6 {
  padding-right: 4.5rem !important;
}

.pb-6, .py-6 {
  padding-bottom: 4.5rem !important;
}

.pl-6, .px-6 {
  padding-left: 4.5rem !important;
}

.p-7 {
  padding: 6rem !important;
}

.pt-7, .py-7 {
  padding-top: 6rem !important;
}

.pr-7, .px-7 {
  padding-right: 6rem !important;
}

.pb-7, .py-7 {
  padding-bottom: 6rem !important;
}

.pl-7, .px-7 {
  padding-left: 6rem !important;
}

.m-n1 {
  margin: -.375rem !important;
}

.mt-n1, .my-n1 {
  margin-top: -.375rem !important;
}

.mr-n1, .mx-n1 {
  margin-right: -.375rem !important;
}

.mb-n1, .my-n1 {
  margin-bottom: -.375rem !important;
}

.ml-n1, .mx-n1 {
  margin-left: -.375rem !important;
}

.m-n2 {
  margin: -.75rem !important;
}

.mt-n2, .my-n2 {
  margin-top: -.75rem !important;
}

.mr-n2, .mx-n2 {
  margin-right: -.75rem !important;
}

.mb-n2, .my-n2 {
  margin-bottom: -.75rem !important;
}

.ml-n2, .mx-n2 {
  margin-left: -.75rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.mt-n3, .my-n3 {
  margin-top: -1.5rem !important;
}

.mr-n3, .mx-n3 {
  margin-right: -1.5rem !important;
}

.mb-n3, .my-n3 {
  margin-bottom: -1.5rem !important;
}

.ml-n3, .mx-n3 {
  margin-left: -1.5rem !important;
}

.m-n4 {
  margin: -2.25rem !important;
}

.mt-n4, .my-n4 {
  margin-top: -2.25rem !important;
}

.mr-n4, .mx-n4 {
  margin-right: -2.25rem !important;
}

.mb-n4, .my-n4 {
  margin-bottom: -2.25rem !important;
}

.ml-n4, .mx-n4 {
  margin-left: -2.25rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5, .my-n5 {
  margin-top: -3rem !important;
}

.mr-n5, .mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5, .my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5, .mx-n5 {
  margin-left: -3rem !important;
}

.m-n6 {
  margin: -4.5rem !important;
}

.mt-n6, .my-n6 {
  margin-top: -4.5rem !important;
}

.mr-n6, .mx-n6 {
  margin-right: -4.5rem !important;
}

.mb-n6, .my-n6 {
  margin-bottom: -4.5rem !important;
}

.ml-n6, .mx-n6 {
  margin-left: -4.5rem !important;
}

.m-n7 {
  margin: -6rem !important;
}

.mt-n7, .my-n7 {
  margin-top: -6rem !important;
}

.mr-n7, .mx-n7 {
  margin-right: -6rem !important;
}

.mb-n7, .my-n7 {
  margin-bottom: -6rem !important;
}

.ml-n7, .mx-n7 {
  margin-left: -6rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .375rem !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: .375rem !important;
  }

  .mr-sm-1, .mx-sm-1 {
    margin-right: .375rem !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: .375rem !important;
  }

  .ml-sm-1, .mx-sm-1 {
    margin-left: .375rem !important;
  }

  .m-sm-2 {
    margin: .75rem !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: .75rem !important;
  }

  .mr-sm-2, .mx-sm-2 {
    margin-right: .75rem !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: .75rem !important;
  }

  .ml-sm-2, .mx-sm-2 {
    margin-left: .75rem !important;
  }

  .m-sm-3 {
    margin: 1.5rem !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-3, .mx-sm-3 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-3, .mx-sm-3 {
    margin-left: 1.5rem !important;
  }

  .m-sm-4 {
    margin: 2.25rem !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 2.25rem !important;
  }

  .mr-sm-4, .mx-sm-4 {
    margin-right: 2.25rem !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 2.25rem !important;
  }

  .ml-sm-4, .mx-sm-4 {
    margin-left: 2.25rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .m-sm-6 {
    margin: 4.5rem !important;
  }

  .mt-sm-6, .my-sm-6 {
    margin-top: 4.5rem !important;
  }

  .mr-sm-6, .mx-sm-6 {
    margin-right: 4.5rem !important;
  }

  .mb-sm-6, .my-sm-6 {
    margin-bottom: 4.5rem !important;
  }

  .ml-sm-6, .mx-sm-6 {
    margin-left: 4.5rem !important;
  }

  .m-sm-7 {
    margin: 6rem !important;
  }

  .mt-sm-7, .my-sm-7 {
    margin-top: 6rem !important;
  }

  .mr-sm-7, .mx-sm-7 {
    margin-right: 6rem !important;
  }

  .mb-sm-7, .my-sm-7 {
    margin-bottom: 6rem !important;
  }

  .ml-sm-7, .mx-sm-7 {
    margin-left: 6rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .375rem !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-top: .375rem !important;
  }

  .pr-sm-1, .px-sm-1 {
    padding-right: .375rem !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-bottom: .375rem !important;
  }

  .pl-sm-1, .px-sm-1 {
    padding-left: .375rem !important;
  }

  .p-sm-2 {
    padding: .75rem !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-top: .75rem !important;
  }

  .pr-sm-2, .px-sm-2 {
    padding-right: .75rem !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-bottom: .75rem !important;
  }

  .pl-sm-2, .px-sm-2 {
    padding-left: .75rem !important;
  }

  .p-sm-3 {
    padding: 1.5rem !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 1.5rem !important;
  }

  .p-sm-4 {
    padding: 2.25rem !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-top: 2.25rem !important;
  }

  .pr-sm-4, .px-sm-4 {
    padding-right: 2.25rem !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-bottom: 2.25rem !important;
  }

  .pl-sm-4, .px-sm-4 {
    padding-left: 2.25rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important;
  }

  .p-sm-6 {
    padding: 4.5rem !important;
  }

  .pt-sm-6, .py-sm-6 {
    padding-top: 4.5rem !important;
  }

  .pr-sm-6, .px-sm-6 {
    padding-right: 4.5rem !important;
  }

  .pb-sm-6, .py-sm-6 {
    padding-bottom: 4.5rem !important;
  }

  .pl-sm-6, .px-sm-6 {
    padding-left: 4.5rem !important;
  }

  .p-sm-7 {
    padding: 6rem !important;
  }

  .pt-sm-7, .py-sm-7 {
    padding-top: 6rem !important;
  }

  .pr-sm-7, .px-sm-7 {
    padding-right: 6rem !important;
  }

  .pb-sm-7, .py-sm-7 {
    padding-bottom: 6rem !important;
  }

  .pl-sm-7, .px-sm-7 {
    padding-left: 6rem !important;
  }

  .m-sm-n1 {
    margin: -.375rem !important;
  }

  .mt-sm-n1, .my-sm-n1 {
    margin-top: -.375rem !important;
  }

  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -.375rem !important;
  }

  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -.375rem !important;
  }

  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -.375rem !important;
  }

  .m-sm-n2 {
    margin: -.75rem !important;
  }

  .mt-sm-n2, .my-sm-n2 {
    margin-top: -.75rem !important;
  }

  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -.75rem !important;
  }

  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -.75rem !important;
  }

  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -.75rem !important;
  }

  .m-sm-n3 {
    margin: -1.5rem !important;
  }

  .mt-sm-n3, .my-sm-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n4 {
    margin: -2.25rem !important;
  }

  .mt-sm-n4, .my-sm-n4 {
    margin-top: -2.25rem !important;
  }

  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -2.25rem !important;
  }

  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -2.25rem !important;
  }

  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -2.25rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5, .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-n6 {
    margin: -4.5rem !important;
  }

  .mt-sm-n6, .my-sm-n6 {
    margin-top: -4.5rem !important;
  }

  .mr-sm-n6, .mx-sm-n6 {
    margin-right: -4.5rem !important;
  }

  .mb-sm-n6, .my-sm-n6 {
    margin-bottom: -4.5rem !important;
  }

  .ml-sm-n6, .mx-sm-n6 {
    margin-left: -4.5rem !important;
  }

  .m-sm-n7 {
    margin: -6rem !important;
  }

  .mt-sm-n7, .my-sm-n7 {
    margin-top: -6rem !important;
  }

  .mr-sm-n7, .mx-sm-n7 {
    margin-right: -6rem !important;
  }

  .mb-sm-n7, .my-sm-n7 {
    margin-bottom: -6rem !important;
  }

  .ml-sm-n7, .mx-sm-n7 {
    margin-left: -6rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .375rem !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: .375rem !important;
  }

  .mr-md-1, .mx-md-1 {
    margin-right: .375rem !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: .375rem !important;
  }

  .ml-md-1, .mx-md-1 {
    margin-left: .375rem !important;
  }

  .m-md-2 {
    margin: .75rem !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: .75rem !important;
  }

  .mr-md-2, .mx-md-2 {
    margin-right: .75rem !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: .75rem !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: .75rem !important;
  }

  .m-md-3 {
    margin: 1.5rem !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 1.5rem !important;
  }

  .mr-md-3, .mx-md-3 {
    margin-right: 1.5rem !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 1.5rem !important;
  }

  .m-md-4 {
    margin: 2.25rem !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 2.25rem !important;
  }

  .mr-md-4, .mx-md-4 {
    margin-right: 2.25rem !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 2.25rem !important;
  }

  .ml-md-4, .mx-md-4 {
    margin-left: 2.25rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important;
  }

  .m-md-6 {
    margin: 4.5rem !important;
  }

  .mt-md-6, .my-md-6 {
    margin-top: 4.5rem !important;
  }

  .mr-md-6, .mx-md-6 {
    margin-right: 4.5rem !important;
  }

  .mb-md-6, .my-md-6 {
    margin-bottom: 4.5rem !important;
  }

  .ml-md-6, .mx-md-6 {
    margin-left: 4.5rem !important;
  }

  .m-md-7 {
    margin: 6rem !important;
  }

  .mt-md-7, .my-md-7 {
    margin-top: 6rem !important;
  }

  .mr-md-7, .mx-md-7 {
    margin-right: 6rem !important;
  }

  .mb-md-7, .my-md-7 {
    margin-bottom: 6rem !important;
  }

  .ml-md-7, .mx-md-7 {
    margin-left: 6rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .375rem !important;
  }

  .pt-md-1, .py-md-1 {
    padding-top: .375rem !important;
  }

  .pr-md-1, .px-md-1 {
    padding-right: .375rem !important;
  }

  .pb-md-1, .py-md-1 {
    padding-bottom: .375rem !important;
  }

  .pl-md-1, .px-md-1 {
    padding-left: .375rem !important;
  }

  .p-md-2 {
    padding: .75rem !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: .75rem !important;
  }

  .pr-md-2, .px-md-2 {
    padding-right: .75rem !important;
  }

  .pb-md-2, .py-md-2 {
    padding-bottom: .75rem !important;
  }

  .pl-md-2, .px-md-2 {
    padding-left: .75rem !important;
  }

  .p-md-3 {
    padding: 1.5rem !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 1.5rem !important;
  }

  .pr-md-3, .px-md-3 {
    padding-right: 1.5rem !important;
  }

  .pb-md-3, .py-md-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-3, .px-md-3 {
    padding-left: 1.5rem !important;
  }

  .p-md-4 {
    padding: 2.25rem !important;
  }

  .pt-md-4, .py-md-4 {
    padding-top: 2.25rem !important;
  }

  .pr-md-4, .px-md-4 {
    padding-right: 2.25rem !important;
  }

  .pb-md-4, .py-md-4 {
    padding-bottom: 2.25rem !important;
  }

  .pl-md-4, .px-md-4 {
    padding-left: 2.25rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important;
  }

  .p-md-6 {
    padding: 4.5rem !important;
  }

  .pt-md-6, .py-md-6 {
    padding-top: 4.5rem !important;
  }

  .pr-md-6, .px-md-6 {
    padding-right: 4.5rem !important;
  }

  .pb-md-6, .py-md-6 {
    padding-bottom: 4.5rem !important;
  }

  .pl-md-6, .px-md-6 {
    padding-left: 4.5rem !important;
  }

  .p-md-7 {
    padding: 6rem !important;
  }

  .pt-md-7, .py-md-7 {
    padding-top: 6rem !important;
  }

  .pr-md-7, .px-md-7 {
    padding-right: 6rem !important;
  }

  .pb-md-7, .py-md-7 {
    padding-bottom: 6rem !important;
  }

  .pl-md-7, .px-md-7 {
    padding-left: 6rem !important;
  }

  .m-md-n1 {
    margin: -.375rem !important;
  }

  .mt-md-n1, .my-md-n1 {
    margin-top: -.375rem !important;
  }

  .mr-md-n1, .mx-md-n1 {
    margin-right: -.375rem !important;
  }

  .mb-md-n1, .my-md-n1 {
    margin-bottom: -.375rem !important;
  }

  .ml-md-n1, .mx-md-n1 {
    margin-left: -.375rem !important;
  }

  .m-md-n2 {
    margin: -.75rem !important;
  }

  .mt-md-n2, .my-md-n2 {
    margin-top: -.75rem !important;
  }

  .mr-md-n2, .mx-md-n2 {
    margin-right: -.75rem !important;
  }

  .mb-md-n2, .my-md-n2 {
    margin-bottom: -.75rem !important;
  }

  .ml-md-n2, .mx-md-n2 {
    margin-left: -.75rem !important;
  }

  .m-md-n3 {
    margin: -1.5rem !important;
  }

  .mt-md-n3, .my-md-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n3, .mx-md-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n3, .my-md-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n3, .mx-md-n3 {
    margin-left: -1.5rem !important;
  }

  .m-md-n4 {
    margin: -2.25rem !important;
  }

  .mt-md-n4, .my-md-n4 {
    margin-top: -2.25rem !important;
  }

  .mr-md-n4, .mx-md-n4 {
    margin-right: -2.25rem !important;
  }

  .mb-md-n4, .my-md-n4 {
    margin-bottom: -2.25rem !important;
  }

  .ml-md-n4, .mx-md-n4 {
    margin-left: -2.25rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5, .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5, .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5, .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5, .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-n6 {
    margin: -4.5rem !important;
  }

  .mt-md-n6, .my-md-n6 {
    margin-top: -4.5rem !important;
  }

  .mr-md-n6, .mx-md-n6 {
    margin-right: -4.5rem !important;
  }

  .mb-md-n6, .my-md-n6 {
    margin-bottom: -4.5rem !important;
  }

  .ml-md-n6, .mx-md-n6 {
    margin-left: -4.5rem !important;
  }

  .m-md-n7 {
    margin: -6rem !important;
  }

  .mt-md-n7, .my-md-n7 {
    margin-top: -6rem !important;
  }

  .mr-md-n7, .mx-md-n7 {
    margin-right: -6rem !important;
  }

  .mb-md-n7, .my-md-n7 {
    margin-bottom: -6rem !important;
  }

  .ml-md-n7, .mx-md-n7 {
    margin-left: -6rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .375rem !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: .375rem !important;
  }

  .mr-lg-1, .mx-lg-1 {
    margin-right: .375rem !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: .375rem !important;
  }

  .ml-lg-1, .mx-lg-1 {
    margin-left: .375rem !important;
  }

  .m-lg-2 {
    margin: .75rem !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: .75rem !important;
  }

  .mr-lg-2, .mx-lg-2 {
    margin-right: .75rem !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: .75rem !important;
  }

  .ml-lg-2, .mx-lg-2 {
    margin-left: .75rem !important;
  }

  .m-lg-3 {
    margin: 1.5rem !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-3, .mx-lg-3 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-3, .mx-lg-3 {
    margin-left: 1.5rem !important;
  }

  .m-lg-4 {
    margin: 2.25rem !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 2.25rem !important;
  }

  .mr-lg-4, .mx-lg-4 {
    margin-right: 2.25rem !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 2.25rem !important;
  }

  .ml-lg-4, .mx-lg-4 {
    margin-left: 2.25rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .m-lg-6 {
    margin: 4.5rem !important;
  }

  .mt-lg-6, .my-lg-6 {
    margin-top: 4.5rem !important;
  }

  .mr-lg-6, .mx-lg-6 {
    margin-right: 4.5rem !important;
  }

  .mb-lg-6, .my-lg-6 {
    margin-bottom: 4.5rem !important;
  }

  .ml-lg-6, .mx-lg-6 {
    margin-left: 4.5rem !important;
  }

  .m-lg-7 {
    margin: 6rem !important;
  }

  .mt-lg-7, .my-lg-7 {
    margin-top: 6rem !important;
  }

  .mr-lg-7, .mx-lg-7 {
    margin-right: 6rem !important;
  }

  .mb-lg-7, .my-lg-7 {
    margin-bottom: 6rem !important;
  }

  .ml-lg-7, .mx-lg-7 {
    margin-left: 6rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .375rem !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-top: .375rem !important;
  }

  .pr-lg-1, .px-lg-1 {
    padding-right: .375rem !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-bottom: .375rem !important;
  }

  .pl-lg-1, .px-lg-1 {
    padding-left: .375rem !important;
  }

  .p-lg-2 {
    padding: .75rem !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-top: .75rem !important;
  }

  .pr-lg-2, .px-lg-2 {
    padding-right: .75rem !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-bottom: .75rem !important;
  }

  .pl-lg-2, .px-lg-2 {
    padding-left: .75rem !important;
  }

  .p-lg-3 {
    padding: 1.5rem !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-3, .px-lg-3 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-3, .px-lg-3 {
    padding-left: 1.5rem !important;
  }

  .p-lg-4 {
    padding: 2.25rem !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-top: 2.25rem !important;
  }

  .pr-lg-4, .px-lg-4 {
    padding-right: 2.25rem !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-bottom: 2.25rem !important;
  }

  .pl-lg-4, .px-lg-4 {
    padding-left: 2.25rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important;
  }

  .p-lg-6 {
    padding: 4.5rem !important;
  }

  .pt-lg-6, .py-lg-6 {
    padding-top: 4.5rem !important;
  }

  .pr-lg-6, .px-lg-6 {
    padding-right: 4.5rem !important;
  }

  .pb-lg-6, .py-lg-6 {
    padding-bottom: 4.5rem !important;
  }

  .pl-lg-6, .px-lg-6 {
    padding-left: 4.5rem !important;
  }

  .p-lg-7 {
    padding: 6rem !important;
  }

  .pt-lg-7, .py-lg-7 {
    padding-top: 6rem !important;
  }

  .pr-lg-7, .px-lg-7 {
    padding-right: 6rem !important;
  }

  .pb-lg-7, .py-lg-7 {
    padding-bottom: 6rem !important;
  }

  .pl-lg-7, .px-lg-7 {
    padding-left: 6rem !important;
  }

  .m-lg-n1 {
    margin: -.375rem !important;
  }

  .mt-lg-n1, .my-lg-n1 {
    margin-top: -.375rem !important;
  }

  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -.375rem !important;
  }

  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -.375rem !important;
  }

  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -.375rem !important;
  }

  .m-lg-n2 {
    margin: -.75rem !important;
  }

  .mt-lg-n2, .my-lg-n2 {
    margin-top: -.75rem !important;
  }

  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -.75rem !important;
  }

  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -.75rem !important;
  }

  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -.75rem !important;
  }

  .m-lg-n3 {
    margin: -1.5rem !important;
  }

  .mt-lg-n3, .my-lg-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n4 {
    margin: -2.25rem !important;
  }

  .mt-lg-n4, .my-lg-n4 {
    margin-top: -2.25rem !important;
  }

  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -2.25rem !important;
  }

  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -2.25rem !important;
  }

  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -2.25rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5, .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-n6 {
    margin: -4.5rem !important;
  }

  .mt-lg-n6, .my-lg-n6 {
    margin-top: -4.5rem !important;
  }

  .mr-lg-n6, .mx-lg-n6 {
    margin-right: -4.5rem !important;
  }

  .mb-lg-n6, .my-lg-n6 {
    margin-bottom: -4.5rem !important;
  }

  .ml-lg-n6, .mx-lg-n6 {
    margin-left: -4.5rem !important;
  }

  .m-lg-n7 {
    margin: -6rem !important;
  }

  .mt-lg-n7, .my-lg-n7 {
    margin-top: -6rem !important;
  }

  .mr-lg-n7, .mx-lg-n7 {
    margin-right: -6rem !important;
  }

  .mb-lg-n7, .my-lg-n7 {
    margin-bottom: -6rem !important;
  }

  .ml-lg-n7, .mx-lg-n7 {
    margin-left: -6rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .375rem !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: .375rem !important;
  }

  .mr-xl-1, .mx-xl-1 {
    margin-right: .375rem !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: .375rem !important;
  }

  .ml-xl-1, .mx-xl-1 {
    margin-left: .375rem !important;
  }

  .m-xl-2 {
    margin: .75rem !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: .75rem !important;
  }

  .mr-xl-2, .mx-xl-2 {
    margin-right: .75rem !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: .75rem !important;
  }

  .ml-xl-2, .mx-xl-2 {
    margin-left: .75rem !important;
  }

  .m-xl-3 {
    margin: 1.5rem !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-3, .mx-xl-3 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-3, .mx-xl-3 {
    margin-left: 1.5rem !important;
  }

  .m-xl-4 {
    margin: 2.25rem !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 2.25rem !important;
  }

  .mr-xl-4, .mx-xl-4 {
    margin-right: 2.25rem !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 2.25rem !important;
  }

  .ml-xl-4, .mx-xl-4 {
    margin-left: 2.25rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .m-xl-6 {
    margin: 4.5rem !important;
  }

  .mt-xl-6, .my-xl-6 {
    margin-top: 4.5rem !important;
  }

  .mr-xl-6, .mx-xl-6 {
    margin-right: 4.5rem !important;
  }

  .mb-xl-6, .my-xl-6 {
    margin-bottom: 4.5rem !important;
  }

  .ml-xl-6, .mx-xl-6 {
    margin-left: 4.5rem !important;
  }

  .m-xl-7 {
    margin: 6rem !important;
  }

  .mt-xl-7, .my-xl-7 {
    margin-top: 6rem !important;
  }

  .mr-xl-7, .mx-xl-7 {
    margin-right: 6rem !important;
  }

  .mb-xl-7, .my-xl-7 {
    margin-bottom: 6rem !important;
  }

  .ml-xl-7, .mx-xl-7 {
    margin-left: 6rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .375rem !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-top: .375rem !important;
  }

  .pr-xl-1, .px-xl-1 {
    padding-right: .375rem !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-bottom: .375rem !important;
  }

  .pl-xl-1, .px-xl-1 {
    padding-left: .375rem !important;
  }

  .p-xl-2 {
    padding: .75rem !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-top: .75rem !important;
  }

  .pr-xl-2, .px-xl-2 {
    padding-right: .75rem !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-bottom: .75rem !important;
  }

  .pl-xl-2, .px-xl-2 {
    padding-left: .75rem !important;
  }

  .p-xl-3 {
    padding: 1.5rem !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-3, .px-xl-3 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-3, .px-xl-3 {
    padding-left: 1.5rem !important;
  }

  .p-xl-4 {
    padding: 2.25rem !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-top: 2.25rem !important;
  }

  .pr-xl-4, .px-xl-4 {
    padding-right: 2.25rem !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-bottom: 2.25rem !important;
  }

  .pl-xl-4, .px-xl-4 {
    padding-left: 2.25rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important;
  }

  .p-xl-6 {
    padding: 4.5rem !important;
  }

  .pt-xl-6, .py-xl-6 {
    padding-top: 4.5rem !important;
  }

  .pr-xl-6, .px-xl-6 {
    padding-right: 4.5rem !important;
  }

  .pb-xl-6, .py-xl-6 {
    padding-bottom: 4.5rem !important;
  }

  .pl-xl-6, .px-xl-6 {
    padding-left: 4.5rem !important;
  }

  .p-xl-7 {
    padding: 6rem !important;
  }

  .pt-xl-7, .py-xl-7 {
    padding-top: 6rem !important;
  }

  .pr-xl-7, .px-xl-7 {
    padding-right: 6rem !important;
  }

  .pb-xl-7, .py-xl-7 {
    padding-bottom: 6rem !important;
  }

  .pl-xl-7, .px-xl-7 {
    padding-left: 6rem !important;
  }

  .m-xl-n1 {
    margin: -.375rem !important;
  }

  .mt-xl-n1, .my-xl-n1 {
    margin-top: -.375rem !important;
  }

  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -.375rem !important;
  }

  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -.375rem !important;
  }

  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -.375rem !important;
  }

  .m-xl-n2 {
    margin: -.75rem !important;
  }

  .mt-xl-n2, .my-xl-n2 {
    margin-top: -.75rem !important;
  }

  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -.75rem !important;
  }

  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -.75rem !important;
  }

  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -.75rem !important;
  }

  .m-xl-n3 {
    margin: -1.5rem !important;
  }

  .mt-xl-n3, .my-xl-n3 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n4 {
    margin: -2.25rem !important;
  }

  .mt-xl-n4, .my-xl-n4 {
    margin-top: -2.25rem !important;
  }

  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -2.25rem !important;
  }

  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -2.25rem !important;
  }

  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -2.25rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5, .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-n6 {
    margin: -4.5rem !important;
  }

  .mt-xl-n6, .my-xl-n6 {
    margin-top: -4.5rem !important;
  }

  .mr-xl-n6, .mx-xl-n6 {
    margin-right: -4.5rem !important;
  }

  .mb-xl-n6, .my-xl-n6 {
    margin-bottom: -4.5rem !important;
  }

  .ml-xl-n6, .mx-xl-n6 {
    margin-left: -4.5rem !important;
  }

  .m-xl-n7 {
    margin: -6rem !important;
  }

  .mt-xl-n7, .my-xl-n7 {
    margin-top: -6rem !important;
  }

  .mr-xl-n7, .mx-xl-n7 {
    margin-right: -6rem !important;
  }

  .mb-xl-n7, .my-xl-n7 {
    margin-bottom: -6rem !important;
  }

  .ml-xl-n7, .mx-xl-n7 {
    margin-left: -6rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link:after {
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: #0000;
  position: absolute;
  inset: 0;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #db162f !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #950f20 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #212529 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #000 !important;
}

.text-primary-2 {
  color: #3423a6 !important;
}

a.text-primary-2:hover, a.text-primary-2:focus {
  color: #201667 !important;
}

.text-primary-3 {
  color: #010e1e !important;
}

a.text-primary-3:hover, a.text-primary-3:focus {
  color: #000 !important;
}

.text-body {
  color: #495057 !important;
}

.text-muted {
  color: #adb5bd !important;
}

.text-black-50 {
  color: #00000080 !important;
}

.text-white-50 {
  color: #ffffff80 !important;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *, :before, :after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre, blockquote {
    page-break-inside: avoid;
    border: 1px solid #adb5bd;
  }

  thead {
    display: table-header-group;
  }

  tr, img {
    page-break-inside: avoid;
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body, .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered th, .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

section {
  position: relative;
}

.bg-image {
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  left: 0;
}

.bg-image:not([class*="absolute"]) {
  position: absolute;
}

.bg-image + .card-body {
  z-index: 1;
  position: relative;
}

img.bg-image {
  object-fit: cover;
}

.btn {
  letter-spacing: -.02em;
}

.btn.btn-sm, .btn-group-sm > .btn {
  letter-spacing: 0;
}

.btn > span {
  vertical-align: middle;
  display: inline-block;
}

.btn > .icon:first-child {
  margin-right: .5rem;
}

.btn > .icon:last-child {
  margin-left: .5rem;
}

.btn > .icon.d-block {
  height: 2rem;
  margin: 0 auto .75rem;
}

.btn.btn-sm > .icon, .btn-group-sm > .btn > .icon {
  height: 1rem;
}

.btn:focus {
  box-shadow: none !important;
}

[class*="-flex"] > .btn {
  flex-shrink: 0;
}

.btn.btn-primary *, .btn-outline-primary:hover *, .btn.btn-secondary *, .btn-outline-secondary:hover *, .btn.btn-success *, .btn-outline-success:hover *, .btn.btn-info *, .btn-outline-info:hover * {
  fill: #fff;
}

.btn.btn-warning *, .btn-outline-warning:hover * {
  fill: #212529;
}

.btn.btn-danger *, .btn-outline-danger:hover * {
  fill: #fff;
}

.btn.btn-light *, .btn-outline-light:hover * {
  fill: #212529;
}

.btn.btn-dark *, .btn-outline-dark:hover *, .btn.btn-primary-2 *, .btn-outline-primary-2:hover *, .btn.btn-primary-3 *, .btn-outline-primary-3:hover * {
  fill: #fff;
}

.btn-light {
  background: #f8f9fa;
  border-color: #dee2e6;
}

.btn-white {
  color: #db162f;
  background: #fff;
}

.btn-white > .icon path {
  fill: #db162f;
}

.btn-white:hover {
  color: #db162f;
  background: #ffffffe6;
}

.btn-outline-white {
  border-color: #dee2e6;
}

.btn-round {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.btn-round > .icon:only-child {
  margin: 0;
}

.btn-round > .icon.icon-sm {
  height: 1rem;
}

.btn-round.btn-sm, .btn-group-sm > .btn-round.btn {
  width: 2rem;
  height: 2rem;
}

.btn-round.btn-lg, .btn-group-lg > .btn-round.btn {
  width: 3.75rem;
  height: 3.75rem;
}

.btn-round.btn-xlg {
  width: 4.5rem;
  height: 4.5rem;
}

.btn-round.btn-lg > .icon, .btn-group-lg > .btn-round.btn > .icon, .btn-round.btn-xlg > .icon {
  height: 2rem;
}

.btn-loading:not(.btn-loading-animate) > span {
  vertical-align: baseline;
}

.btn-loading .icon {
  display: none;
}

.btn-loading.btn-loading-animate .icon {
  display: inline-block;
}

.btn-loading.btn-loading-animate .icon path {
  transform-origin: 12px;
  animation: spin .5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.btn.back-to-top {
  z-index: 1020;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
}

.card {
  margin-bottom: 1.5rem;
}

.card.card-sm.card-body {
  padding: 1.5rem;
}

.card[class*="bg-primary"]:not([class*="hover-bg-primary"]) {
  border-color: #0000;
}

.card[class*="hover-"] {
  transition: background-color .2s, box-shadow .2s, transform .2s;
}

.card > .bg-image {
  border-radius: .625rem;
}

@media (min-width: 576px) {
  .card.card-lg.card-body {
    padding: 3rem;
  }
}

@media (max-width: 767.98px) {
  .card {
    margin-bottom: .75rem;
  }
}

.card + .card {
  margin-top: 1.5rem;
}

.card.card-sm + .card-sm {
  margin-top: .75rem;
}

a.card {
  color: #495057;
  font-weight: 400;
  transition: all .1s;
}

a.card[class*="hover-shadow"]:hover {
  transform: translate3d(-4px, -4px, 0)rotateZ(-1deg);
}

@media (min-width: 768px) {
  .card-icon-2 {
    min-height: 22rem;
  }
}

.card-icon-3 {
  overflow: hidden;
}

.card-icon-3 h3 {
  margin-bottom: 1rem;
}

.card-icon-3 .badge {
  max-height: 1.5rem;
  position: absolute;
  top: 3rem;
  right: 3rem;
}

@media (min-width: 768px) {
  .card-icon-3 {
    min-height: 28rem;
  }

  .card-icon-3 .icon-round {
    position: relative;
    bottom: 1rem;
    right: 1rem;
    transform: scale(2.5);
  }
}

.card-customer-1 h4 {
  margin-bottom: .75rem;
}

.card-article-wide {
  overflow: hidden;
}

.card-article-wide .card-img-top {
  height: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .card-article-wide {
    min-height: 22.5rem;
  }

  .card-article-wide .card-img-top {
    border-radius: .625rem 0 0 .625rem;
  }
}

.dropdown-toggle {
  cursor: pointer;
  outline: none;
}

.dropdown-toggle:after {
  display: none !important;
}

.dropdown-toggle.btn > .icon:last-child {
  margin: 0;
  transform: rotateZ(-90deg);
}

.dropright .dropdown-toggle.btn > .icon:last-child {
  transform: rotateZ(180deg);
}

.dropup .dropdown-toggle.btn > .icon:last-child {
  transform: rotateZ(90deg);
}

.dropleft .dropdown-toggle:before {
  display: none;
}

.dropleft .dropdown-toggle.btn > .icon:first-child {
  transform: rotateZ(0deg);
}

.dropdown-item {
  font-weight: 600;
  transition: color .1s;
}

.dropdown-header {
  font-size: inherit;
  padding-bottom: 0;
}

.dropdown-menu {
  box-shadow: 0 .125rem .625rem #0000000d;
}

.dropdown-menu h6 {
  margin-bottom: 1rem;
}

.dropdown-menu.text-light .dropdown-item {
  color: #ffffff80;
}

.dropdown-menu.text-light .dropdown-item:hover, .dropdown-menu.text-light .dropdown-item:active {
  color: #fff;
}

[data-dropdown-content] .dropdown-item {
  padding-left: 0;
  padding-right: 0;
}

.dropdown-divider {
  margin: 0;
}

[data-toggle="dropdown-grid"] + .dropdown-menu {
  width: 100vw;
  max-width: none;
  box-shadow: none;
  background: none;
  border: none;
  border-radius: 0;
  margin-top: 0;
  padding: 0;
}

[data-toggle="dropdown-grid"] + .dropdown-menu .dropdown-menu {
  margin-top: 0;
  top: 0;
}

[data-toggle="dropdown-grid"] + .dropdown-menu .card {
  margin-bottom: 0;
}

[data-toggle="dropdown-grid"] + .dropdown-menu .card.card-sm {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.dropdown-menu.row {
  pointer-events: none;
}

.dropdown-menu.row.show {
  display: flex;
}

@media (min-width: 992px) {
  .dropdown-menu.row [class*="col"] {
    padding-left: 0;
    padding-right: 0;
  }
}

[data-dropdown-content] {
  pointer-events: all;
}

.dropdown-menu .dropdown {
  position: relative;
}

.dropdown-menu .dropdown .dropdown-toggle {
  padding-right: 3rem;
}

.dropdown-menu .dropdown .dropdown-toggle .icon {
  transition: opacity .2s, transform .2s;
  position: absolute;
  right: 0;
  transform: rotateZ(180deg);
}

.dropdown-menu .dropdown .dropdown-toggle:focus .icon {
  opacity: 1;
  transform: rotateZ(180deg)translateX(-4px);
}

@media (max-width: 991.98px) {
  .navbar-expand-lg .dropdown-menu, .navbar-expand-lg [data-dropdown-content] {
    left: 0 !important;
  }

  .navbar-expand-lg [data-toggle="dropdown-grid"] + .dropdown-menu {
    width: auto;
    position: relative;
    top: auto;
  }

  .navbar-expand-lg .dropdown-menu .dropdown .dropdown-toggle .icon {
    transform: rotateZ(270deg);
  }
}

body[data-dropdown-grid-hover="true"] [data-toggle="dropdown-grid"]:hover:before, body[data-dropdown-grid-hover="true"] [data-dropdown-content]:not(.container):hover:before, [data-toggle="dropdown-grid"][data-hover="true"]:hover:before {
  content: "";
  width: 100%;
  height: 3rem;
  position: absolute;
  top: 100%;
  left: 0;
}

body[data-dropdown-grid-hover="true"] [data-toggle="dropdown-grid"] + .dropdown-menu [data-toggle="dropdown-grid"]:hover:before, body[data-dropdown-grid-hover="true"] [data-dropdown-content]:not(.container) + .dropdown-menu [data-toggle="dropdown-grid"]:hover:before, [data-toggle="dropdown-grid"][data-hover="true"] + .dropdown-menu [data-toggle="dropdown-grid"]:hover:before {
  width: 140%;
  height: 110%;
  top: 0;
  left: -20%;
}

[data-dropdown-content]:not(.container):hover:before {
  width: calc(100% + 3rem);
  height: calc(100% + 3rem);
  padding: 3rem;
  top: -1.5rem;
  left: -1.5rem;
}

@media (min-width: 992px) {
  .dropdown-grid-menu {
    min-width: 10rem;
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: .3125rem;
    padding: 1.125rem;
    box-shadow: 0 .625rem 1.5rem #00000014;
  }
}

@media (max-width: 1199.98px) {
  .dropdown-grid-menu {
    border-left: 1px solid #dee2e6;
    padding-left: .75rem;
  }

  .navbar-dark .dropdown-grid-menu {
    border-color: #ffffff40;
  }
}

footer {
  padding: 6rem 0;
}

.row[class*="flex-"][class*="-column"] > [class*="col"][class*="my-"]:first-child {
  margin-top: 0 !important;
}

.row[class*="flex-"][class*="-column"] > [class*="col"][class*="my-"]:last-child {
  margin-bottom: 0 !important;
}

@media (max-width: 767px) {
  .row:not(.no-gutters) > [class*="col-6"]:nth-child(2n+1) {
    padding-right: .375rem;
  }

  .row:not(.no-gutters) > [class*="col-6"]:nth-child(2n) {
    padding-left: .375rem;
  }
}

@media (min-width: 576px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.row-tight {
  margin-left: -.375rem;
  margin-right: -.375rem;
}

.row-tight > [class*="col"] {
  padding: 0 .375rem;
}

img[class*="flex-"][class*="-grow-1"] {
  object-fit: cover;
}

img {
  max-width: 100%;
}

.navbar {
  width: 100%;
  border-bottom: 1px solid #010e1e14;
}

.navbar[data-overlay] {
  position: absolute;
}

.navbar[data-sticky="top"] {
  transition: all .2s;
}

.navbar[data-sticky="top"].scrolled.navbar-dark {
  background: #010e1e;
  border-bottom-color: #0000;
}

.navbar[data-sticky="top"].scrolled.navbar-light {
  background: #fff !important;
}

.navbar[data-overlay], .navbar[data-sticky="top"] {
  z-index: 999;
}

.navbar[data-overlay].scrolled, .navbar[data-sticky="top"].scrolled {
  z-index: 1030;
}

.navbar .dropdown-menu {
  z-index: 1060;
}

.navbar .nav-link {
  transition: color .2s;
}

.navbar-container {
  position: relative;
}

.navbar-light .navbar-toggler svg path {
  fill: #212529;
}

.navbar-dark {
  border-bottom: 1px solid #ffffff14;
}

.navbar-dark .navbar-toggler svg path, .navbar-dark.navbar-toggled-show .dropdown-toggle > svg.bg-dark * {
  fill: #fff;
}

.navbar-toggler {
  border: none;
  position: relative;
}

.navbar-toggler[aria-expanded="false"] .navbar-toggler-close {
  opacity: 0;
  transform: scale(0)rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-open {
  opacity: 0;
  transform: scale(0)rotate(-45deg);
}

.navbar-toggler-open, .navbar-toggler-close {
  opacity: 1;
  transition: opacity .2s, transform .2s;
  transform: scale(1);
}

.navbar-toggler-close {
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 992px) {
  .navbar {
    padding-left: 0;
    padding-right: 0;
  }

  .navbar .dropdown-menu {
    margin-top: 1rem;
  }
}

nav[data-overlay].navbar-toggled-show {
  background: #fff;
}

nav[data-overlay].navbar-toggled-show.navbar-dark {
  background: #010e1e;
}

nav[data-overlay].navbar-toggled-show.navbar-dark .dropdown-item {
  color: #ffffff80;
}

nav[data-overlay].navbar-toggled-show.navbar-dark .dropdown-item:hover {
  color: #fff;
}

@media (max-width: 991.98px) {
  .navbar-collapse {
    max-height: 100vh;
    overflow-y: scroll;
  }
}

@media (max-width: 991.98px) {
  .nav-item.dropdown .col-auto {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.nav-link.disabled {
  opacity: .25;
}

section .nav-link, footer .nav-link {
  transition: opacity .2s linear;
}

section .nav-link:not(.active):not(.disabled):not(:hover), footer .nav-link:not(.active):not(.disabled):not(:hover) {
  opacity: .5;
}

section .nav-link .icon, footer .nav-link .icon {
  transform: translate3d(0, 0, 0);
}

section .nav-link[aria-expanded="true"], footer .nav-link[aria-expanded="true"] {
  opacity: 1 !important;
}

section:not(.text-light) .nav-link, footer:not(.text-light) .nav-link {
  color: #212529;
}

.nav-link.btn.active {
  color: #fff;
  background: #db162f;
}

.nav-link.btn-light.active, .nav-link.btn-light:active {
  background: #f8f9fa !important;
  border-color: #dee2e6 !important;
}

.nav-link.btn-light:not(.active) {
  background: none;
  border-color: #0000;
}

.nav-link.btn-light .icon * {
  fill: #db162f;
}

.nav-link .icon-round {
  margin-bottom: .75rem;
  margin-left: auto;
  margin-right: auto;
}

.nav-link:not(.active):not(:active) .icon-round {
  background-color: #0000 !important;
}

.nav.flex-column {
  flex-flow: column;
}

.nav.flex-column .nav-link {
  padding-top: 0;
  padding-left: 0;
}

.nav-link[data-toggle="collapse"] > .icon {
  position: relative;
  right: .25rem;
  transform: rotateZ(-90deg);
}

img[data-inject-svg] {
  opacity: 0;
}

svg.icon * {
  fill: #db162f;
}

svg.icon[class*="bg-"] {
  background: none !important;
}

svg.bg-white * {
  fill: #fff;
}

svg.bg-primary * {
  fill: #db162f;
}

svg.bg-primary-alt * {
  fill: #fdf1f3;
}

svg.bg-secondary * {
  fill: #6c757d;
}

svg.bg-secondary-alt * {
  fill: #f6f7f7;
}

svg.bg-success * {
  fill: #28a745;
}

svg.bg-success-alt * {
  fill: #f2faf4;
}

svg.bg-info * {
  fill: #17a2b8;
}

svg.bg-info-alt * {
  fill: #f1f9fb;
}

svg.bg-warning * {
  fill: #ffc107;
}

svg.bg-warning-alt * {
  fill: #fffbf0;
}

svg.bg-danger * {
  fill: #dc3545;
}

svg.bg-danger-alt * {
  fill: #fdf3f4;
}

svg.bg-light * {
  fill: #f8f9fa;
}

svg.bg-light-alt * {
  fill: #fff;
}

svg.bg-dark * {
  fill: #212529;
}

svg.bg-dark-alt * {
  fill: #f2f2f2;
}

svg.bg-primary-2 * {
  fill: #3423a6;
}

svg.bg-primary-2-alt * {
  fill: #f3f2fa;
}

svg.bg-primary-3 * {
  fill: #010e1e;
}

svg.bg-primary-3-alt * {
  fill: #f0f1f2;
}

.text-light svg.icon:not([class*="bg-"]) * {
  fill: #fff;
}

.text-primary svg.icon:not([class*="bg-"]) * {
  fill: #db162f;
}

@font-face {
  font-family: Inter UI;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+0-5FF;
  src: url("Inter-UI-upright.var.bab7c391.woff2") format("woff2-variations"), url("Inter-UI-Regular.ace1c64c.woff2") format("woff2"), url("Inter-UI-Regular.716fa801.woff") format("woff");
}

@font-face {
  font-family: Inter UI;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  unicode-range: U+0-5FF;
  src: url("Inter-UI.var.8ffcdd4f.woff2") format("woff2-variations"), url("Inter-UI-Italic.971f9989.woff2") format("woff2"), url("Inter-UI-Italic.f471eaa4.woff") format("woff");
}

@font-face {
  font-family: Inter UI;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  unicode-range: U+0-5FF;
  src: url("Inter-UI-upright.var.bab7c391.woff2") format("woff2-variations"), url("Inter-UI-Medium.c8597681.woff2") format("woff2"), url("Inter-UI-Medium.105399a0.woff") format("woff");
}

@font-face {
  font-family: Inter UI;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  unicode-range: U+0-5FF;
  src: url("Inter-UI.var.8ffcdd4f.woff2") format("woff2-variations"), url("Inter-UI-MediumItalic.eff01856.woff2") format("woff2"), url("Inter-UI-MediumItalic.62b9a08c.woff") format("woff");
}

@font-face {
  font-family: Inter UI;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  unicode-range: U+0-5FF;
  src: url("Inter-UI-upright.var.bab7c391.woff2") format("woff2-variations"), url("Inter-UI-Bold.6c95cea8.woff2") format("woff2"), url("Inter-UI-Bold.28819779.woff") format("woff");
}

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4, .display-1, .display-2, .display-3, .display-4 {
  letter-spacing: -.03em;
}

h5, .h5 {
  line-height: 1.5;
}

@media (max-width: 1199.98px) {
  html {
    font-size: .9375rem;
  }
}

@media (max-width: 767.98px) {
  html {
    font-size: .875rem;
  }
}

@media (max-width: 991.98px) {
  .display-2 {
    font-size: 3.5rem;
  }
}

.display-1, .display-2, .display-3, .display-4 {
  color: #212529;
  margin-bottom: 1.5rem;
  line-height: 1;
}

.display-4 {
  line-height: 1.1;
}

@media (max-width: 1199.98px) {
  .display-3 {
    font-size: 3.5rem;
    line-height: 4rem;
  }
}

@media (max-width: 767.98px) {
  .display-4 {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

h5 {
  margin-bottom: .75rem;
}

h6 {
  line-height: 1.375rem;
}

a {
  color: #db162f;
  font-weight: 600;
}

a.lead, small, .text-small {
  font-weight: 600;
}

.text-small {
  font-size: .875rem;
  line-height: 1.25rem;
}

small {
  line-height: 1.1875rem;
  display: inline-block;
}

small.text-uppercase {
  letter-spacing: .08em;
}

strong {
  font-weight: 600;
}

.text-light a:not(.btn), .text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5, .text-light h6, .text-light .h1, .text-light .h2, .text-light .h3, .text-light .h4, .text-light .h5, .text-light .h6, .text-light .display-1, .text-light .display-2, .text-light .display-3, .text-light .display-4 {
  color: #fff;
}

.text-light .text-muted {
  color: #ffffffb3 !important;
}

.highlight {
  background: #3423a633;
  display: inline;
}

.alert {
  word-break: break-word;
}

.o-hidden {
  overflow: hidden;
}

.o-visible {
  overflow: visible;
}

@media (min-width: 992px) {
  .o-lg-visible {
    overflow: visible;
  }
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: .1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-30 {
  opacity: .3;
}

.opacity-40 {
  opacity: .4;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.opacity-80 {
  opacity: .8;
}

.opacity-90 {
  opacity: .9;
}

section {
  padding: 6rem 0;
}

.h-0 {
  height: 0;
}

.min-vh-10 {
  min-height: 10vh;
}

.min-vh-20 {
  min-height: 20vh;
}

.min-vh-30 {
  min-height: 30vh;
}

.min-vh-40 {
  min-height: 40vh;
}

.min-vh-50 {
  min-height: 50vh;
}

.min-vh-60 {
  min-height: 60vh;
}

.min-vh-70 {
  min-height: 70vh;
}

.min-vh-80 {
  min-height: 80vh;
}

.min-vh-90 {
  min-height: 90vh;
}

.min-vh-100 {
  min-height: 100vh;
}

@media (min-width: 768px) {
  .min-vh-md-10 {
    min-height: 10vh;
  }

  .min-vh-md-20 {
    min-height: 20vh;
  }

  .min-vh-md-30 {
    min-height: 30vh;
  }

  .min-vh-md-40 {
    min-height: 40vh;
  }

  .min-vh-md-50 {
    min-height: 50vh;
  }

  .min-vh-md-60 {
    min-height: 60vh;
  }

  .min-vh-md-70 {
    min-height: 70vh;
  }

  .min-vh-md-80 {
    min-height: 80vh;
  }

  .min-vh-md-90 {
    min-height: 90vh;
  }

  .min-vh-md-100 {
    min-height: 100vh;
  }
}

@media (min-width: 992px) {
  .min-vh-lg-10 {
    min-height: 10vh;
  }

  .min-vh-lg-20 {
    min-height: 20vh;
  }

  .min-vh-lg-30 {
    min-height: 30vh;
  }

  .min-vh-lg-40 {
    min-height: 40vh;
  }

  .min-vh-lg-50 {
    min-height: 50vh;
  }

  .min-vh-lg-60 {
    min-height: 60vh;
  }

  .min-vh-lg-70 {
    min-height: 70vh;
  }

  .min-vh-lg-80 {
    min-height: 80vh;
  }

  .min-vh-lg-90 {
    min-height: 90vh;
  }

  .min-vh-lg-100 {
    min-height: 100vh;
  }
}

.min-vw-10 {
  min-width: 10vw;
}

.min-vw-20 {
  min-width: 20vw;
}

.min-vw-30 {
  min-width: 30vw;
}

.min-vw-40 {
  min-width: 40vw;
}

.min-vw-50 {
  min-width: 50vw;
}

.min-vw-60 {
  min-width: 60vw;
}

.min-vw-70 {
  min-width: 70vw;
}

.min-vw-80 {
  min-width: 80vw;
}

.min-vw-90 {
  min-width: 90vw;
}

.min-vw-100 {
  min-width: 100vw;
}

.text-white p a {
  color: #fff;
}

.text-strikethrough {
  text-decoration: line-through;
}

.overlay .container {
  z-index: 1;
  position: relative;
}

.overlay:after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(#0000 0%, #000000a6 100%);
  position: absolute;
  bottom: 0;
  left: 0;
}

.overlay .divider {
  z-index: 1;
}

.overlay.card:after {
  border-radius: 0 0 .625rem .625rem;
}

.overlay > .position-absolute {
  z-index: 2;
}

.shadow-3d {
  box-shadow: .5rem .5rem #e9ecef80;
}

.bg-primary-alt {
  background: #fdf1f3;
}

.bg-primary-2-alt {
  background: #f3f2fa;
}

.bg-primary-3-alt {
  background: #f0f1f2;
}

img.rounded, div.rounded {
  border-radius: .625rem !important;
}

.rounded-sm, img.rounded-sm {
  border-radius: .3125rem !important;
}

.bg-primary-3.border-bottom {
  border-color: #ffffff14 !important;
}

.position-relative {
  z-index: 1;
}

.position-absolute[style] {
  z-index: 2;
}

.sticky-top {
  top: 1.5rem;
}

.top-0 {
  top: 0;
  bottom: auto;
}

.layer-1 {
  z-index: 1;
  position: relative;
}

.layer-2 {
  z-index: 2;
}

.layer-3 {
  z-index: 3;
}

.layer-4 {
  z-index: 4;
}

[class*="layer-"] {
  position: relative;
}

.blend-mode-multiply {
  mix-blend-mode: multiply;
}

.flip-x {
  transform: scaleX(-1);
}

.flip-y {
  transform: scaleY(-1);
}

.rotate-left {
  transform: rotateZ(-1deg);
}

.rotate-left.mobile-screenshot {
  transform: rotateZ(-5deg);
}

.rotate-right {
  transform: rotateZ(1deg);
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing="linear"], body[data-aos-easing="linear"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .25, .75, .75);
}

[data-aos][data-aos][data-aos-easing="ease"], body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing="ease-in"], body[data-aos-easing="ease-in"] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing="ease-out"], body[data-aos-easing="ease-out"] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-out"], body[data-aos-easing="ease-in-out"] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-back"], body[data-aos-easing="ease-in-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

[data-aos][data-aos][data-aos-easing="ease-out-back"], body[data-aos-easing="ease-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-back"], body[data-aos-easing="ease-in-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

[data-aos][data-aos][data-aos-easing="ease-in-sine"], body[data-aos-easing="ease-in-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.47, 0, .745, .715);
}

[data-aos][data-aos][data-aos-easing="ease-out-sine"], body[data-aos-easing="ease-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-sine"], body[data-aos-easing="ease-in-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
}

[data-aos][data-aos][data-aos-easing="ease-in-quad"], body[data-aos-easing="ease-in-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quad"], body[data-aos-easing="ease-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quad"], body[data-aos-easing="ease-in-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-cubic"], body[data-aos-easing="ease-in-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-cubic"], body[data-aos-easing="ease-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-cubic"], body[data-aos-easing="ease-in-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-quart"], body[data-aos-easing="ease-in-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quart"], body[data-aos-easing="ease-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quart"], body[data-aos-easing="ease-in-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos^="fade"][data-aos^="fade"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="fade"][data-aos^="fade"].aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

[data-aos="fade-up"] {
  transform: translate3d(0, 100px, 0);
}

[data-aos="fade-down"] {
  transform: translate3d(0, -100px, 0);
}

[data-aos="fade-right"] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos="fade-left"] {
  transform: translate3d(100px, 0, 0);
}

[data-aos="fade-up-right"] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos="fade-up-left"] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos="fade-down-right"] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos="fade-down-left"] {
  transform: translate3d(100px, -100px, 0);
}

[data-aos^="zoom"][data-aos^="zoom"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^="zoom"][data-aos^="zoom"].aos-animate {
  opacity: 1;
  transform: translateZ(0)scale(1);
}

[data-aos="zoom-in"] {
  transform: scale(.6);
}

[data-aos="zoom-in-up"] {
  transform: translate3d(0, 100px, 0)scale(.6);
}

[data-aos="zoom-in-down"] {
  transform: translate3d(0, -100px, 0)scale(.6);
}

[data-aos="zoom-in-right"] {
  transform: translate3d(-100px, 0, 0)scale(.6);
}

[data-aos="zoom-in-left"] {
  transform: translate3d(100px, 0, 0)scale(.6);
}

[data-aos="zoom-out"] {
  transform: scale(1.2);
}

[data-aos="zoom-out-up"] {
  transform: translate3d(0, 100px, 0)scale(1.2);
}

[data-aos="zoom-out-down"] {
  transform: translate3d(0, -100px, 0)scale(1.2);
}

[data-aos="zoom-out-right"] {
  transform: translate3d(-100px, 0, 0)scale(1.2);
}

[data-aos="zoom-out-left"] {
  transform: translate3d(100px, 0, 0)scale(1.2);
}

[data-aos^="slide"][data-aos^="slide"] {
  transition-property: transform;
}

[data-aos^="slide"][data-aos^="slide"].aos-animate {
  transform: translateZ(0);
}

[data-aos="slide-up"] {
  transform: translate3d(0, 100%, 0);
}

[data-aos="slide-down"] {
  transform: translate3d(0, -100%, 0);
}

[data-aos="slide-right"] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos="slide-left"] {
  transform: translate3d(100%, 0, 0);
}

[data-aos^="flip"][data-aos^="flip"] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos="flip-left"] {
  transform: perspective(2500px)rotateY(-100deg);
}

[data-aos="flip-left"].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos="flip-right"] {
  transform: perspective(2500px)rotateY(100deg);
}

[data-aos="flip-right"].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos="flip-up"] {
  transform: perspective(2500px)rotateX(-100deg);
}

[data-aos="flip-up"].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos="flip-down"] {
  transform: perspective(2500px)rotateX(100deg);
}

[data-aos="flip-down"].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

.accordion-panel-title {
  cursor: pointer;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.accordion-panel-title > .icon {
  transition: transform .1s;
}

.accordion-panel-title > * {
  transition: color .1s;
}

.accordion-panel-title .h6 + .icon {
  height: 1rem;
}

.accordion-panel-title[aria-expanded="true"] > .icon {
  transform: rotateZ(45deg);
}

.accordion-panel-title[aria-expanded="true"] > *, .accordion-panel-title:hover > * {
  color: #db162f;
}

.accordion-panel-title[aria-expanded="true"] > * path, .accordion-panel-title:hover > * path {
  fill: #db162f;
}

.article p.lead:first-child {
  margin-top: 4.5rem;
  margin-bottom: 3rem;
}

.article p + h4 {
  margin-top: 3rem;
}

.article p:not(:last-child) {
  margin-bottom: 1.5rem;
}

.article blockquote {
  margin: 3rem 0;
}

.article p + h5 {
  margin-top: 2.25rem;
}

.article ul {
  padding-left: 1.5rem;
}

.article ul > li {
  margin-bottom: .75rem;
}

article ol > li:not(:last-child) {
  margin-bottom: 3rem;
}

figure {
  text-align: center;
  margin: 3rem 0;
}

figcaption {
  color: #adb5bd;
  margin-top: .75rem;
  font-size: .875rem;
  font-weight: 600;
}

blockquote {
  border-radius: .625rem;
  padding: 3rem;
  position: relative;
}

blockquote .btn {
  position: absolute;
  bottom: .75rem;
  right: .75rem;
}

.comments {
  padding-left: 0;
  list-style: none;
}

.comments .comment:not(:last-child) {
  margin-bottom: 1.5rem;
}

.comments .comment .comments {
  margin-top: 1.5rem;
}

.comments .comment .comment {
  border-left: 4px solid #dee2e6;
  padding-left: 2.25rem;
}

.list-articles {
  margin-bottom: 0;
}

.list-articles > li:not(:last-child) {
  margin-bottom: .75rem;
}

.col-lg-3 .list-articles img {
  min-height: 3.75rem;
  object-fit: cover;
}

.col-lg-3 .list-articles img.rounded {
  border-radius: .3125rem !important;
}

.col-lg-4 .list-articles img {
  min-height: 6.75rem;
  object-fit: cover;
}

.col-lg-4 .list-articles img.rounded {
  border-radius: .3125rem !important;
}

@media (min-width: 768px) {
  .article-portfolio > :not(figure) {
    max-width: 36rem;
    margin-left: auto;
    margin-right: auto;
  }

  .article-portfolio figure {
    margin: 6rem 0;
  }
}

.avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  flex-shrink: 0;
}

.avatar.avatar-sm {
  width: 2.25rem;
  height: 2.25rem;
}

.avatar.avatar-lg {
  width: 4.5rem;
  height: 4.5rem;
}

.avatar.avatar-xlg {
  width: 7.5rem;
  height: 7.5rem;
}

@media (min-width: 992px) {
  .avatar.avatar-lg {
    width: 4.5rem;
    height: 4.5rem;
  }
}

.avatars {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.avatars > li {
  position: relative;
}

.avatars > li:not(:last-child) {
  margin-right: -.75rem;
}

.avatars .avatar {
  border: 2px solid #fff;
}

.avatar-author {
  display: flex;
}

.avatar-author h6 {
  margin-bottom: 0;
}

.badge {
  text-transform: uppercase;
  letter-spacing: .02em;
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}

.badge .icon {
  height: .75rem;
  vertical-align: top;
}

.badge.position-absolute {
  bottom: auto;
}

.badge.badge-top {
  position: absolute;
  top: -.75rem;
  bottom: auto;
}

.breadcrumb {
  font-weight: 600;
  display: inline-flex;
}

.breadcrumb-item {
  height: 1.5rem;
  align-items: center;
  font-size: .875rem;
  display: flex;
}

.breadcrumb-item a {
  transition: opacity .1s linear;
}

.breadcrumb-item a:not(:hover) {
  opacity: .75;
}

.breadcrumb-item + .breadcrumb-item:before {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  opacity: .2;
  background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.4444 8.41358C14.7776 8.2281 15.1875 8.46907 15.1875 8.85048V15.1495C15.1875 15.5309 14.7776 15.7719 14.4444 15.5864L8.78505 12.4369C8.44258 12.2463 8.44258 11.7537 8.78505 11.5631L14.4444 8.41358Z' fill='%23495057'/></svg>");
  margin-right: .25rem;
  padding: 0;
  transform: rotateZ(180deg);
}

.breadcrumb-item .icon {
  height: 1rem;
  opacity: .2;
  margin-left: .25rem;
  transform: rotateZ(180deg);
}

.text-light .breadcrumb-item.active {
  color: #ffffff80;
}

.text-light .breadcrumb-item + .breadcrumb-item:before {
  background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.4444 8.41358C14.7776 8.2281 15.1875 8.46907 15.1875 8.85048V15.1495C15.1875 15.5309 14.7776 15.7719 14.4444 15.5864L8.78505 12.4369C8.44258 12.2463 8.44258 11.7537 8.78505 11.5631L14.4444 8.41358Z' fill='%23FFFFFF'/></svg>");
}

.text-light .breadcrumb {
  background: #ffffff1a;
}

@media (max-width: 767.98px) {
  .breadcrumb-item + .breadcrumb-item:before {
    margin-left: .5rem;
    position: relative;
    top: 3px;
  }
}

.decoration:not(.position-relative) {
  position: absolute;
}

.decoration svg {
  background: none !important;
}

.decoration.top {
  top: 0;
}

.decoration.left {
  left: 0;
}

.decoration.right {
  right: 0;
}

.decoration.middle-y {
  top: 50%;
}

.decoration.middle-x {
  left: 50%;
}

.decoration.bottom {
  bottom: 0;
}

.decoration.scale-2 svg {
  transform: scale(2);
}

.decoration.scale-3 svg {
  transform: scale(3);
}

.decoration.scale-4 svg {
  transform: scale(4);
}

.decoration.scale-5 svg {
  transform: scale(5);
}

.decoration-check {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.decoration-check .decoration {
  max-width: 250px;
  position: relative;
}

.decoration-check .icon {
  height: 4rem;
  position: absolute;
}

.mobile-screenshot {
  position: relative;
}

.mobile-screenshot > img:first-child {
  padding: 7%;
}

.mobile-screenshot > img:last-child {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.divider {
  position: relative;
}

.divider svg {
  height: 6vw;
  background: none !important;
}

.divider svg:not([class*="bg"]) path {
  fill: #fff;
}

.divider:not(:first-child) {
  top: 1px;
}

.divider:first-child {
  bottom: 1px;
}

.divider.divider-vertical {
  height: 100vh;
  width: 6rem;
  top: 0;
  left: 1px;
}

.divider.divider-vertical svg {
  height: 100vh;
  width: 6rem;
}

section.has-divider {
  padding: 0;
}

section.has-divider .container {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

body.compensate-for-scrollbar {
  overflow: hidden;
}

.fancybox-active {
  height: auto;
}

.fancybox-is-hidden {
  visibility: hidden;
  margin: 0;
  top: -9999px;
  left: -9999px;
  position: absolute !important;
}

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  width: 100%;
  z-index: 99992;
  outline: none;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateZ(0);
}

.fancybox-container * {
  box-sizing: border-box;
}

.fancybox-bg, .fancybox-inner, .fancybox-outer, .fancybox-stage {
  position: absolute;
  inset: 0;
}

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.fancybox-bg {
  opacity: 0;
  transition-duration: inherit;
  background: #1e1e1e;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(.47, 0, .74, .71);
}

.fancybox-is-open .fancybox-bg {
  opacity: .9;
  transition-timing-function: cubic-bezier(.22, .61, .36, 1);
}

.fancybox-caption, .fancybox-infobar, .fancybox-navigation .fancybox-button, .fancybox-toolbar {
  direction: ltr;
  opacity: 0;
  visibility: hidden;
  z-index: 99997;
  transition: opacity .25s, visibility 0s .25s;
  position: absolute;
}

.fancybox-show-caption .fancybox-caption, .fancybox-show-infobar .fancybox-infobar, .fancybox-show-nav .fancybox-navigation .fancybox-button, .fancybox-show-toolbar .fancybox-toolbar {
  opacity: 1;
  visibility: visible;
  transition: opacity .25s, visibility;
}

.fancybox-infobar {
  color: #ccc;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  pointer-events: none;
  -webkit-touch-callout: none;
  user-select: none;
  padding: 0 10px;
  font-size: 13px;
  line-height: 44px;
  top: 0;
  left: 0;
}

.fancybox-toolbar {
  top: 0;
  right: 0;
}

.fancybox-stage {
  direction: ltr;
  z-index: 99994;
  overflow: visible;
  transform: translateZ(0);
}

.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  white-space: normal;
  width: 100%;
  z-index: 99994;
  outline: none;
  padding: 44px;
  transition-property: transform, opacity;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: auto;
}

.fancybox-slide:before {
  content: "";
  height: 100%;
  vertical-align: middle;
  width: 0;
  font-size: 0;
  display: inline-block;
}

.fancybox-is-sliding .fancybox-slide, .fancybox-slide--current, .fancybox-slide--next, .fancybox-slide--previous {
  display: block;
}

.fancybox-slide--image {
  padding: 44px 0;
  overflow: hidden;
}

.fancybox-slide--image:before {
  display: none;
}

.fancybox-slide--html {
  padding: 6px;
}

.fancybox-content {
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  text-align: left;
  vertical-align: middle;
  background: #fff;
  margin: 0;
  padding: 44px;
  display: inline-block;
  position: relative;
  overflow: auto;
}

.fancybox-slide--image .fancybox-content {
  -webkit-backface-visibility: hidden;
  max-width: none;
  transform-origin: 0 0;
  user-select: none;
  z-index: 99995;
  background: 0 0 / 100% 100% no-repeat;
  padding: 0;
  transition-property: transform, opacity;
  animation-timing-function: cubic-bezier(.5, 0, .14, 1);
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
}

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in;
}

.fancybox-can-pan .fancybox-content, .fancybox-can-swipe .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-container [data-selectable="true"] {
  cursor: text;
}

.fancybox-image, .fancybox-spaceball {
  height: 100%;
  max-height: none;
  max-width: none;
  user-select: none;
  width: 100%;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--iframe .fancybox-content, .fancybox-slide--map .fancybox-content, .fancybox-slide--pdf .fancybox-content, .fancybox-slide--video .fancybox-content {
  height: 100%;
  width: 100%;
  padding: 0;
  overflow: visible;
}

.fancybox-slide--video .fancybox-content {
  background: #000;
}

.fancybox-slide--map .fancybox-content {
  background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
}

.fancybox-iframe, .fancybox-video {
  height: 100%;
  width: 100%;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  overflow: hidden;
}

.fancybox-iframe {
  position: absolute;
  top: 0;
  left: 0;
}

.fancybox-error {
  cursor: default;
  max-width: 400px;
  width: 100%;
  background: #fff;
  padding: 40px;
}

.fancybox-error p {
  color: #444;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 20px;
}

.fancybox-button {
  box-shadow: none;
  cursor: pointer;
  height: 44px;
  vertical-align: top;
  visibility: inherit;
  width: 44px;
  background: #1e1e1e99;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 10px;
  transition: color .2s;
  display: inline-block;
  position: relative;
}

.fancybox-button, .fancybox-button:link, .fancybox-button:visited {
  color: #ccc;
}

.fancybox-button:hover {
  color: #fff;
}

.fancybox-button:focus {
  outline: none;
}

.fancybox-button.fancybox-focus {
  outline: 1px dotted;
}

.fancybox-button[disabled], .fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none;
}

.fancybox-button div {
  height: 100%;
}

.fancybox-button svg {
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
  overflow: visible;
}

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0;
}

.fancybox-button--fsenter svg:nth-child(2), .fancybox-button--fsexit svg:nth-child(1), .fancybox-button--pause svg:nth-child(1), .fancybox-button--play svg:nth-child(2) {
  display: none;
}

.fancybox-progress {
  height: 2px;
  transform-origin: 0;
  z-index: 99998;
  background: #ff5268;
  transition-property: transform;
  transition-timing-function: linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: scaleX(0);
}

.fancybox-close-small {
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  z-index: 401;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 8px;
  position: absolute;
  top: -44px;
  right: -12px;
}

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1;
}

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  top: 0;
  right: 0;
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden;
}

.fancybox-is-scaling .fancybox-close-small, .fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none;
}

.fancybox-navigation .fancybox-button {
  height: 100px;
  opacity: 0;
  width: 70px;
  background-clip: content-box;
  position: absolute;
  top: calc(50% - 50px);
}

.fancybox-navigation .fancybox-button div {
  padding: 7px;
}

.fancybox-navigation .fancybox-button--arrow_left {
  padding: 31px 26px 31px 6px;
  left: 0;
}

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
}

.fancybox-caption {
  color: #eee;
  text-align: center;
  z-index: 99996;
  padding: 25px 44px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  bottom: 0;
  left: 0;
  right: 0;
}

.fancybox-caption:before {
  content: "";
  pointer-events: none;
  z-index: -1;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAD6CAQAAADKSeXYAAAAYklEQVQoz42RwQ3AMAgDjfcfup8WoRykfBAK5mQHKSz5rbXJPis1hjiV3CIqgG0hLZPkVkA4p4x5oR1bVeDrdCLrW2Q0D5bcwY3TGMHbdw3mPRuOtaspYP1w//G1OIcW148H0DMCqI/3mMMAAAAASUVORK5CYII=");
  background-repeat: repeat-x;
  background-size: contain;
  display: block;
  position: absolute;
  inset: -44px 0 0;
}

.fancybox-caption a, .fancybox-caption a:link, .fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none;
}

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline;
}

.fancybox-loading {
  height: 50px;
  opacity: .7;
  width: 50px;
  z-index: 99999;
  background: none;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  margin: -25px 0 0 -25px;
  padding: 0;
  animation: a 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes a {
  to {
    transform: rotate(1turn);
  }
}

.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, .25, 1);
}

.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translateZ(0);
}

.fancybox-fx-fade.fancybox-slide--next, .fancybox-fx-fade.fancybox-slide--previous {
  opacity: 0;
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(.5, .5, .5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scaleX(1);
}

.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  transform: rotate(-1turn);
}

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  transform: rotate(1turn);
}

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  transform: rotate(0deg);
}

.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0)translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0)translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scaleX(1)translateZ(0);
}

.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0)scale(.1)skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0)scale(.1)skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  transform: translateZ(0)scale(1);
}

@media (max-height: 576px) {
  .fancybox-caption {
    padding: 12px;
  }

  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px;
  }

  .fancybox-slide--image {
    padding: 6px 0;
  }

  .fancybox-close-small {
    right: -6px;
  }

  .fancybox-slide--image .fancybox-close-small {
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    width: 36px;
    background: #4e4e4e;
    padding: 6px;
    top: 0;
    right: 0;
  }
}

.fancybox-share {
  max-width: 90%;
  text-align: center;
  background: #f4f4f4;
  border-radius: 3px;
  padding: 30px;
}

.fancybox-share h1 {
  color: #222;
  margin: 0 0 20px;
  font-size: 35px;
  font-weight: 700;
}

.fancybox-share p {
  margin: 0;
  padding: 0;
}

.fancybox-share__button {
  min-width: 130px;
  user-select: none;
  white-space: nowrap;
  border: 0;
  border-radius: 3px;
  margin: 0 5px 10px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  text-decoration: none;
  transition: all .2s;
  display: inline-block;
}

.fancybox-share__button:link, .fancybox-share__button:visited {
  color: #fff;
}

.fancybox-share__button:hover {
  text-decoration: none;
}

.fancybox-share__button--fb {
  background: #3b5998;
}

.fancybox-share__button--fb:hover {
  background: #344e86;
}

.fancybox-share__button--pt {
  background: #bd081d;
}

.fancybox-share__button--pt:hover {
  background: #aa0719;
}

.fancybox-share__button--tw {
  background: #1da1f2;
}

.fancybox-share__button--tw:hover {
  background: #0d95e8;
}

.fancybox-share__button svg {
  height: 25px;
  vertical-align: middle;
  width: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
}

.fancybox-share__button svg path {
  fill: #fff;
}

.fancybox-share__input {
  color: #5d5b5b;
  width: 100%;
  background: none;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  outline: none;
  margin: 10px 0 0;
  padding: 10px 15px;
  font-size: 14px;
}

.fancybox-thumbs {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-tap-highlight-color: transparent;
  width: 212px;
  z-index: 99995;
  background: #ddd;
  margin: 0;
  padding: 2px 2px 4px;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}

.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}

.fancybox-thumbs__list {
  height: 100%;
  white-space: nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 0;
  list-style: none;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px #0000004d;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}

.fancybox-thumbs__list a {
  backface-visibility: hidden;
  cursor: pointer;
  float: left;
  height: 75px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  -webkit-tap-highlight-color: transparent;
  width: 100px;
  background-color: #0000001a;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  outline: none;
  margin: 2px;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.fancybox-thumbs__list a:before {
  content: "";
  opacity: 0;
  z-index: 99991;
  border: 6px solid #ff5268;
  transition: all .2s cubic-bezier(.25, .46, .45, .94);
  position: absolute;
  inset: 0;
}

.fancybox-thumbs__list a:focus:before {
  opacity: .5;
}

.fancybox-thumbs__list a.fancybox-thumbs-active:before {
  opacity: 1;
}

@media (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px;
  }

  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }

  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px);
  }
}

.flatpickr-calendar {
  opacity: 0;
  text-align: center;
  visibility: hidden;
  direction: ltr;
  width: 307.875px;
  box-sizing: border-box;
  touch-action: manipulation;
  background: #fff;
  border: 0;
  border-radius: 5px;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  animation: none;
  display: none;
  position: absolute;
  box-shadow: 1px 0 #e6e6e6, -1px 0 #e6e6e6, 0 1px #e6e6e6, 0 -1px #e6e6e6, 0 3px 13px #00000014;
}

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  z-index: 99999;
  display: inline-block;
}

.flatpickr-calendar.animate.open {
  animation: fpFadeInDown .3s cubic-bezier(.23, 1, .32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
  pointer-events: none;
  content: "";
  height: 0;
  width: 0;
  border: solid #0000;
  display: block;
  position: absolute;
  left: 22px;
}

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.arrowRight:before, .flatpickr-calendar.rightMost:after, .flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before, .flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  color: #000000e6;
  fill: #000000e6;
  height: 34px;
  text-align: center;
  user-select: none;
  background: none;
  flex: 1;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  cursor: pointer;
  height: 34px;
  z-index: 3;
  color: #000000e6;
  fill: #000000e6;
  padding: 10px;
  text-decoration: none;
  position: absolute;
  top: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  fill: inherit;
  transition: fill .1s;
}

.numInputWrapper {
  height: auto;
  position: relative;
}

.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numInputWrapper span {
  width: 14px;
  height: 50%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid #39393926;
  padding: 0 4px 0 2px;
  line-height: 50%;
  position: absolute;
  right: 0;
}

.numInputWrapper span:hover {
  background: #0000001a;
}

.numInputWrapper span:active {
  background: #0003;
}

.numInputWrapper span:after {
  content: "";
  display: block;
  position: absolute;
}

.numInputWrapper span.arrowUp {
  border-bottom: 0;
  top: 0;
}

.numInputWrapper span.arrowUp:after {
  border-bottom: 4px solid #39393999;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-top: 4px solid #39393999;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: #00000080;
}

.numInputWrapper:hover {
  background: #0000000d;
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  color: inherit;
  width: 75%;
  height: 34px;
  text-align: center;
  padding: 7.48px 0 0;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
  position: absolute;
  left: 12.5%;
  transform: translate3d(0, 0, 0);
}

.flatpickr-current-month span.cur-month {
  color: inherit;
  margin-left: .5ch;
  padding: 0;
  font-family: inherit;
  font-weight: 700;
  display: inline-block;
}

.flatpickr-current-month span.cur-month:hover {
  background: #0000000d;
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch�;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #000000e6;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #000000e6;
}

.flatpickr-current-month input.cur-year {
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  vertical-align: initial;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0 0 0 .5ch;
  display: inline-block;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  color: #00000080;
  pointer-events: none;
  background: none;
  font-size: 100%;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  height: auto;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  vertical-align: initial;
  width: auto;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  margin: -1px 0 0;
  padding: 0 0 0 .5ch;
  position: relative;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: #0000000d;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #0000;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  text-align: center;
  width: 100%;
  height: 28px;
  background: none;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  flex: 1;
  display: flex;
}

span.flatpickr-weekday {
  cursor: default;
  color: #0000008a;
  text-align: center;
  background: none;
  flex: 1;
  margin: 0;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  display: block;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0;
}

.flatpickr-days {
  width: 307.875px;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  box-sizing: border-box;
  opacity: 1;
  outline: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0;
  display: flex;
  transform: translate3d(0, 0, 0);
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 #e6e6e6;
}

.flatpickr-day {
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  width: 14.2857%;
  max-width: 39px;
  height: 39px;
  text-align: center;
  background: none;
  border: 1px solid #0000;
  border-radius: 150px;
  flex-basis: 14.2857%;
  justify-content: center;
  margin: 0;
  font-weight: 400;
  line-height: 39px;
  display: inline-block;
  position: relative;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  background: #e6e6e6;
  border-color: #e6e6e6;
  outline: 0;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  color: #fff;
  background: #959ea9;
  border-color: #959ea9;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  box-shadow: none;
  color: #fff;
  background: #569ff7;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: #3939394d;
  cursor: default;
  background: none;
  border-color: #0000;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: #3939391a;
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 #569ff7, 5px 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  width: 100%;
  max-width: none;
  color: #3939394d;
  cursor: default;
  background: none;
  border: none;
  display: block;
}

.flatpickr-innerContainer {
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
}

.flatpickr-rContainer {
  box-sizing: border-box;
  padding: 0;
  display: inline-block;
}

.flatpickr-time {
  text-align: center;
  height: 0;
  max-height: 40px;
  box-sizing: border-box;
  outline: 0;
  line-height: 40px;
  display: flex;
  overflow: hidden;
}

.flatpickr-time:after {
  content: "";
  clear: both;
  display: table;
}

.flatpickr-time .numInputWrapper {
  width: 40%;
  height: 40px;
  float: left;
  flex: 1;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  box-shadow: none;
  text-align: center;
  height: inherit;
  line-height: inherit;
  color: #393939;
  box-sizing: border-box;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  position: relative;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  border: 0;
  outline: 0;
}

.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  width: 2%;
  user-select: none;
  align-self: center;
  font-weight: bold;
}

.flatpickr-time .flatpickr-am-pm {
  width: 18%;
  cursor: pointer;
  text-align: center;
  outline: 0;
  font-weight: 400;
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.flatpickr-months {
  margin: .75rem 0;
}

.flatpickr-months .flatpickr-month, .flatpickr-months .flatpickr-next-month, .flatpickr-months .flatpickr-prev-month {
  height: auto;
  position: relative;
}

.flatpickr-months .flatpickr-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg, .flatpickr-months .flatpickr-prev-month:hover svg {
  fill: #db162f;
}

.flatpickr-months .flatpickr-month {
  color: #495057;
}

.flatpickr-current-month {
  padding: 13px 0 0;
  font-size: 115%;
}

.flatpickr-current-month span.cur-month {
  font-weight: 600;
}

.flatpickr-current-month span.cur-month:hover, .numInputWrapper:hover {
  background: #db162f26;
}

.flatpickr-day {
  color: #495057;
  border-radius: .3125rem;
  font-weight: 500;
}

.flatpickr-day.today {
  border-color: #db162f;
}

.flatpickr-day.today:hover {
  background: #db162f;
  border-color: #db162f;
}

.flatpickr-day:hover {
  background: #db162f1a;
  border-color: #0000;
}

span.flatpickr-weekday {
  color: #495057;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #db162f;
  border-color: #db162f;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 #db162f;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: .3125rem 0 0 .3125rem;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 .3125rem .3125rem 0;
}

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.flickity-slider {
  width: 100%;
  height: 100%;
  position: absolute;
}

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.flickity-button {
  color: #333;
  background: #ffffffbf;
  border: none;
  position: absolute;
}

.flickity-button:hover {
  cursor: pointer;
  background: #fff;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19f;
}

.flickity-button:active {
  opacity: .6;
}

.flickity-button:disabled {
  opacity: .3;
  cursor: auto;
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

.flickity-prev-next-button {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  left: 10px;
  right: auto;
}

.flickity-prev-next-button .flickity-button-icon {
  width: 60%;
  height: 60%;
  position: absolute;
  top: 20%;
  left: 20%;
}

.flickity-page-dots {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  line-height: 1;
  list-style: none;
  position: absolute;
  bottom: -25px;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  width: 10px;
  height: 10px;
  opacity: .25;
  cursor: pointer;
  background: #333;
  border-radius: 50%;
  margin: 0 8px;
  display: inline-block;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

[data-flickity]:not(.flickity-enabled) {
  opacity: 0;
}

.flickity-page-dots {
  bottom: -2.125rem;
}

.flickity-page-dots .dot {
  width: 6px;
  height: 6px;
  opacity: .2;
  background: #495057;
  border-radius: 10rem;
  margin: 0 5px;
  transition: transform .2s linear, opacity .2s linear;
}

.flickity-page-dots .dot.is-selected {
  transform: scale(1.33333);
}

.flickity-prev-next-button {
  width: 3rem;
  height: 3rem;
  background: #fff;
  border: 1px solid #dee2e6;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .2s linear, box-shadow .2s linear;
  display: flex;
}

.flickity-prev-next-button:after {
  content: "";
  width: 2rem;
  height: 2rem;
  background-image: url("data:image/svg+xml;utf8,<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14.8517 18.1136C14.9506 18.2121 15 18.3402 15 18.4977C15 18.6356 14.9506 18.7538 14.8517 18.8523C14.7331 18.9508 14.6045 19 14.4661 19C14.3277 19 14.209 18.9508 14.1102 18.8523L8.14831 12.8841C8.04944 12.7659 8 12.6379 8 12.5C8 12.3621 8.04944 12.2341 8.14831 12.1159L14.1102 6.14773C14.209 6.04924 14.3277 6 14.4661 6C14.6045 6 14.7331 6.04924 14.8517 6.14773C14.9506 6.24621 15 6.37424 15 6.53182C15 6.6697 14.9506 6.78788 14.8517 6.88636L9.42373 12.5L14.8517 18.1136Z' fill='%23DB162F'/></svg>");
  background-size: cover;
  display: block;
}

.flickity-prev-next-button.previous {
  left: -4.5rem;
}

.flickity-prev-next-button.next {
  right: -4.5rem;
}

.flickity-prev-next-button.next:after {
  transform: rotateZ(180deg);
}

.flickity-prev-next-button:hover {
  box-shadow: 0 .125rem .625rem #0000000d;
}

.flickity-button-icon {
  display: none;
}

@media (max-width: 1199.98px) {
  .flickity-prev-next-button {
    display: none;
  }
}

[data-flickity].controls-light .flickity-page-dots .dot {
  background: #fff;
}

.controls-inside:not(:hover) .flickity-prev-next-button {
  opacity: 0;
}

.controls-inside .flickity-page-dots {
  position: absolute;
  bottom: 1.5rem;
}

.controls-inside .flickity-prev-next-button.previous, .arrows-inside .flickity-prev-next-button.previous {
  left: 1.5rem;
}

.controls-inside .flickity-prev-next-button.next, .arrows-inside .flickity-prev-next-button.next {
  right: 1.5rem;
}

.carousel-cell {
  width: 100%;
}

.highlight-selected .carousel-cell {
  transition: opacity .2s, transform .2s;
}

.highlight-selected .carousel-cell:not(.is-selected) {
  opacity: .25;
  transform: scale(.9);
}

.slider-phone > img {
  pointer-events: none;
  display: none;
}

@media (min-width: 768px) {
  .slider-phone {
    position: relative;
  }

  .slider-phone > img {
    z-index: 1;
    display: block;
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  .slider-phone .carousel-cell img {
    transition: opacity .2s, transform .2s;
    transform: scale(.86)translate3d(0, -4.55%, 0);
  }

  .slider-phone .carousel-cell:not(.is-selected) img {
    opacity: .1;
    transform: scale(.8);
  }

  .slider-phone .flickity-viewport {
    overflow: visible;
  }
}

@media (min-width: 992px) {
  .slider-phone .carousel-cell img {
    border-radius: 1.5rem;
  }
}

.form-control {
  transition: background-color .2s, border-color .2s, opacity .2s;
}

.form-control:disabled {
  opacity: .5;
}

.form-control[readonly] {
  background: #fff;
}

.form-group label {
  color: #adb5bd;
  font-size: .875rem;
  font-weight: 600;
}

.form-group label.custom-control-label {
  color: #495057;
}

.form-group label.custom-control-label:before, .form-group label.custom-control-label:after {
  top: .125rem;
}

.form-group.rounded {
  border-radius: .3125rem !important;
}

.text-light .form-group > label {
  color: #ffffffbf;
}

@media (max-width: 767.98px) {
  .form-control {
    font-size: 16px;
  }
}

.input-group .icon * {
  fill: #212529 !important;
}

.input-group .input-group-text {
  font-weight: 600;
}

.form-group {
  position: relative;
}

.form-group .custom-select {
  cursor: pointer;
  background: none;
}

.form-group .custom-select + .icon {
  pointer-events: none;
  position: absolute;
  top: .5rem;
  right: .75rem;
  transform: rotateZ(-90deg);
}

.form-group .custom-select + .icon path {
  fill: #212529;
}

.form-group .custom-select.custom-select-lg + .icon {
  top: 1.2rem;
}

.custom-control label {
  cursor: pointer;
}

.custom-radio {
  min-height: 1.3125rem;
  line-height: 1.3125rem;
}

.custom-control-label {
  user-select: none;
}

.custom-control-label:before {
  border: 1px solid #dee2e6;
}

.custom-control-label.text-small:before, .custom-control-label.text-small:after {
  top: .125rem;
}

.custom-control-input:checked ~ .custom-control-label:before {
  border-color: #db162f;
}

.custom-control {
  padding-left: 1.875rem;
}

.custom-control .custom-control-label:before, .custom-control .custom-control-label:after {
  left: -1.875rem;
}

.custom-switch {
  padding-left: 3.75rem;
}

.custom-switch .custom-control-label:before {
  height: 1.5rem;
  top: 0;
  left: -3.75rem;
}

.custom-switch .custom-control-label:after {
  background-color: #fff;
  border: 1px solid #dee2e6;
  top: .25rem;
  left: -3.375rem;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  border-color: #fff;
  transform: translateX(1.25rem);
}

.custom-range-labels {
  justify-content: space-between;
  margin-top: .25rem;
  display: flex;
}

.custom-range-labels > span {
  min-width: 13px;
  text-align: center;
  color: #adb5bd;
  font-size: .75rem;
  font-weight: 600;
  display: block;
}

.custom-range-labels > span:before {
  content: "";
  height: .125rem;
  width: 2px;
  height: .4rem;
  opacity: .5;
  background: #adb5bd;
  margin: 0 auto .5rem;
  display: block;
}

.custom-range-labels > span:first-child:before, .custom-range-labels > span:last-child:before {
  height: .6rem;
}

[data-recaptcha]:not([data-size="invisible"]) {
  margin-bottom: .75rem;
}

.grecaptcha-badge {
  display: none;
}

.header-inner {
  padding: 0;
  display: block;
}

.header-inner .container {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

@media (min-width: 768px) {
  .header-desktop-app .row:last-of-type {
    z-index: 2;
    margin-bottom: -22.5rem;
    position: relative;
  }

  .header-desktop-app + section {
    padding-top: 22.5rem;
  }
}

[class*="hover-"] {
  transition: background-color .1s, color .1s, box-shadow .1s, transform .1s;
}

.hover-bg-primary:hover {
  color: #fff;
  background: #db162f;
  border-color: #db162f;
}

.hover-bg-primary:hover h1, .hover-bg-primary:hover h2, .hover-bg-primary:hover h3, .hover-bg-primary:hover h5, .hover-bg-primary:hover h6, .hover-bg-primary:hover h5, .hover-bg-primary:hover .h1, .hover-bg-primary:hover .h2, .hover-bg-primary:hover .h3, .hover-bg-primary:hover .h4, .hover-bg-primary:hover .h5, .hover-bg-primary:hover .h6 {
  color: #fff;
}

.hover-bg-primary:hover .icon-round {
  background: #ffffff1a !important;
}

.hover-bg-primary:hover .icon * {
  fill: #fff;
}

.hover-bg-primary-2:hover {
  color: #fff;
  background: #3423a6;
  border-color: #3423a6;
}

.hover-bg-primary-2:hover h1, .hover-bg-primary-2:hover h2, .hover-bg-primary-2:hover h3, .hover-bg-primary-2:hover h5, .hover-bg-primary-2:hover h6, .hover-bg-primary-2:hover h5, .hover-bg-primary-2:hover .h1, .hover-bg-primary-2:hover .h2, .hover-bg-primary-2:hover .h3, .hover-bg-primary-2:hover .h4, .hover-bg-primary-2:hover .h5, .hover-bg-primary-2:hover .h6 {
  color: #fff;
}

.hover-bg-primary-2:hover .icon-round {
  background: #ffffff1a !important;
}

.hover-bg-primary-2:hover .icon * {
  fill: #fff;
}

.hover-bg-primary-3:hover {
  color: #fff;
  background: #010e1e;
  border-color: #010e1e;
}

.hover-bg-primary-3:hover h1, .hover-bg-primary-3:hover h2, .hover-bg-primary-3:hover h3, .hover-bg-primary-3:hover h5, .hover-bg-primary-3:hover h6, .hover-bg-primary-3:hover h5, .hover-bg-primary-3:hover .h1, .hover-bg-primary-3:hover .h2, .hover-bg-primary-3:hover .h3, .hover-bg-primary-3:hover .h4, .hover-bg-primary-3:hover .h5, .hover-bg-primary-3:hover .h6 {
  color: #fff;
}

.hover-bg-primary-3:hover .icon-round {
  background: #ffffff1a !important;
}

.hover-bg-primary-3:hover .icon * {
  fill: #fff;
}

.hover-shadow-3d {
  box-shadow: 0 0 #0000;
}

.hover-shadow-3d:hover {
  transform: translate3d(-4px, -4px, 0)rotateZ(-1deg);
  box-shadow: .5rem .5rem #e9ecef80;
}

.hover-shadow-sm {
  box-shadow: 0 0 #0000;
}

.hover-shadow-sm:hover {
  box-shadow: 0 .125rem .625rem #0000000d;
}

.hover-arrow:after {
  content: "->";
  margin-left: .375rem;
  transition: transform .2s;
  display: inline-block;
}

.hover-arrow:hover:after {
  transform: translate3d(6px, 0, 0);
}

.icon {
  width: auto;
  height: 1.5rem;
  flex-shrink: 0;
}

.icon.icon-md {
  height: 2.25rem;
}

.icon.icon-lg {
  height: 3rem;
}

.icon.icon-sm {
  height: .75rem;
}

.icon-round {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icon-round > .icon {
  height: 2.25rem;
}

.icon-round.bg-primary {
  background: #db162f1a !important;
}

.icon-round.bg-primary-2 {
  background: #3423a61a !important;
}

.icon-round.bg-primary-3 {
  background: #010e1e1a !important;
}

.icon-round.bg-white {
  background: #ffffff1a !important;
}

.icon-round.icon-round-xs {
  width: 2.25rem;
  height: 2.25rem;
}

.icon-round.icon-round-xs > .icon {
  height: 1.5rem;
}

.icon-round.icon-round-sm {
  width: 3rem;
  height: 3rem;
}

.icon-round.icon-round-sm > .icon {
  height: 1.5rem;
}

.icon-round.icon-round-xs {
  width: 1.5rem;
  height: 1.5rem;
}

.icon-round.icon-round-xs > .icon {
  height: 1rem;
}

@media (min-width: 992px) {
  .icon-round.icon-round-lg {
    width: 6rem;
    height: 6rem;
  }

  .icon-round.icon-round-lg > .icon {
    height: 3rem;
  }
}

.image-collage {
  justify-content: center;
  display: flex;
  position: relative;
}

.image-collage > a {
  z-index: 1;
  transition: transform .1s linear;
  position: relative;
}

.image-collage > a:nth-child(1), .image-collage > a:nth-child(3) {
  z-index: 2;
  position: absolute;
}

.image-collage > a:nth-child(1) img, .image-collage > a:nth-child(3) img {
  max-width: 345px;
}

.image-collage > a:nth-child(1) {
  top: -3rem;
  right: 0;
  transform: rotateZ(10deg);
}

.image-collage > a:nth-child(1):hover {
  transform: rotateZ(12deg)translate3d(0, -10px, 0);
}

.image-collage > a:nth-child(3) {
  bottom: -3rem;
  left: 0;
  transform: rotateZ(-10deg);
}

.image-collage > a:nth-child(3):hover {
  transform: rotateZ(-12deg)translate3d(0, -10px, 0);
}

.image-collage > a:nth-child(2) img {
  max-width: 384px;
}

.image-collage > a:nth-child(2):hover {
  transform: rotateZ(-2deg)translate3d(0, -10px, 0);
}

@media (max-width: 991.98px) {
  .image-collage > a:nth-child(2) img {
    max-width: 100%;
  }
}

.irs {
  -webkit-touch-callout: none;
  user-select: none;
  font-family: Arial, sans-serif;
  font-size: 12px;
  display: block;
  position: relative;
}

.irs-line {
  display: block;
  position: relative;
  overflow: hidden;
  outline: none !important;
}

.irs-bar {
  width: 0;
  display: block;
  position: absolute;
  left: 0;
}

.irs-shadow {
  width: 0;
  display: none;
  position: absolute;
  left: 0;
}

.irs-handle {
  box-sizing: border-box;
  cursor: default;
  z-index: 1;
  display: block;
  position: absolute;
}

.irs-handle.type_last {
  z-index: 2;
}

.irs-min, .irs-max {
  cursor: default;
  display: block;
  position: absolute;
}

.irs-min {
  left: 0;
}

.irs-max {
  right: 0;
}

.irs-from, .irs-to, .irs-single {
  cursor: default;
  white-space: nowrap;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.irs-grid {
  width: 100%;
  height: 20px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  width: 1px;
  height: 8px;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
}

.irs-grid-pol.small {
  height: 4px;
}

.irs-grid-text {
  white-space: nowrap;
  text-align: center;
  color: #000;
  padding: 0 3px;
  font-size: 9px;
  line-height: 9px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.irs-disable-mask {
  width: 102%;
  height: 100%;
  cursor: default;
  z-index: 2;
  background: none;
  display: block;
  position: absolute;
  top: 0;
  left: -1%;
}

.lt-ie9 .irs-disable-mask {
  filter: alpha(opacity= 0);
  cursor: not-allowed;
  background: #000;
}

.irs-disabled {
  opacity: .4;
}

.irs-hidden-input {
  overflow: hidden;
  width: 0 !important;
  height: 0 !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: #0000 !important;
  outline: none !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  display: block !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.irs--flat {
  height: 40px;
}

.irs--flat.irs-with-grid {
  height: 60px;
}

.irs--flat .irs-line {
  height: 12px;
  background-color: #e1e4e9;
  border-radius: 4px;
  top: 25px;
}

.irs--flat .irs-bar {
  height: 12px;
  background-color: #ed5565;
  top: 25px;
}

.irs--flat .irs-bar--single {
  border-radius: 4px 0 0 4px;
}

.irs--flat .irs-shadow {
  height: 1px;
  background-color: #e1e4e9;
  bottom: 16px;
}

.irs--flat .irs-handle {
  width: 16px;
  height: 18px;
  background-color: #0000;
  top: 22px;
}

.irs--flat .irs-handle > i:first-child {
  width: 2px;
  height: 100%;
  background-color: #da4453;
  margin-left: -1px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.irs--flat .irs-handle.state_hover > i:first-child, .irs--flat .irs-handle:hover > i:first-child {
  background-color: #a43540;
}

.irs--flat .irs-min, .irs--flat .irs-max {
  color: #999;
  text-shadow: none;
  background-color: #e1e4e9;
  border-radius: 4px;
  padding: 1px 3px;
  font-size: 10px;
  line-height: 1.333;
  top: 0;
}

.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
  color: #fff;
  text-shadow: none;
  background-color: #ed5565;
  border-radius: 4px;
  padding: 1px 5px;
  font-size: 10px;
  line-height: 1.333;
}

.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
  content: "";
  width: 0;
  height: 0;
  border: 3px solid #0000;
  border-top-color: #ed5565;
  margin-left: -3px;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  overflow: hidden;
}

.irs--flat .irs-grid-pol {
  background-color: #e1e4e9;
}

.irs--flat .irs-grid-text {
  color: #999;
}

.irs--big {
  height: 55px;
}

.irs--big.irs-with-grid {
  height: 70px;
}

.irs--big .irs-line {
  height: 12px;
  background: linear-gradient(#ddd -50%, #fff 150%);
  border: 1px solid #ccc;
  border-radius: 12px;
  top: 33px;
}

.irs--big .irs-bar {
  height: 12px;
  background: linear-gradient(#fff 0%, #428bca 30%, #b9d4ec 100%);
  border: 1px solid #428bca;
  top: 33px;
  box-shadow: inset 0 0 1px 1px #ffffff80;
}

.irs--big .irs-bar--single {
  border-radius: 12px 0 0 12px;
}

.irs--big .irs-shadow {
  height: 1px;
  background-color: #428bca80;
  bottom: 16px;
}

.irs--big .irs-handle {
  width: 30px;
  height: 30px;
  background: linear-gradient(#fff 0%, #b4b9be 30%, #fff 100%);
  border: 1px solid #0000004d;
  border-radius: 30px;
  top: 25px;
  box-shadow: 1px 1px 2px #0003, inset 0 0 3px 1px #fff;
}

.irs--big .irs-handle.state_hover, .irs--big .irs-handle:hover {
  background: linear-gradient(#fff 0%, #919ba5 30%, #fff 100%);
  border-color: #00000073;
}

.irs--big .irs-min, .irs--big .irs-max {
  color: #fff;
  text-shadow: none;
  background-color: #9f9f9f;
  border-radius: 3px;
  padding: 1px 5px;
  top: 0;
}

.irs--big .irs-from, .irs--big .irs-to, .irs--big .irs-single {
  color: #fff;
  text-shadow: none;
  background: linear-gradient(#428bca 0%, #3071a9 100%);
  border-radius: 3px;
  padding: 1px 5px;
}

.irs--big .irs-grid-pol {
  background-color: #428bca;
}

.irs--big .irs-grid-text {
  color: #428bca;
}

.irs--modern, .irs--modern.irs-with-grid {
  height: 55px;
}

.irs--modern .irs-line {
  height: 5px;
  background: linear-gradient(#e0e4ea 0%, #d1d6e0 100%);
  border: 1px solid #a3adc1;
  border-bottom-width: 0;
  border-radius: 5px;
  top: 25px;
}

.irs--modern .irs-bar {
  height: 5px;
  background: linear-gradient(#20b426 0%, #18891d 100%);
  top: 25px;
}

.irs--modern .irs-bar--single {
  border-radius: 5px 0 0 5px;
}

.irs--modern .irs-shadow {
  height: 1px;
  background-color: #d1d6e080;
  bottom: 21px;
}

.irs--modern .irs-handle {
  width: 12px;
  height: 13px;
  border: 1px solid #a3adc1;
  border-top-width: 0;
  border-radius: 0 0 3px 3px;
  top: 37px;
  box-shadow: 1px 1px 1px #0000001a;
}

.irs--modern .irs-handle > i:nth-child(1) {
  width: 6px;
  height: 6px;
  background: #fff;
  border: 1px solid #a3adc1;
  display: block;
  position: absolute;
  top: -4px;
  left: 1px;
  transform: rotate(45deg);
}

.irs--modern .irs-handle > i:nth-child(2) {
  box-sizing: border-box;
  width: 10px;
  height: 12px;
  background: linear-gradient(#fff 0%, #e9e6e6 100%);
  border-radius: 0 0 3px 3px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.irs--modern .irs-handle > i:nth-child(3) {
  box-sizing: border-box;
  width: 4px;
  height: 5px;
  border-left: 1px solid #a3adc1;
  border-right: 1px solid #a3adc1;
  display: block;
  position: absolute;
  top: 3px;
  left: 3px;
}

.irs--modern .irs-handle.state_hover, .irs--modern .irs-handle:hover {
  background: linear-gradient(#fff 0%, #919ba5 30%, #fff 100%);
  border-color: #7685a2;
}

.irs--modern .irs-handle.state_hover > i:nth-child(1), .irs--modern .irs-handle:hover > i:nth-child(1) {
  border-color: #7685a2;
}

.irs--modern .irs-handle.state_hover > i:nth-child(3), .irs--modern .irs-handle:hover > i:nth-child(3) {
  border-color: #48536a;
}

.irs--modern .irs-min, .irs--modern .irs-max {
  text-shadow: none;
  color: #fff;
  background-color: #d1d6e0;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 10px;
  line-height: 1.333;
  top: 0;
}

.irs--modern .irs-from, .irs--modern .irs-to, .irs--modern .irs-single {
  text-shadow: none;
  color: #fff;
  background-color: #20b426;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 10px;
  line-height: 1.333;
}

.irs--modern .irs-from:before, .irs--modern .irs-to:before, .irs--modern .irs-single:before {
  content: "";
  width: 0;
  height: 0;
  border: 3px solid #0000;
  border-top-color: #20b426;
  margin-left: -3px;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  overflow: hidden;
}

.irs--modern .irs-grid {
  height: 25px;
}

.irs--modern .irs-grid-pol {
  background-color: #dedede;
}

.irs--modern .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--sharp {
  height: 50px;
  font-size: 12px;
  line-height: 1;
}

.irs--sharp.irs-with-grid {
  height: 57px;
}

.irs--sharp .irs-line {
  height: 2px;
  background-color: #000;
  border-radius: 2px;
  top: 30px;
}

.irs--sharp .irs-bar {
  height: 2px;
  background-color: #ee22fa;
  top: 30px;
}

.irs--sharp .irs-bar--single {
  border-radius: 2px 0 0 2px;
}

.irs--sharp .irs-shadow {
  height: 1px;
  background-color: #00000080;
  bottom: 21px;
}

.irs--sharp .irs-handle {
  width: 10px;
  height: 10px;
  background-color: #a804b2;
  top: 25px;
}

.irs--sharp .irs-handle > i:first-child {
  width: 0;
  height: 0;
  border: 5px solid #0000;
  border-top-color: #a804b2;
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
}

.irs--sharp .irs-handle.state_hover, .irs--sharp .irs-handle:hover {
  background-color: #000;
}

.irs--sharp .irs-handle.state_hover > i:first-child, .irs--sharp .irs-handle:hover > i:first-child {
  border-top-color: #000;
}

.irs--sharp .irs-min, .irs--sharp .irs-max {
  color: #fff;
  opacity: .4;
  background-color: #a804b2;
  border-radius: 2px;
  padding: 3px 4px;
  font-size: 14px;
  line-height: 1;
  top: 0;
}

.irs--sharp .irs-from, .irs--sharp .irs-to, .irs--sharp .irs-single {
  text-shadow: none;
  color: #fff;
  background-color: #a804b2;
  border-radius: 2px;
  padding: 3px 4px;
  font-size: 14px;
  line-height: 1;
}

.irs--sharp .irs-from:before, .irs--sharp .irs-to:before, .irs--sharp .irs-single:before {
  content: "";
  width: 0;
  height: 0;
  border: 3px solid #0000;
  border-top-color: #a804b2;
  margin-left: -3px;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  overflow: hidden;
}

.irs--sharp .irs-grid {
  height: 25px;
}

.irs--sharp .irs-grid-pol {
  background-color: #dedede;
}

.irs--sharp .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--round {
  height: 50px;
}

.irs--round.irs-with-grid {
  height: 65px;
}

.irs--round .irs-line {
  height: 4px;
  background-color: #dee4ec;
  border-radius: 4px;
  top: 36px;
}

.irs--round .irs-bar {
  height: 4px;
  background-color: #006cfa;
  top: 36px;
}

.irs--round .irs-bar--single {
  border-radius: 4px 0 0 4px;
}

.irs--round .irs-shadow {
  height: 4px;
  background-color: #dee4ec80;
  bottom: 21px;
}

.irs--round .irs-handle {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 4px solid #006cfa;
  border-radius: 24px;
  top: 26px;
  box-shadow: 0 1px 3px #0000ff4d;
}

.irs--round .irs-handle.state_hover, .irs--round .irs-handle:hover {
  background-color: #f0f6ff;
}

.irs--round .irs-min, .irs--round .irs-max {
  color: #333;
  background-color: #0000001a;
  border-radius: 4px;
  padding: 3px 5px;
  font-size: 14px;
  line-height: 1;
  top: 0;
}

.irs--round .irs-from, .irs--round .irs-to, .irs--round .irs-single {
  text-shadow: none;
  color: #fff;
  background-color: #006cfa;
  border-radius: 4px;
  padding: 3px 5px;
  font-size: 14px;
  line-height: 1;
}

.irs--round .irs-from:before, .irs--round .irs-to:before, .irs--round .irs-single:before {
  content: "";
  width: 0;
  height: 0;
  border: 3px solid #0000;
  border-top-color: #006cfa;
  margin-left: -3px;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  overflow: hidden;
}

.irs--round .irs-grid {
  height: 25px;
}

.irs--round .irs-grid-pol {
  background-color: #dedede;
}

.irs--round .irs-grid-text {
  color: silver;
  font-size: 13px;
}

.irs--square {
  height: 50px;
}

.irs--square.irs-with-grid {
  height: 60px;
}

.irs--square .irs-line {
  height: 4px;
  background-color: #dedede;
  top: 31px;
}

.irs--square .irs-bar {
  height: 4px;
  background-color: #000;
  top: 31px;
}

.irs--square .irs-shadow {
  height: 2px;
  background-color: #dedede;
  bottom: 21px;
}

.irs--square .irs-handle {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border: 3px solid #000;
  top: 25px;
  transform: rotate(45deg);
}

.irs--square .irs-handle.state_hover, .irs--square .irs-handle:hover {
  background-color: #f0f6ff;
}

.irs--square .irs-min, .irs--square .irs-max {
  color: #333;
  background-color: #0000001a;
  padding: 3px 5px;
  font-size: 14px;
  line-height: 1;
  top: 0;
}

.irs--square .irs-from, .irs--square .irs-to, .irs--square .irs-single {
  text-shadow: none;
  color: #fff;
  background-color: #000;
  padding: 3px 5px;
  font-size: 14px;
  line-height: 1;
}

.irs--square .irs-grid {
  height: 25px;
}

.irs--square .irs-grid-pol {
  background-color: #dedede;
}

.irs--square .irs-grid-text {
  color: silver;
  font-size: 11px;
}

.irs--theme {
  height: 40px;
}

.irs--theme.irs-with-grid {
  height: 60px;
}

.irs--theme .irs-line {
  height: 12px;
  top: 25px;
}

.irs--theme .irs-line-left {
  height: 12px;
  background-position: 0 -30px;
}

.irs--theme .irs-line-mid {
  height: 12px;
  background-position: 0 0;
}

.irs--theme .irs-line-right {
  height: 12px;
  background-position: 100% -30px;
}

.irs--theme .irs-bar {
  height: 6px;
  background-color: #db162f;
  border-radius: 200px;
  top: 27px;
}

.irs--theme .irs-bar-edge {
  height: 12px;
  width: 9px;
  background-position: 0 -90px;
  top: 25px;
}

.irs--theme .irs-shadow {
  height: 3px;
  opacity: .25;
  background: #000;
  top: 34px;
}

.irs--theme .lt-ie9 .irs-shadow {
  filter: alpha(opacity= 25);
}

.irs--theme .irs-slider {
  width: 16px;
  height: 18px;
  background-position: 0 -120px;
  top: 22px;
}

.irs--theme .irs-slider.state_hover, .irs--theme .irs-slider:hover {
  background-position: 0 -150px;
}

.irs--theme .irs-min, .irs--theme .irs-max {
  opacity: 0;
}

.irs--theme .irs-from, .irs--theme .irs-to, .irs--theme .irs-single {
  color: #fff;
  text-shadow: none;
  background: #212529;
  border-radius: 4px;
  padding: 1px 5px;
  font-size: 10px;
  line-height: 1.333;
}

.irs--theme .irs-from:after, .irs--theme .irs-to:after, .irs--theme .irs-single:after {
  content: "";
  width: 0;
  height: 0;
  border: 3px solid #0000;
  border-top-color: #212529;
  margin-left: -3px;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  overflow: hidden;
}

.irs--theme .irs-grid {
  bottom: -8px;
}

.irs--theme .irs-grid-pol {
  background: #adb5bd;
}

.irs--theme .irs-grid-text {
  color: #adb5bd;
  margin-top: .5rem;
  font-size: .75rem;
  font-weight: 600;
  bottom: -8px;
}

.irs--theme .irs-handle {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dee2e6;
  border-radius: 50%;
  transition: background-color .2s;
  top: 22px;
  box-shadow: 0 .125rem .625rem #0000000d;
}

.irs--theme .irs-handle.state_hover {
  background-color: #db162f;
}

.irs--theme .irs-handle > i:first-child {
  display: none;
}

.irs--theme .irs-handle.state_hover > i:first-child, .irs--theme .irs-handle:hover > i:first-child {
  background-color: #a43540;
}

.jarallax {
  z-index: 0;
  position: relative;
}

.jarallax > .jarallax-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  font-family: "object-fit: cover;";
  position: absolute;
  top: 0;
  left: 0;
}

.list-group-item.row {
  padding-left: 0;
  padding-right: 0;
  transition: color .2s;
  display: flex;
}

.list-group-item.row h5 {
  transition: color .2s;
}

.list-group-item.row:hover {
  color: #db162f;
  background: none;
}

.list-group-item.row:hover h5 {
  color: #db162f;
}

.list-group-item.list-group-item-action {
  transition: background-color .2s;
}

.list-group-flush:last-child .list-group-item.row:last-child {
  border-bottom: 1px solid #dee2e6;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 1px solid #dee2e6;
}

div[data-maps-api-key] div.map-marker, div[data-maps-api-key] div.map-style {
  display: none;
}

.gm-err-title, .gm-err-message {
  color: #e0e0e0;
  margin: 0;
}

.gm-err-title:after, .gm-err-message:after {
  content: "Enter a valid Google Maps API Key";
  color: #616161;
  display: block;
}

.gm-err-message:after {
  content: "Please consult the Google Maps API documentation on how to obtain an API key.";
}

@keyframes plyr-progress {
  to {
    background-position: 25px 0;
  }
}

@keyframes plyr-popup {
  0% {
    opacity: .5;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes plyr-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  font-variant-numeric: tabular-nums;
  max-width: 100%;
  min-width: 200px;
  text-shadow: none;
  font-family: Avenir, Avenir Next, Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.7;
  transition: box-shadow .3s;
  position: relative;
}

.plyr video, .plyr audio {
  border-radius: inherit;
  height: auto;
  vertical-align: middle;
  width: 100%;
}

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}

.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}

.plyr--full-ui *, .plyr--full-ui :after, .plyr--full-ui :before {
  box-sizing: inherit;
}

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  color: #fff;
  background: #4f5b5f;
  border-radius: 2px;
  padding: 3px 4px;
  font-size: 9px;
  line-height: 1;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  color: #fff;
  text-align: center;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  transition: transform .4s ease-in-out;
  animation: plyr-fade-in .3s;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.plyr__captions .plyr__caption {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  white-space: pre-wrap;
  background: #000c;
  border-radius: 2px;
  padding: .2em .5em;
  line-height: 185%;
}

.plyr__captions .plyr__caption div {
  display: inline;
}

.plyr__captions span:empty {
  display: none;
}

@media (min-width: 480px) {
  .plyr__captions {
    padding: 20px;
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .plyr__captions {
    font-size: 18px;
  }
}

.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(-40px);
}

.plyr__control {
  color: inherit;
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 3px;
  flex-shrink: 0;
  padding: 7px;
  transition: all .3s;
  position: relative;
  overflow: visible;
}

.plyr__control svg {
  fill: currentColor;
  height: 18px;
  pointer-events: none;
  width: 18px;
  display: block;
}

.plyr__control:focus {
  outline: 0;
}

.plyr__control.plyr__tab-focus {
  outline: 0;
  box-shadow: 0 0 0 5px #1aafff80;
}

a.plyr__control {
  text-decoration: none;
}

a.plyr__control:after, a.plyr__control:before, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed, .plyr__control.plyr__control--pressed .label--not-pressed {
  display: none;
}

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded="true"] {
  color: #fff;
  background: #1aafff;
}

.plyr--video .plyr__control svg {
  -webkit-filter: drop-shadow(0 1px 1px #00000026);
  filter: drop-shadow(0 1px 1px #00000026);
}

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded="true"] {
  color: #fff;
  background: #1aafff;
}

.plyr__control--overlaid {
  color: #fff;
  z-index: 2;
  background: #1aafffcc;
  border: 0;
  border-radius: 100%;
  padding: 15px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 1px 1px #00000026;
}

.plyr__control--overlaid svg {
  position: relative;
  left: 2px;
}

.plyr__control--overlaid:hover, .plyr__control--overlaid:focus {
  background: #1aafff;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  text-align: center;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.plyr__controls > .plyr__control, .plyr__controls .plyr__progress, .plyr__controls .plyr__time, .plyr__controls .plyr__menu, .plyr__controls .plyr__volume {
  margin-left: 5px;
}

.plyr__controls .plyr__menu + .plyr__control, .plyr__controls > .plyr__control + .plyr__menu, .plyr__controls > .plyr__control + .plyr__control, .plyr__controls .plyr__progress + .plyr__control {
  margin-left: 2px;
}

.plyr__controls > .plyr__control:first-child, .plyr__controls > .plyr__control:first-child + [data-plyr="pause"] {
  margin-left: 0;
  margin-right: auto;
}

.plyr__controls:empty {
  display: none;
}

@media (min-width: 480px) {
  .plyr__controls > .plyr__control, .plyr__controls .plyr__menu, .plyr__controls .plyr__progress, .plyr__controls .plyr__time, .plyr__controls .plyr__volume {
    margin-left: 10px;
  }
}

.plyr--audio .plyr__controls {
  border-radius: inherit;
  color: #4f5b5f;
  background: #fff;
  padding: 10px;
}

.plyr--video .plyr__controls {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  color: #fff;
  z-index: 3;
  background: linear-gradient(#0000, #000000b3);
  padding: 20px 5px 5px;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: 35px 10px 10px;
  }
}

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.plyr [data-plyr="captions"], .plyr [data-plyr="pip"], .plyr [data-plyr="airplay"], .plyr [data-plyr="fullscreen"] {
  display: none;
}

.plyr--captions-enabled [data-plyr="captions"], .plyr--pip-supported [data-plyr="pip"], .plyr--airplay-supported [data-plyr="airplay"], .plyr--fullscreen-enabled [data-plyr="fullscreen"] {
  display: inline-block;
}

.plyr__video-embed {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.plyr__video-embed iframe {
  height: 100%;
  user-select: none;
  width: 100%;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.2813%);
}

.plyr__menu {
  display: flex;
  position: relative;
}

.plyr__menu .plyr__control svg {
  transition: transform .3s;
}

.plyr__menu .plyr__control[aria-expanded="true"] svg {
  transform: rotate(90deg);
}

.plyr__menu .plyr__control[aria-expanded="true"] .plyr__tooltip {
  display: none;
}

.plyr__menu__container {
  color: #4f5b5f;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
  background: #ffffffe6;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 16px;
  animation: plyr-popup .2s;
  position: absolute;
  bottom: 100%;
  right: -3px;
  box-shadow: 0 1px 2px #00000026;
}

.plyr__menu__container > div {
  transition: height .35s cubic-bezier(.4, 0, .2, 1), width .35s cubic-bezier(.4, 0, .2, 1);
  overflow: hidden;
}

.plyr__menu__container:after {
  content: "";
  height: 0;
  width: 0;
  border: 4px solid #0000;
  border-top-color: #ffffffe6;
  position: absolute;
  top: 100%;
  right: 15px;
}

.plyr__menu__container [role="menu"] {
  padding: 7px;
}

.plyr__menu__container [role="menuitem"], .plyr__menu__container [role="menuitemradio"] {
  margin-top: 2px;
}

.plyr__menu__container [role="menuitem"]:first-child, .plyr__menu__container [role="menuitemradio"]:first-child {
  margin-top: 0;
}

.plyr__menu__container .plyr__control {
  color: #4f5b5f;
  user-select: none;
  width: 100%;
  align-items: center;
  padding: 4px 11px;
  font-size: 14px;
  display: flex;
}

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  width: 100%;
  display: flex;
}

.plyr__menu__container .plyr__control:after {
  content: "";
  border: 4px solid #0000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px;
}

.plyr__menu__container .plyr__control--forward:after {
  border-left-color: #4f5b5fcc;
  right: 5px;
}

.plyr__menu__container .plyr__control--forward.plyr__tab-focus:after, .plyr__menu__container .plyr__control--forward:hover:after {
  border-left-color: currentColor;
}

.plyr__menu__container .plyr__control--back {
  width: calc(100% - 14px);
  margin: 7px 7px 3px;
  padding-left: 28px;
  font-weight: 500;
  position: relative;
}

.plyr__menu__container .plyr__control--back:after {
  border-right-color: #4f5b5fcc;
  left: 7px;
}

.plyr__menu__container .plyr__control--back:before {
  content: "";
  height: 1px;
  background: #b7c5cd;
  margin-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
  box-shadow: 0 1px #fff;
}

.plyr__menu__container .plyr__control--back.plyr__tab-focus:after, .plyr__menu__container .plyr__control--back:hover:after {
  border-right-color: currentColor;
}

.plyr__menu__container .plyr__control[role="menuitemradio"] {
  padding-left: 7px;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:before, .plyr__menu__container .plyr__control[role="menuitemradio"]:after {
  border-radius: 100%;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:before {
  content: "";
  height: 16px;
  width: 16px;
  background: #0000001a;
  flex-shrink: 0;
  margin-right: 10px;
  transition: all .3s;
  display: block;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:after {
  height: 6px;
  opacity: 0;
  width: 6px;
  background: #fff;
  border: 0;
  transition: transform .3s, opacity .3s;
  top: 50%;
  left: 12px;
  transform: translateY(-50%)scale(0);
}

.plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]:before {
  background: #1aafff;
}

.plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]:after {
  opacity: 1;
  transform: translateY(-50%)scale(1);
}

.plyr__menu__container .plyr__control[role="menuitemradio"].plyr__tab-focus:before, .plyr__menu__container .plyr__control[role="menuitemradio"]:hover:before {
  background: #0000001a;
}

.plyr__menu__container .plyr__menu__value {
  pointer-events: none;
  align-items: center;
  margin-left: auto;
  margin-right: -5px;
  padding-left: 25px;
  display: flex;
  overflow: hidden;
}

.plyr--full-ui input[type="range"] {
  -webkit-appearance: none;
  color: #1aafff;
  height: 20px;
  width: 100%;
  background: none;
  border: 0;
  border-radius: 28px;
  margin: 0;
  padding: 0;
  transition: box-shadow .3s;
  display: block;
}

.plyr--full-ui input[type="range"]::-webkit-slider-runnable-track {
  height: 6px;
  user-select: none;
  background: none;
  background-image: linear-gradient( to right, currentColor var(--value, 0%), transparent var(--value, 0%) );
  border: 0;
  border-radius: 3px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-webkit-slider-thumb {
  height: 14px;
  width: 14px;
  -webkit-appearance: none;
  background: #fff;
  border: 0;
  border-radius: 100%;
  margin-top: -4px;
  transition: all .2s;
  position: relative;
  box-shadow: 0 1px 1px #00000026, 0 0 0 1px #2f343d33;
}

.plyr--full-ui input[type="range"]::-moz-range-track {
  height: 6px;
  user-select: none;
  background: none;
  border: 0;
  border-radius: 3px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-moz-range-thumb {
  height: 14px;
  width: 14px;
  background: #fff;
  border: 0;
  border-radius: 100%;
  transition: all .2s;
  position: relative;
  box-shadow: 0 1px 1px #00000026, 0 0 0 1px #2f343d33;
}

.plyr--full-ui input[type="range"]::-moz-range-progress {
  height: 6px;
  background: currentColor;
  border-radius: 3px;
}

.plyr--full-ui input[type="range"]::-ms-track {
  height: 6px;
  user-select: none;
  color: #0000;
  background: none;
  border: 0;
  border-radius: 3px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-ms-fill-upper {
  height: 6px;
  user-select: none;
  background: none;
  border: 0;
  border-radius: 3px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-ms-fill-lower {
  height: 6px;
  user-select: none;
  background: currentColor;
  border: 0;
  border-radius: 3px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-ms-thumb {
  height: 14px;
  width: 14px;
  background: #fff;
  border: 0;
  border-radius: 100%;
  margin-top: 0;
  transition: all .2s;
  position: relative;
  box-shadow: 0 1px 1px #00000026, 0 0 0 1px #2f343d33;
}

.plyr--full-ui input[type="range"]::-ms-tooltip {
  display: none;
}

.plyr--full-ui input[type="range"]:focus {
  outline: 0;
}

.plyr--full-ui input[type="range"]::-moz-focus-outer {
  border: 0;
}

.plyr--full-ui input[type="range"].plyr__tab-focus::-webkit-slider-runnable-track {
  outline: 0;
  box-shadow: 0 0 0 5px #1aafff80;
}

.plyr--full-ui input[type="range"].plyr__tab-focus::-moz-range-track {
  outline: 0;
  box-shadow: 0 0 0 5px #1aafff80;
}

.plyr--full-ui input[type="range"].plyr__tab-focus::-ms-track {
  outline: 0;
  box-shadow: 0 0 0 5px #1aafff80;
}

.plyr--full-ui.plyr--video input[type="range"]::-webkit-slider-runnable-track {
  background-color: #ffffff40;
}

.plyr--full-ui.plyr--video input[type="range"]::-moz-range-track {
  background-color: #ffffff40;
}

.plyr--full-ui.plyr--video input[type="range"]::-ms-track {
  background-color: #ffffff40;
}

.plyr--full-ui.plyr--video input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px #00000026, 0 0 0 1px #2f343d33, 0 0 0 3px #ffffff80;
}

.plyr--full-ui.plyr--video input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px #00000026, 0 0 0 1px #2f343d33, 0 0 0 3px #ffffff80;
}

.plyr--full-ui.plyr--video input[type="range"]:active::-ms-thumb {
  box-shadow: 0 1px 1px #00000026, 0 0 0 1px #2f343d33, 0 0 0 3px #ffffff80;
}

.plyr--full-ui.plyr--audio input[type="range"]::-webkit-slider-runnable-track {
  background-color: #b7c5cda8;
}

.plyr--full-ui.plyr--audio input[type="range"]::-moz-range-track {
  background-color: #b7c5cda8;
}

.plyr--full-ui.plyr--audio input[type="range"]::-ms-track {
  background-color: #b7c5cda8;
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px #00000026, 0 0 0 1px #2f343d33, 0 0 0 3px #0000001a;
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px #00000026, 0 0 0 1px #2f343d33, 0 0 0 3px #0000001a;
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-ms-thumb {
  box-shadow: 0 1px 1px #00000026, 0 0 0 1px #2f343d33, 0 0 0 3px #0000001a;
}

.plyr__poster {
  height: 100%;
  opacity: 0;
  width: 100%;
  z-index: 1;
  background-color: #000;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr__time {
  font-size: 14px;
}

.plyr__time + .plyr__time:before {
  content: "⁄";
  margin-right: 10px;
}

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none;
  }
}

.plyr--video .plyr__time {
  text-shadow: 0 1px 1px #00000026;
}

.plyr__tooltip {
  color: #4f5b5f;
  opacity: 0;
  pointer-events: none;
  transform-origin: 50% 100%;
  white-space: nowrap;
  z-index: 2;
  background: #ffffffe6;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 5px 7.5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  transition: transform .2s .1s, opacity .2s .1s;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 10px)scale(.8);
  box-shadow: 0 1px 2px #00000026;
}

.plyr__tooltip:before {
  content: "";
  height: 0;
  width: 0;
  z-index: 2;
  border-top: 4px solid #ffffffe6;
  border-left: 4px solid #0000;
  border-right: 4px solid #0000;
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
}

.plyr .plyr__control:hover .plyr__tooltip, .plyr .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%)scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  transform-origin: 0 100%;
  left: 0;
  transform: translate(0, 10px)scale(.8);
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip:before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip:before {
  left: 16px;
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  transform-origin: 100% 100%;
  left: auto;
  right: 0;
  transform: translate(0, 10px)scale(.8);
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip:before {
  left: auto;
  right: 16px;
  transform: translateX(50%);
}

.plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible {
  transform: translate(0)scale(1);
}

.plyr--video {
  background: #000;
  overflow: hidden;
}

.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  border-radius: inherit;
  z-index: 0;
  background: #000;
  position: relative;
  overflow: hidden;
}

.plyr__progress {
  flex: 1;
  margin-right: 14px;
  position: relative;
  left: 7px;
}

.plyr__progress input[type="range"], .plyr__progress__buffer {
  width: calc(100% + 14px);
  margin-left: -7px;
  margin-right: -7px;
}

.plyr__progress input[type="range"] {
  z-index: 2;
  position: relative;
}

.plyr__progress .plyr__tooltip {
  font-size: 14px;
  left: 0;
}

.plyr__progress__buffer {
  -webkit-appearance: none;
  height: 6px;
  background: none;
  border: 0;
  border-radius: 100px;
  margin-top: -3px;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 0;
}

.plyr__progress__buffer::-webkit-progress-bar {
  background: none;
  transition: width .2s;
}

.plyr__progress__buffer::-webkit-progress-value {
  min-width: 6px;
  background: currentColor;
  border-radius: 100px;
}

.plyr__progress__buffer::-moz-progress-bar {
  min-width: 6px;
  background: currentColor;
  border-radius: 100px;
  transition: width .2s;
}

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  transition: width .2s;
}

.plyr--video .plyr__progress__buffer {
  color: #ffffff40;
  box-shadow: 0 1px 1px #00000026;
}

.plyr--audio .plyr__progress__buffer {
  color: #b7c5cda8;
}

.plyr--loading .plyr__progress__buffer {
  color: #0000;
  background-image: linear-gradient(-45deg, #2f343d99 25%, #0000 25% 50%, #2f343d99 50% 75%, #0000 75%, #0000);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  animation: plyr-progress 1s linear infinite;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: #ffffff40;
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: #b7c5cda8;
}

.plyr__volume {
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.plyr__volume input[type="range"] {
  z-index: 2;
  margin-left: 5px;
  position: relative;
}

@media (min-width: 480px) {
  .plyr__volume {
    max-width: 90px;
  }
}

@media (min-width: 768px) {
  .plyr__volume {
    max-width: 110px;
  }
}

.plyr--is-ios .plyr__volume, .plyr--is-ios.plyr--vimeo [data-plyr="mute"] {
  display: none !important;
}

.plyr:fullscreen {
  height: 100%;
  width: 100%;
  background: #000;
  margin: 0;
  border-radius: 0 !important;
}

.plyr:-webkit-full-screen video, .plyr:-moz-full-screen video, .plyr:-ms-fullscreen video, .plyr:fullscreen video {
  height: 100%;
}

.plyr:-webkit-full-screen .plyr__video-wrapper, .plyr:-moz-full-screen .plyr__video-wrapper, .plyr:-ms-fullscreen .plyr__video-wrapper, .plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-webkit-full-screen .plyr__video-embed, .plyr:-moz-full-screen .plyr__video-embed, .plyr:-ms-fullscreen .plyr__video-embed, .plyr:fullscreen .plyr__video-embed {
  overflow: visible;
}

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper, .plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper, .plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper, .plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen, .plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen, .plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen, .plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg, .plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg, .plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg, .plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions, .plyr:-moz-full-screen .plyr__captions, .plyr:-ms-fullscreen .plyr__captions, .plyr:fullscreen .plyr__captions {
    font-size: 21px;
  }
}

.plyr:-webkit-full-screen {
  height: 100%;
  width: 100%;
  background: #000;
  margin: 0;
  border-radius: 0 !important;
}

.plyr:-webkit-full-screen video {
  height: 100%;
}

.plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-webkit-full-screen .plyr__video-embed {
  overflow: visible;
}

.plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
  }
}

.plyr:-moz-full-screen {
  height: 100%;
  width: 100%;
  background: #000;
  margin: 0;
  border-radius: 0 !important;
}

.plyr:-moz-full-screen video {
  height: 100%;
}

.plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-moz-full-screen .plyr__video-embed {
  overflow: visible;
}

.plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px;
  }
}

.plyr:-ms-fullscreen {
  height: 100%;
  width: 100%;
  background: #000;
  margin: 0;
  border-radius: 0 !important;
}

.plyr:-ms-fullscreen video {
  height: 100%;
}

.plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr:-ms-fullscreen .plyr__video-embed {
  overflow: visible;
}

.plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
  }
}

.plyr--fullscreen-fallback {
  height: 100%;
  width: 100%;
  z-index: 10000000;
  background: #000;
  margin: 0;
  position: fixed;
  inset: 0;
  border-radius: 0 !important;
}

.plyr--fullscreen-fallback video {
  height: 100%;
}

.plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  width: 100%;
}

.plyr--fullscreen-fallback .plyr__video-embed {
  overflow: visible;
}

.plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  top: 50%;
  transform: translateY(-50%);
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px;
  }
}

.plyr__ads {
  border-radius: inherit;
  cursor: pointer;
  z-index: -1;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.plyr__ads > div, .plyr__ads > div iframe {
  height: 100%;
  width: 100%;
  position: absolute;
}

.plyr__ads:after {
  color: #fff;
  content: attr(data-badge-text);
  pointer-events: none;
  z-index: 3;
  background: #2f343dcc;
  border-radius: 2px;
  padding: 2px 6px;
  font-size: 11px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.plyr__cues {
  height: 6px;
  opacity: .8;
  width: 3px;
  z-index: 3;
  background: currentColor;
  margin: -3px 0 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px !important;
  width: 1px !important;
  border: 0 !important;
  padding: 0 !important;
  position: absolute !important;
}

.plyr [hidden] {
  display: none !important;
}

.plyr {
  font-family: Inter UI, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.plyr__poster {
  background-size: cover !important;
}

button.plyr__control--overlaid {
  width: 3.75rem;
  height: 3.75rem;
  background: #db162f;
  padding: 0;
}

button.plyr__control--overlaid svg {
  margin: 0 auto;
  transform: scaleX(.75);
}

button.plyr__control--overlaid:hover, .plyr--video .plyr__control:hover {
  background: #c4142a;
}

.plyr--full-ui input[type="range"] {
  color: #db162f;
}

div.rounded video, div.rounded .plyr {
  border-radius: .625rem;
  overflow: hidden;
}

div.rounded .plyr__controls {
  border-radius: 0 0 .625rem .625rem;
}

.plyr__video-wrapper, .plyr__poster, .plyr--video {
  background: none;
}

.video-poster {
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.video-poster .btn {
  position: absolute;
}

code[class*="language-"], pre[class*="language-"] {
  color: #000;
  text-shadow: 0 1px #fff;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  hyphens: none;
  background: none;
  font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
  font-size: 1em;
  line-height: 1.5;
}

pre[class*="language-"]::selection, pre[class*="language-"] ::selection, code[class*="language-"]::selection, code[class*="language-"] ::selection {
  text-shadow: none;
  background: #b3d4fc;
}

@media print {
  code[class*="language-"], pre[class*="language-"] {
    text-shadow: none;
  }
}

pre[class*="language-"] {
  margin: .5em 0;
  padding: 1em;
  overflow: auto;
}

:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background: #f5f2f0;
}

:not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .3em;
  padding: .1em;
}

.token.comment, .token.prolog, .token.doctype, .token.cdata {
  color: #708090;
}

.token.punctuation {
  color: #999;
}

.token.namespace {
  opacity: .7;
}

.token.property, .token.tag, .token.boolean, .token.number, .token.constant, .token.symbol, .token.deleted {
  color: #905;
}

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
  color: #690;
}

.token.operator, .token.entity, .token.url, .language-css .token.string, .style .token.string {
  color: #9a6e3a;
  background: #ffffff80;
}

.token.atrule, .token.attr-value, .token.keyword {
  color: #07a;
}

.token.function, .token.class-name {
  color: #dd4a68;
}

.token.regex, .token.important, .token.variable {
  color: #e90;
}

.token.important, .token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

pre[class*="language-"], code[class*="language-"] {
  color: #212529;
  background: #fceff0;
  border: none;
}

article pre[class*="language-"] {
  border-radius: .3125rem;
  margin: 1rem 0;
}

pre[class*="language-"] {
  padding: 1.5rem;
}

body {
  transition: opacity .4s;
}

body.fade-page {
  opacity: 0;
}

.popover {
  font-weight: 600;
  box-shadow: 0 1rem 3rem #0000002d;
}

.popover-header {
  border-bottom: none;
  padding-bottom: 0;
  font-size: .875rem;
}

.popover-body {
  padding-top: .5rem;
}

.popover-image {
  position: relative;
}

.popover-hotspot {
  cursor: pointer;
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 50%;
  animation: hotspotPulse 2s infinite alternate;
  position: absolute;
}

@keyframes hotspotPulse {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: .5;
    transform: scale(1.3);
  }
}

.pricing-table {
  border-bottom: 1px solid #dee2e6;
}

.pricing-table thead th {
  border-top: 0;
  border-bottom-width: 1px;
}

.pricing-table th, .pricing-table td {
  padding: 1.5rem;
}

.pricing-table td, .pricing-table th {
  vertical-align: middle;
}

.pricing-table th:not(:last-child), .pricing-table td:not(:last-child) {
  border-right: 1px solid #dee2e6;
}

.pricing-table tbody tr {
  transition: background-color .2s;
}

.pricing-table-competitors thead {
  text-align: center;
}

.pricing-table-competitors thead th {
  vertical-align: middle;
  border-top: 0;
}

.pricing-table-competitors tbody .icon-round {
  margin: 0 auto;
}

.pricing-table-competitors tbody th {
  max-width: 120px;
}

.pricing-ticket:before, .pricing-ticket:after {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  background: #fff;
  border-radius: 50%;
  flex-shrink: 0;
  display: block;
  position: absolute;
  left: -.75rem;
}

.pricing-ticket:after {
  left: auto;
  right: -.75rem;
}

.process-circle {
  width: 3rem;
  height: 3rem;
  color: #fff;
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 600;
  display: flex;
}

.process-circle:empty:after {
  content: "";
  width: .5rem;
  height: .5rem;
  border-radius: 50%;
}

.process-circle:empty.bg-primary {
  background: #db162f0d !important;
}

.process-circle:empty.bg-primary:after {
  background-color: #db162f;
}

.process-circle:empty.bg-primary-2 {
  background: #3423a60d !important;
}

.process-circle:empty.bg-primary-2:after {
  background-color: #3423a6;
}

.process-circle:empty.bg-primary-3 {
  background: #010e1e0d !important;
}

.process-circle:empty.bg-primary-3:after {
  background-color: #010e1e;
}

.process-vertical {
  width: 100%;
  flex-direction: column;
  padding: 0;
  list-style: none;
  display: flex;
}

.process-vertical li {
  align-items: center;
  display: flex;
}

.process-vertical li .process-circle {
  margin-right: 1.5rem;
}

.process-vertical li:not(:last-child) {
  margin-bottom: 1.5rem;
  position: relative;
}

@media (min-width: 768px) {
  .process-vertical li {
    width: 50%;
    margin-left: 50%;
  }

  .process-vertical li .process-circle {
    margin-left: -1.5rem;
  }

  .process-vertical li:nth-child(2n) {
    text-align: right;
    flex-direction: row-reverse;
    margin-left: 0;
    margin-right: 50%;
  }

  .process-vertical li:nth-child(2n) .process-circle {
    margin-left: 1.5rem;
    margin-right: -1.5rem;
  }

  .process-vertical li:not(:last-child) {
    margin-bottom: 0;
    padding-bottom: 4.5rem;
  }

  .process-vertical li:not(:last-child):after {
    content: "";
    width: 1px;
    height: 3rem;
    background: #dee2e6;
    display: block;
    position: absolute;
    top: 4.125rem;
  }

  .text-light .process-vertical li:not(:last-child):after {
    background: #ffffff40;
  }
}

.progress-bar {
  font-weight: 600;
}

.progress-bar:only-child {
  border-radius: 10rem;
}

.progress {
  height: auto;
  min-height: .375rem;
}

progress.reading-position {
  width: 100%;
  height: 3px;
  appearance: none;
  color: #3423a6;
  background-color: #0000;
  border: none;
  transition: opacity .2s;
  position: absolute;
  bottom: 0;
  left: 0;
}

progress.reading-position.reading-position-hidden {
  opacity: 0;
}

progress.reading-position::-webkit-progress-bar {
  background-color: #0000;
}

progress.reading-position::-webkit-progress-value {
  background-color: #3423a6;
}

progress.reading-position::-moz-progress-bar {
  background-color: #3423a6;
}

.article-progress {
  width: 100%;
  z-index: 998;
  position: fixed;
}

.article-progress .article-progress-wrapper {
  background: #fff;
  border-bottom: 1px solid #dee2e6;
  transition: opacity .2s;
  box-shadow: 0 .625rem 1.5rem #00000014;
}

.article-progress .reading-position-hidden + .article-progress-wrapper, .article-progress .reading-position[value="0"] + .article-progress-wrapper {
  opacity: 0;
  pointer-events: none;
  height: 0;
}

.navbar-container:not([style]) + .article-progress {
  top: 0;
}

th {
  color: #212529;
  font-weight: 600;
}

.table-dark th {
  color: #fff;
}

.table thead th {
  border-bottom-width: 1px;
}

article table {
  border-top: 1px solid #dee2e6;
  margin-bottom: 1.5rem;
}

article table td {
  vertical-align: top;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

article table td:not(:last-child) {
  white-space: nowrap;
  padding-right: 1.5rem;
}

article table thead th {
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-right: 1.5rem;
}

article table tr {
  border-bottom: 1px solid #dee2e6;
}

.tooltip {
  font-weight: 600;
}

.user img {
  max-height: 3rem;
  border-radius: 50%;
}

.user [data-scribe="component:author"] {
  display: none;
}

.timePosted > a {
  color: #adb5bd;
}

.tweet {
  word-break: break-word;
}

.step-circles {
  border-bottom: none;
}

.step-circles .nav-link {
  width: 3rem;
  height: 3rem;
  background: #db162f1a;
  border: 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: background-color .2s;
  display: flex;
  position: relative;
  color: #db162f !important;
  opacity: 1 !important;
}

.step-circles .nav-link:hover {
  background: #db162f26;
}

.step-circles .nav-item {
  opacity: .5;
  margin: 0 1.5rem;
}

.step-circles .nav-item.active .nav-link {
  background: #db162f;
  color: #fff !important;
}

.step-circles .nav-item.active, .step-circles .nav-item.done {
  opacity: 1;
}

.step-circles .nav-item:not(:last-child) .nav-link:after {
  content: "";
  width: 3rem;
  height: 4px;
  pointer-events: none;
  background: #db162f1a;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
}

.wizard > ul:not(.step-circles) .btn {
  border-radius: .3125rem .3125rem 0 0;
}

.wizard > ul:not(.step-circles) > li.active .btn {
  color: #fff;
  background: #db162f;
  opacity: 1 !important;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .card .card-img-overlay {
    top: 0;
  }

  .card > a {
    flex-shrink: 0;
  }

  .card.justify-content-end .card-img-overlay {
    top: auto;
    bottom: 0;
  }

  .card:not([class*="col-"]) {
    width: 100%;
  }

  .card > div {
    max-width: 100%;
  }

  .card.card-article-wide .card-body {
    width: 100%;
  }

  .flex-column > img.flex-grow-1 {
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
  }

  .icon {
    flex-shrink: 0 !important;
  }

  .icon-logo {
    width: auto !important;
    max-width: 6rem !important;
  }

  svg.icon {
    width: 1.5rem;
  }

  svg.icon.icon-md {
    width: 2.25rem;
  }

  svg.icon.icon-lg {
    width: 3rem;
  }

  svg.icon.icon-sm {
    width: .75rem;
  }

  img {
    flex-shrink: 0;
  }

  img.flex-grow-1 {
    flex-grow: 0 !important;
  }

  .list-group, .row {
    width: 100%;
  }

  .text-image-2 {
    display: block;
  }

  .text-image-2 .bg-white {
    bottom: 0;
    right: 0;
  }

  .video-cover .video-play-icon {
    left: 50%;
    transform: translateX(-50%)translateY(-50%);
  }

  .video-play-icon:after {
    content: "Play";
    font-size: .5rem;
    bottom: 51px;
    right: 5px;
  }

  .progress-bar {
    height: 6px;
    display: block;
  }

  .video-poster .btn-round {
    left: 50%;
    transform: translateX(-50%);
  }

  .process-vertical li:not(:last-child):after {
    display: none;
  }

  .flickity-button-icon {
    display: block;
  }

  .min-vh-100 {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .docs-sidebar {
    height: calc(100vh - 4.5625rem);
    position: sticky;
    top: 4.5625rem;
    overflow-y: scroll;
  }

  #docs-index {
    display: block !important;
  }
}

.docs-dl {
  border-top: 1px solid #dee2e6;
  margin-left: 0;
  margin-right: 0;
}

.docs-dl p {
  margin-bottom: 0;
}

.docs-dl > dt, .docs-dl > dd {
  border-bottom: 1px solid #dee2e6;
  margin: 0;
  padding: .5rem;
}

.alert p {
  margin-bottom: 0;
}

.code-snippet {
  position: relative;
}

.code-snippet .btn-clipboard {
  opacity: 0;
  position: absolute;
  top: .75rem;
  right: .75rem;
}

.code-snippet:hover .btn-clipboard {
  opacity: 1;
}

.docs-graphic .btn {
  margin: .5rem;
}

.docs-graphic:not(:hover) .btn {
  opacity: 0;
}

/*# sourceMappingURL=index.f9c0a361.css.map */
