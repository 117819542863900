body {
  background-color: #1b1f3b !important;
}

/* Common Loader CSS */

@keyframes hideLoader {
  0% {
    width: 100%;
    height: 100%;
  }
  100% {
    width: 0;
    height: 0;
  }
}
body div.loader {
  width: 100vw;
  height: 100vh;
  z-index: 1071;
  transition: opacity 0.5s ease;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
body.loaded > div.loader {
  animation: hideLoader 0.5s linear 0.5s forwards;
}

/* Typing Animation */
.loading-animation {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  animation: typing 1s linear infinite alternate;
  position: relative;
  left: -12px;
}

@keyframes typing {
  0% {
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 12px 0px 0px 0px rgba(100, 100, 100, 0.2),
      24px 0px 0px 0px rgba(100, 100, 100, 0.2);
  }

  25% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(100, 100, 100, 2),
      24px 0px 0px 0px rgba(100, 100, 100, 0.2);
  }

  75% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(100, 100, 100, 0.2),
      24px 0px 0px 0px rgba(100, 100, 100, 1);
  }
}
